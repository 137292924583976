import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { saveEmailInfoDetails, saveEmailInfoUpdateFlag, saveEmailUpdateJson, saveEmailUpdateOktaJson, saveEmailVerifyEditFlag, saveRestrictAccessFlag } from "../../../../actions/LandingAction";
import { saveSelectedChannel } from "../../../../actions/TwoFactorAuthenticationAction";
import Icon_Mail from "../../../../assets/Icon/icon_mail.svg";
import "../../../../css/EmailInfo.css";
import { COBRAND, CORE_ORSO_ACCOUNT_S800_PRODUCT, DDMMYYYY, S800, SIMP, SMAP, SMP, descendingOrder, effectiveDate, schemeS800 } from "../../../../translations/common/commonConstants";
import { SortUtils, UiUtils } from "../../../../utils";

EmailInfo.propTypes = {
  props: PropTypes.any,
  clientInfo: PropTypes.object,
  emailInfoDetails: PropTypes.object,
  emailVerifyEditFlag: PropTypes.bool,
  emailAddress: PropTypes.string,
  estatementForS800: PropTypes.bool,
  estatementForSMAP: PropTypes.bool,
  estatementForSIMP: PropTypes.bool,
  dispatch: PropTypes.func,
  scheme: PropTypes.string,
  cycleDate: PropTypes.string,
  loginSchemeProduct: PropTypes.string,
  onEdit: PropTypes.func,
  activeEdit: PropTypes.bool,
  isOrsoAccount: PropTypes.bool,
  authMFAData: PropTypes.object,
  clntRltnshpNmbr: PropTypes.string,
  productTypeNmbr: PropTypes.string,
};

let pageRefreshFlag = true;

function EmailInfo(props) {
  const { t } = useTranslation("common");
  const history = useHistory();
  var updatedEmailDetails;

  let loginUsername;
  if (props.scheme === COBRAND || props.scheme === SMP) {
    loginUsername = '8' + String(props?.authMFAData?.userName).padStart(8, "0");
  } else {
    loginUsername = '' + props.clientInfo.clientNmbr;
  }

  useEffect(() => {
    if (history && history.action === 'POP' && pageRefreshFlag === true) {
      resetPropsValue();
      pageRefreshFlag = false;
    }
    if (props.emailVerifyEditFlag) {
      props.onEdit(true);
    }
  }, []);

  const clientCustomEmailData = props?.clientInfo?.clientCustomData?.find(obj => obj.cstmAttrCd === 102);
  const clientCustomEmailDataSorted = clientCustomEmailData?.customDataDetailsList?.sort(SortUtils.sortOnDate(effectiveDate, descendingOrder, DDMMYYYY));
  var clientEmailAddress = clientCustomEmailDataSorted?.[0].cstmAttrTxt;

  const schemeDetailsMap = {
    "S800": {
      schemecode: 255,
      schemestatement: S800
    },
    "SIMP": {
      schemecode: 256,
      schemestatement: SIMP
    },
    "SMAP": {
      schemecode: 257,
      schemestatement: SMAP
    }
  };

  let eCommStatement = "";
  if (props.scheme === schemeS800) {
    const clientCustomECommData = props?.clientInfo?.clientCustomData?.find(obj => obj.cstmAttrCd === schemeDetailsMap[props.scheme === schemeS800 ? CORE_ORSO_ACCOUNT_S800_PRODUCT.includes(props.loginSchemeProduct) ? schemeS800 : props.loginSchemeProduct : props.loginSchemeProduct].schemecode);
    const clientCustomECommDataSorted = clientCustomECommData?.customDataDetailsList?.sort(SortUtils.sortOnDate(effectiveDate, descendingOrder, DDMMYYYY));
    eCommStatement = clientCustomECommDataSorted?.[0].cstmAttrTxt;
  } else if (props.scheme === SMP) {
    eCommStatement = props.clientInfo.estatementForSMAP ? props.clientInfo.estatementForSMAP : props.clientInfo.estatementForSIMP;
  }

  if (props.emailVerifyEditFlag === true) {
    if (Object.keys(props.emailInfoDetails).length > 0) {
      updatedEmailDetails = props.emailInfoDetails;
    }
    setTimeout(function () {
      document.getElementById("emailSection").click();
    }, 2);
  }

  const [state, setState] = useState({
    emailInfoEditFlag: false,
    emailInfoDisplayFlag: true,
    emailInfoUpdateFlag: false,
    emailValue:
      (props.emailVerifyEditFlag && updatedEmailDetails !== undefined)
        ? updatedEmailDetails.emailAddress
        : clientEmailAddress && clientEmailAddress !== 'XX'
          ? clientEmailAddress
          : "",
    prevEmailValue: clientEmailAddress && clientEmailAddress !== 'XX'
      ? clientEmailAddress
      : "",
    emailEditFlag: props.emailVerifyEditFlag
      ? updatedEmailDetails.emailIdDetailsEditFlag
      : false,

    checkEditFlag: props.emailVerifyEditFlag
      ? updatedEmailDetails.checkDetailsEditFlag
      : false,
    emailError: false,
    emailEmpty: false,
    emailUpdateFlag: false,
    disableUpdate: props.emailVerifyEditFlag ? (updatedEmailDetails.emailIdDetailsEditFlag || updatedEmailDetails.checkDetailsEditFlag || updatedEmailDetails.check2FAEditFlag) ? false : true
      : true,
    disableVerify: true,
    registerEcom:
      props.emailVerifyEditFlag && updatedEmailDetails !== undefined
        ? updatedEmailDetails.estatementForSIMP === true
          ? true
          : false
        : eCommStatement === "Y"
          ? true
          : false,
    prevRegisterEcom: eCommStatement === "Y"
      ? true
      : false,
    enteredPin: "",
    pinError: false,
    cstmAttrCd: "",
    cstmAttrNm: ""
  });

  const handleEmailInfoEdit = (e) => {
    e.preventDefault();
    if (UiUtils.determineMPFTermination("profileInfoCutOff", Number(props.productTypeNmbr))) {
      props.dispatch(saveRestrictAccessFlag(true));
      props.onEdit(false);
      setState({
        ...state,
        emailInfoEditFlag: false,
      });
      window.scrollTo(0, 0);
    } else {
      if (e.target.name === "editButton") {
        setState({
          ...state,
          emailInfoEditFlag: true,
          prevEmailValue: state.emailValue,
          prevRegisterEcom: state.registerEcom,
          disableUpdate: true,
          emailInfoUpdateFlag: false,
          emailError: false,
          enteredPin: "",
          emailEditFlag: false,
          pinError: false,
          disableVerify: true,
          checkEditFlag: false
        });
        props.onEdit(true);
      } else if (e.target.name === "cancel") {
        setState({
          ...state,
          emailInfoEditFlag: false,
          emailValue: state.prevEmailValue,
          registerEcom: state.prevRegisterEcom,
          disableUpdate: true,
          emailInfoUpdateFlag: false,
          emailError: false,
          emailEditFlag: false,
          enteredPin: "",
          pinError: false,
          disableVerify: true,
          checkEditFlag: false
        });
        props.onEdit(false);
      }
      resetPropsValue();
    }
  };

  const resetPropsValue = () => {
    props.dispatch(saveEmailVerifyEditFlag(false));
    props.dispatch(saveEmailInfoUpdateFlag(false));
    props.dispatch(saveEmailInfoDetails({}));
    props.dispatch(saveEmailUpdateJson({}));
    props.dispatch(saveEmailUpdateOktaJson({})); //Michael Lam
  };

  const handleChange = (event) => {
    if (event.target.name === "email") {
      if (event.target.value !== null && event.target.value !== "") {
        var pattern = new RegExp(/^[a-zA-Z0-9]+(([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-])*|([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]*\.+[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)+)(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(event.target.value)) {
          setState({
            ...state,
            emailError: true,
            emailEditFlag: true,
            emailValue: event.target.value,
            disableUpdate: true,
            emailEmpty: false,
          });
        } else {
          setState({
            ...state,
            emailError: false,
            emailEditFlag: true,
            emailValue: event.target.value,
            disableUpdate: false,
            emailEmpty: false,
          });
        }
      } else {
        setState({
          ...state,
          emailError: false,
          emailEditFlag: true,
          emailValue: event.target.value,
          disableUpdate: true,
          emailEmpty: true
        });
      }
    }
  };

  const handleCheckChange = (event) => {
    if (event.target.checked == false) {
      setState({
        ...state,
        registerEcom: false,
        disableUpdate: state.emailValue ? false : true,
        checkEditFlag: true,
      });
    } else {
      setState({
        ...state,
        registerEcom: true,
        disableUpdate: state.emailValue ? false : true,
        checkEditFlag: true,
      });
    }
  };

  const updateEmailInfo = (e, props) => {
    e.preventDefault();
    let updatedEmail = state.emailValue;
    let updateData = [];
    if (state.emailEditFlag) {
      updateData = [
        {
          "chgDt": props.cycleDate,
          "clientNmbr": props.clientInfo.clientNmbr,
          "cstmAttrCd": 102,
          "cstmAttrNm": "E-MAIL ADDRESS",
          "cstmAttrTxt": updatedEmail,
          "efctvDt": props.cycleDate,
          clntRltnShpNmbr: props.clntRltnshpNmbr,
        }
      ];
    }
    if (state.checkEditFlag) {
      updateData.push(
        {
          "chgDt": props.cycleDate,
          "clientNmbr": props.clientInfo.clientNmbr,
          "cstmAttrCd": schemeDetailsMap[props.loginSchemeProduct].schemecode,
          "cstmAttrNm": schemeDetailsMap[props.loginSchemeProduct].schemestatement,
          "cstmAttrTxt": state.registerEcom ? "Y" : "N",
          "efctvDt": props.cycleDate,
          clntRltnShpNmbr: props.clntRltnshpNmbr,
        }
      );
    }
    const OktaData = {
      userName: loginUsername,
      updateMobile: false,
      updateEmail: true,
      clientMobile: "",
      clientEmail: updatedEmail,
      pltcJrsdNmbr: "",
      clntRltnShpNmbr: props.clntRltnshpNmbr,
      clientNmbr: props.clientInfo?.clientNmbr
    };

    var editFiedlJsonValue = {};
    editFiedlJsonValue.emailAddress = updatedEmail;
    editFiedlJsonValue.estatementForSIMP = state.registerEcom;
    editFiedlJsonValue.check2FA = (state.prevEmailValue !== state.emailValue);
    editFiedlJsonValue.emailIdDetailsEditFlag = state.emailEditFlag;
    editFiedlJsonValue.checkDetailsEditFlag = state.checkEditFlag;
    editFiedlJsonValue.check2FAEditFlag = (state.prevEmailValue !== state.emailValue);
    props.dispatch(saveEmailInfoDetails(editFiedlJsonValue));
    props.dispatch(saveEmailInfoUpdateFlag(true));
    props.dispatch(saveEmailUpdateJson(updateData));
    props.dispatch(saveSelectedChannel("EMAIL"));
    props.dispatch(saveEmailUpdateOktaJson(OktaData));
    history.push("/verify");
  };

  return (
    <React.Fragment>
      <div className="modal fade" id="pds-modal-5" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog">
          <div className="modal-content email-popup">
            <div className="modal-body">
              <h5 className="info-title">{t("emailInfo.popupTitle")}</h5>
              {t("emailInfo.popup")}
              <button
                className="pds-button pds-button-tertiary btn-blue pds-modal-close-dialog mx-auto"
                data-gtm="modal-cta"
                data-dismiss="modal"
                onClick={(e) => e.preventDefault()}
              >
                {t("emailInfo.ok")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="pds-card info-body rsc-card" id="email-section">
          {state.emailInfoDisplayFlag && (
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h3 className="info-title">{t("emailInfo.email")}</h3>
                {state.emailInfoEditFlag || props.emailVerifyEditFlag ? (
                  <a href="#" className="edit-link" name="cancel" onClick={handleEmailInfoEdit}>{t("common.cancel")}</a>
                ) : (
                  <a href="#" className={props.activeEdit ? "edit-link inactive" : "edit-link"} name="editButton" onClick={handleEmailInfoEdit}>{t("common.edit")}</a>
                )}
              </div>
              <form noValidate>
                <div className="row">
                  <div className="col-12">
                    {state.emailInfoEditFlag || props.emailVerifyEditFlag ? (
                      <div className="row">
                        <div className="col-12">
                          <input id="inputEmail" type="text" name="email" value={state.emailValue} onChange={handleChange} aria-label="emailInput" className="form-input form-control" />
                          {state.emailError && (
                            <div className="pds-error common-pds-error" id="inValidEmail-err-id" aria-label="emailError" data-testid="invalidEmailErrorTest" >{t("emailInfo.invalidEmail")}</div>
                          )}
                          {((props.scheme === schemeS800 && !(props.isOrsoAccount)) || props.scheme === SMP) &&
                            <div className="pds-checkbox checkbox-label mb-4 mb-sm-4 mb-md-0 mb-xl-0 mb-lg-0">
                              <input type="checkbox" id="checkbox4" data-testid="eCommCheckbox" checked={state.registerEcom} disabled={state.emailEmpty} onChange={(e) => handleCheckChange(e)} />
                              <label htmlFor="checkbox4" className="checkbox-label">{t("emailInfo.checkLabel")}</label>
                            </div>
                          }
                          {((props.scheme === schemeS800 && !(props.isOrsoAccount)) || props.scheme === SMP) &&
                            <label className="justify-label mp-ea-note">
                              {t("emailInfo.notifiLabel")}
                              <a href="javascript:void(0)" className="pds-modal-open-dialog info-link" data-target="#pds-modal-5" data-toggle="modal">{t("emailInfo.learnMore")}</a>
                              {t("emailInfo.notifiLabel2")}
                            </label>
                          }
                          <button
                            className="pds-button pds-button-tertiary btn-blue"
                            name="update"
                            type="submit"
                            disabled={state.emailError || state.disableUpdate}
                            onClick={(e) => { updateEmailInfo(e, props); }}
                          >
                            {t("common.update")}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-12">
                          <label className="inner-label">
                            {(clientEmailAddress === 'XX' || !clientEmailAddress) ? <label className="invalid-Email_lbl"><img className="email-icon" src={Icon_Mail}></img>{t("emailInfo.invalidEmailMessage")}</label> : clientEmailAddress}
                          </label>
                          {((props.scheme === schemeS800 && !(props.isOrsoAccount)) || props.scheme === SMP) &&
                            <div className={(clientEmailAddress === 'XX' || !clientEmailAddress) ? "disable-Email" : ""}>
                              <div className="pds-checkbox mb-4 mb-sm-4 mb-md-0 mb-xl-0 mb-lg-0">
                                <input
                                  type="checkbox"
                                  id="checkbox4"
                                  checked={state.registerEcom}
                                  disabled
                                />
                                <label htmlFor="checkbox4" className="checkbox-label">{t("emailInfo.checkLabel")}</label>
                              </div>
                              <div className="row">
                                <div className="col-md-12 col-sm-12">
                                  <label className="justify-label mp-ea-note">
                                    {t("emailInfo.notifiLabel")}
                                    <a
                                      href="javascript:void(0)"
                                      className={(props.clientInfo.emailAddress === 'XX' || !props?.clientInfo?.emailAddress) ? "disable-Email pds-modal-open-dialog info-link" : "pds-modal-open-dialog info-link"}
                                      data-target="#pds-modal-5"
                                      data-toggle="modal"
                                    >
                                      {t("emailInfo.learnMore")}
                                    </a>
                                    {t("emailInfo.notifiLabel2")}
                                  </label>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    clientInfo: state.landing.clientInfo,
    emailInfoDetails: state.landing.emailInfoDetails,
    emailInfoUpdateFlag: state.landing.emailInfoUpdateFlag,
    emailInfoUpdateJson: state.landing.emailInfoUpdateJson,
    emailVerifyEditFlag: state.landing.emailVerifyEditFlag,
    scheme: state.empLogin.scheme,
    cycleDate: state.landing.cycleDate,
    loginSchemeProduct: state.twoAccounts.loginSchemeProduct,
    emailInfoUpdateOktaJson: state.landing.emailInfoUpdateOktaJson,
    isOrsoAccount: state.twoAccounts.isOrsoAccount,
    authMFAData: state.TFADetails.authMFAData,
    clntRltnshpNmbr: state.empLogin.clntRltnshpNmbr,
    productTypeNmbr: state.empLogin.productTypeNmbr,
  };
};
const EmailInfoComp = connect(mapStateToProps)(EmailInfo);

export default class EmailInfoComponent extends React.Component {
  constructor() {
    super();
  }
  render() {
    return <EmailInfoComp onEdit={this.props.onEdit} activeEdit={this.props.activeEdit}></EmailInfoComp>;
  }
}

EmailInfoComponent.propTypes = {
  onEdit: PropTypes.func,
  activeEdit: PropTypes.bool,
};
