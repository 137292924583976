import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import largetick from "../assets/Icon/Tick-large.svg";
import "../css/Modal.css";

const Modal = (props) => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const [toOpen, setToOpen] = useState(true);
    return (
        <div className={props.openOnLoad && toOpen ? "modal modal-bg-colour d-block" : "modal fade modal-bg-colour"} id="sharedModal" data-bs-backdrop="static" data-keyboard="false" data-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content col-12 mx-auto modal-card">
                    <div className="modal-body">
                        <div className="col-12 mx-auto">
                            {props.displayImg && <div className="my-4">
                                <img className="modal-img" src={largetick}></img>
                            </div>}
                            <div className="row">
                                <h2 className="my-2 mx-auto modal-title">{props.title}</h2>
                            </div>
                            <div className="row">
                                <label className="my-1 modal-text mb-2">{props.textLine1}</label>
                                <label className="my-1 modal-text mb-2">{props.textLine2}</label>
                                <label className="my-1 modal-text mb-2">{props.textLine3}</label>
                            </div>
                            <div className="row">
                                <button name="login" type="button" data-dismiss="modal" className="my-3 col-6 modal-button mx-auto" onClick={() => { props.openOnLoad && toOpen ? setToOpen(false) : history.push(props.redirect); }}>{t("forgotpin.ok")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
Modal.propTypes = {
    props: PropTypes.any,
    title: PropTypes.string,
    textLine1: PropTypes.string,
    textLine2: PropTypes.string,
    textLine3: PropTypes.string,
    displayImg: PropTypes.bool,
    openOnLoad: PropTypes.bool,
    redirect: PropTypes.string
};
export default Modal;