import moment from "moment";
import React from "react";
import { Slide, toast } from "react-toastify";
import accountApi from "../API/Account/accountApi";
import api from "../API/PersonalInfo/api";
import Toast from "../Components/Toast";
import { saveAllPolicyDetails, saveEnrollmentDayCount, saveLoginSchemeProduct, saveOrsoAccFlag, savePolicyEnrollmentPeriodList, saveQuickPolicyInfo, saveSelectedPolicyIndex } from "../actions/AccountAction";
import { isPolicyTerminated, saveAsOfDate, saveClntRltnshpNmbr, saveContractNmbr, saveCutOffHours, saveFeesChargeInfo, saveOrganizationsChineseNames, saveProductTypeNmbr, saveQuicPolicyClientInfo, saveWebCycleDate } from "../actions/EmployeeLoginAction";
import { saveFeesAndCharges, savePolicyWiseFeesAndCharges } from "../actions/FundPerformanceAction";
import { saveAllPolicyFundInfo, saveFundInfoData } from "../actions/FundSwitchAction";
import { saveClientDetails, saveCycleDate } from "../actions/LandingAction";
import { saveAuthenticationFlag } from "../actions/TwoFactorAuthenticationAction";
import { CORE_ORSO_ACCOUNT_S800, DDMMYYYY, HKAIIB, S800Acronym, SMP, accountTypeEe, chngDate, descendingOrder, effectiveDate, productTypeORSO } from "../translations/common/commonConstants";
import AccountUtilsCobrand from "./AccountUtilsCobrand";
import { addGlobalFundInfoWithDetails } from "./FundInfoAPIUtils";
import SortUtils from "./SortUtils";
import { getContractInfo, saveContractInfo } from "./customAttributeUtil";

const toastOptions = {
  position: 'top-center',
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Slide,
  rtl: false,
  closeButton: false,
};

const firstStageFilter = (quickPolicyInfo, schemeSelected) => {
  let filteredAccounts = [];

  for (let index = 0; index < quickPolicyInfo.length; index++) {
    if (schemeSelected === "S800") {
      if (((quickPolicyInfo[index]?.product?.productAcronym?.toUpperCase().trim() === "S800") ||
        (CORE_ORSO_ACCOUNT_S800.includes(quickPolicyInfo[index]?.product?.productTypeNmbr))) &&
        (quickPolicyInfo[index]?.narrative === null || quickPolicyInfo[index]?.narrative?.narrativePK?.nrtvTypeNmbr !== 107)
      ) {
        filteredAccounts.push(quickPolicyInfo[index]);
      }
    } else if (schemeSelected === HKAIIB) {
      if (
        (quickPolicyInfo[index]?.product?.productAcronym
          ?.toUpperCase()
          .trim() === HKAIIB) &&
        (quickPolicyInfo[index]?.narrative === null ||
          quickPolicyInfo[index]?.narrative?.narrativePK?.nrtvTypeNmbr !== 107)
      ) {
        filteredAccounts.push(quickPolicyInfo[index]);
      }
    } else {
      if (
        (quickPolicyInfo[index]?.product?.productAcronym
          ?.toUpperCase()
          .trim() === "SMAP" ||
          quickPolicyInfo[index]?.product?.productAcronym
            ?.toUpperCase()
            .trim() === "SIMP") &&
        (quickPolicyInfo[index]?.narrative === null ||
          quickPolicyInfo[index]?.narrative?.narrativePK?.nrtvTypeNmbr !== 107)
      ) {
        filteredAccounts.push(quickPolicyInfo[index]);
      }
    }
  }
  return filteredAccounts;
};

const getFilteredAccountData = async (clientNmbr, headers) => {
  let response = await accountApi.getfilteredAccounts((clientNmbr), headers);
  let filteredList = response.data;
  return filteredList;
};

const secondStageFilter = async (filiteredpolicySummary, filteredPolicyDetails, pisysToken, clientNmbr) => {
  let secondStageFilteredPolicySummary = [];
  let secondStageFilterPolicyDetails = [];
  let headers = {
    pisys_auth_token: pisysToken,
    username: clientNmbr,
    "Accept-Language": "en",
    "Access-Control-Allow-Origin": "*",
  };

  const filteredList = await getFilteredAccountData(clientNmbr, headers);
  if (filteredList) {
    filteredPolicyDetails && filteredPolicyDetails.forEach((policyDetail) => {
      let clientRelationShipNumber = policyDetail.policyVO.clntRltnshpNmbr;
      let contractNumber = policyDetail.policyVO.contract.contractNmbr;
      filteredList.forEach((filteredLst) => {
        if (clientRelationShipNumber === filteredLst.clntRltnShpNmbr && contractNumber === filteredLst.contractNmbr) {
          secondStageFilterPolicyDetails.push(policyDetail);
        }
      });
    });

    filiteredpolicySummary && filiteredpolicySummary.forEach((policyDetail) => {
      let clientRelationShipNumber = policyDetail.clntRltnshpNmbr;
      let contractNumber = policyDetail.contract.contractNmbr;
      filteredList.forEach((filteredLst) => {
        if (clientRelationShipNumber === filteredLst.clntRltnShpNmbr && contractNumber === filteredLst.contractNmbr) {
          secondStageFilteredPolicySummary.push(policyDetail);
        }
      });
    });
  }
  return { secondStageFilteredPolicySummary, secondStageFilterPolicyDetails };
};


const getAllPolicyDetails = (item, pisysToken, clientNmbr, cycleDate) => {
  const headers = {
    pisys_auth_token: pisysToken,
    username: clientNmbr,
    "Accept-Language": "en",
    "Access-Control-Allow-Origin": "*",
  };

  const policyJson = {
    asOfDate: cycleDate,
    businessUnitNmbr: 1,
    clntRltnshpNmbr: item.clntRltnshpNmbr,
    contractNmbr: item.contract.contractNmbr,
    effectiveDate: cycleDate,
    isHistory: true,
    policyDataFilter: {
      investments: true,
      policyHistory: true,
      policyInfo: true,
    },
  };

  let encodedRequest = (JSON.stringify(policyJson));

  let promise = new Promise((resolve, reject) => {
    accountApi.getPolicyDetails(encodedRequest, headers).then((response) => {
      let decodedResponse = response.data;
      resolve(decodedResponse);
    }).catch(() => {
      reject();
    });
  });
  return promise;
};

const loadPolicyDetailsUsingSummary = (quickPolicy, pisysToken, clientNmbr, cycleDate) => {

  let newdate = "";

  if (typeof cycleDate === "string" && cycleDate.includes("/")) {
    newdate = cycleDate;
  } else {
    let date = cycleDate.webCycleDate.substring(0, 10).split("-");
    newdate = date[2] + "/" + date[1] + "/" + date[0];
  }

  let promise = new Promise((resolve) => {

    let promisesArr = [];
    let policyDetails = [];
    let policyTotalAmountArr = [];

    quickPolicy.forEach((item) => {
      promisesArr.push(getAllPolicyDetails(item, pisysToken, clientNmbr, newdate));
    });

    let errorCount = 0;
    Promise.all(promisesArr).then((values) => {
      values.forEach((policyDetail) => {
        if (policyDetail !== "error") {
          policyDetails.push(policyDetail);
          let totalAmt = 0;
          policyDetail.policyAccountValueVOList.forEach((item) => {
            totalAmt = totalAmt + item.investmentAmt;
          });
          policyTotalAmountArr.push(totalAmt);
        } else {
          errorCount = 1;
        }
      });
      if (errorCount === 0) {
        resolve(policyDetails);
      } else {
        resolve("error");
      }
    });
  });

  return promise;

};

const getQualifyingBalance = (props, pisysToken, loginId, clntRltnShpNmbr, asOfDate, rtnDtlFlag, scheme) => {
  const headers = {
    'pisys_auth_token': pisysToken,
    'username': loginId,
    'Accept-Language': 'en',
    'Access-Control-Allow-Origin': '*'
  };
  const requestData = {
    clntRltnShpNmbr: (String(clntRltnShpNmbr)),
    asOfDt: (asOfDate),
    rtnDtlFlag: (rtnDtlFlag)
  };
  const requestDataHKAIIB = {
    clntRltnShpNmbr: (String(clntRltnShpNmbr)),
    asOfDt: (asOfDate),
    rtnDtlFlag: (rtnDtlFlag),
    schemeName: scheme
  };

  let promise;
  if (scheme === HKAIIB) {
    promise = new Promise((resolve, reject) => {
      accountApi.getQualifyingBalanceHKAIIB(requestDataHKAIIB, headers).then(async (response) => {
        if (response.status === 200) {
          let decodedResponse = response.data;
          await compareWebCycleDateWithExistingWebCycleDate(props, decodedResponse?.webCycleDate);
          resolve(decodedResponse);
        } else {
          reject(response);
        }
      }).catch(() => {
        reject();
      });
    });
  } else {
    promise = new Promise((resolve, reject) => {
      accountApi.getQualifyingBalance(requestData, headers).then(async (response) => {
        if (response.status === 200) {
          let decodedResponse = response.data;
          await compareWebCycleDateWithExistingWebCycleDate(props, decodedResponse?.webCycleDate);
          resolve(decodedResponse);
        } else {
          reject(response);
        }
      }).catch(() => {
        reject();
      });
    });
  }

  return promise;
};

const getContributionDetails = (pisysToken, loginId, clntRltnShpNmbr, trnsHstSeqNmbr, trnsHstEfctvDt) => {
  const headers = {
    'pisys_auth_token': pisysToken,
    'username': loginId,
    'Accept-Language': 'en',
  };
  const requestData = {
    clntRltnShpNmbr: (clntRltnShpNmbr),
    trnsHstSeqNmbr: (trnsHstSeqNmbr),
    trnsHstEfctvDt: (trnsHstEfctvDt)
  };

  let promise = new Promise((resolve, reject) => {
    accountApi.getTransactionDetailsInfo(requestData, headers).then((response) => {
      if (response.status === 200) {
        let decodedResponse = response.data;
        resolve(decodedResponse);
      } else {
        reject(response);
      }
    });
  });

  return promise;
};

const saveChineseOrgName = async (props, quickPolicyInfo, pisysToken, clientNmbr) => {
  let chineseOrgName = [];
  let responseData = [];
  const headers = {
    'pisys_auth_token': pisysToken ? pisysToken : props.pisysToken,
    'username': clientNmbr,
    'Accept-Language': 'en',
    'Access-Control-Allow-Origin': '*'
  };
  const regEx1 = /^\(/;
  const regEx2 = /\)$/;
  if (Array.isArray(quickPolicyInfo) && quickPolicyInfo.length > 0) {
    quickPolicyInfo.map((policy) => {
      if (policy?.accountType?.toUpperCase()?.trim() === accountTypeEe) {
        const clientNmbr = policy?.client?.rltClntNmbr;

        if (regEx1.test(policy?.client?.organizationNm?.trim()) && regEx2.test(policy?.client?.organizationNm?.trim())) {
          responseData.push(api.getClientDetails((clientNmbr), headers));
        } else {
          responseData.push(null);
        }
      } else {
        responseData.push(null);
      }
    });

    await Promise.all(responseData).then((responses) => {

      responses.forEach((response) => {
        if (response !== null && response.status == 200) {
          let decodedResponse = response?.data;
          const orgCustomAttribute = decodedResponse?.clientCustomData?.find((attribute) => attribute?.cstmAttrCd === 103);
          chineseOrgName.push(orgCustomAttribute?.customDataDetailsList?.[0]?.cstmAttrTxt);
        } else {
          chineseOrgName.push(null);
        }
      });
    }).then(async () => {
      await props.dispatch(saveOrganizationsChineseNames([...chineseOrgName]));
    }).catch(() => {
      props.dispatch(saveOrganizationsChineseNames([]));
    });
  }

};

const saveAccountDetails = async (props, scheme, pisysToken, clientNmbr, setServiceCallFlag, sendNotification) => {
  let headers = {
    'pisys_auth_token': pisysToken,
    'username': clientNmbr,
    'Accept-Language': 'en',
    'Access-Control-Allow-Origin': '*'
  };
  const requestHeaders = {
    'pisys_auth_token': pisysToken,
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };
  await accountApi.getAccountDetails(String(clientNmbr), requestHeaders).then(async (response) => {
    const data = response.data;
    const cycleDateRes = data?.cycleDt;
    const cycleDate = cycleDateRes?.find((date) => date?.dateTypeCd === '1');
    const latesPriceDate = data?.priceDates?.[0];
    let quickPolicyInfo = data?.policyMinVos;
    const filteredAccounts = data?.filteredAccountVOs;
    const feesAndChargesArray = data?.feesChargesResponseVO ?? [];
    const policyDetailsList = [];
    let filteredQuickPolicyInfo = [];
    quickPolicyInfo = firstStageFilter(quickPolicyInfo, scheme);
    if (Array.isArray(quickPolicyInfo) && Array.isArray(filteredAccounts)) {
      for (let index = 0; index < filteredAccounts.length; index++) {
        const account = filteredAccounts[index];
        const filteredPolicy = quickPolicyInfo.find((policy) => policy?.clntRltnshpNmbr === account?.clntRltnShpNmbr && policy?.contract?.contractNmbr === account?.contractNmbr);
        if (filteredPolicy) {
          filteredQuickPolicyInfo.push(filteredPolicy);
        }
      }
    }
    if (Array.isArray(filteredQuickPolicyInfo) && filteredQuickPolicyInfo.length > 0) {
      let productAcronym = '';
      if (filteredQuickPolicyInfo[0]?.product?.productTypeName?.toUpperCase().includes(productTypeORSO) && scheme == S800Acronym) {
        productAcronym = S800Acronym;
        props.dispatch(saveOrsoAccFlag(true));
      } else {
        productAcronym = filteredQuickPolicyInfo[0]?.product?.productAcronym?.toUpperCase().trim();
        props.dispatch(saveOrsoAccFlag(false));
      }

      filteredQuickPolicyInfo.forEach(policy => {
        const policyDetail = data?.policyDetailsResponseList?.find((policyDtl) => policyDtl?.policyVO?.clntRltnshpNmbr === policy?.clntRltnshpNmbr) ?? {};
        policyDetailsList.push(policyDetail);
      });

      props.dispatch(saveLoginSchemeProduct(productAcronym));
      props.dispatch(saveQuickPolicyInfo(filteredQuickPolicyInfo));
      props.dispatch(saveAllPolicyDetails(policyDetailsList ?? []));
      props.dispatch(saveSelectedPolicyIndex(0));
      props.dispatch(saveAllPolicyFundInfo(data?.fundInfoRiskCurrencyVOsList ?? []));
      const fundInfo = data?.fundInfoRiskCurrencyVOsList?.find((fund) => fund?.contractNumber === filteredQuickPolicyInfo?.[0]?.contract?.contractNmbr);
      props.dispatch(saveFundInfoData(fundInfo?.fundInfoCurrencyRisks ?? []));
      props.dispatch(savePolicyWiseFeesAndCharges(feesAndChargesArray));
      const feesAndChargesData = feesAndChargesArray?.find((data) => data?.feeChargesSmry?.[0]?.clntRltnshpNmbr === filteredQuickPolicyInfo?.[0]?.clntRltnshpNmbr) ?? {};

      let temp = {};
      temp[filteredQuickPolicyInfo?.[0]?.clntRltnshpNmbr] = feesAndChargesData;
      props.dispatch(saveFeesChargeInfo(temp));
      props.dispatch(saveFeesAndCharges(feesAndChargesData));
      props.dispatch(saveClientDetails(data?.clientInfoResponse ?? {}));
      const contractCustomAttributes = data?.contrctCustomFieldResponse?.find((resp) => resp?.contractNmbr === filteredQuickPolicyInfo?.[0]?.contract?.contractNmbr)?.contractAttributeVOs ?? [];
      if (Array.isArray(contractCustomAttributes) && contractCustomAttributes.length > 0) {
        saveContractInfo(props, contractCustomAttributes);
      } else {
        getContractInfo(props, filteredQuickPolicyInfo?.[0]?.contract?.contractNmbr);
      }
      const productTypeNmbr = filteredQuickPolicyInfo?.[0].product?.productTypeNmbr?.toString();
      props.dispatch(saveProductTypeNmbr(productTypeNmbr));
      const webCycleDt = moment(latesPriceDate?.webCycleDate, "YYYY-MM-DD HH:mm:ss A").format("YYYY-MM-DD");
      addGlobalFundInfoWithDetails(props, fundInfo?.fundInfoCurrencyRisks ?? [], productTypeNmbr, webCycleDt, headers);
      await saveChineseOrgName(props, filteredQuickPolicyInfo, pisysToken, clientNmbr);
      props.dispatch(saveQuicPolicyClientInfo(filteredQuickPolicyInfo?.[0].client));
      props.dispatch(saveClntRltnshpNmbr(filteredQuickPolicyInfo?.[0]?.clntRltnshpNmbr?.toString() ?? ''));
      props.dispatch(saveContractNmbr(filteredQuickPolicyInfo?.[0]?.contract?.contractNmbr?.toString() ?? ''));
      filteredQuickPolicyInfo?.[0]?.terminationDate !== null ? props.dispatch(isPolicyTerminated(true)) : props.dispatch(isPolicyTerminated(false));
      props.dispatch(saveCycleDate(cycleDate?.cycleDt));
      props.dispatch(saveAsOfDate(latesPriceDate?.priceDate));
      props.dispatch(saveCutOffHours(latesPriceDate?.cutOffHours ?? 16));
      props.dispatch(saveWebCycleDate(latesPriceDate?.webCycleDate));
      props.dispatch(savePolicyEnrollmentPeriodList(data?.policyEnrollmentPeriodVos));
    } else {
      props.dispatch(saveLoginSchemeProduct(''));
      props.dispatch(saveQuickPolicyInfo([]));
      props.dispatch(saveAllPolicyDetails([]));
      props.dispatch(saveAuthenticationFlag(false));
      setServiceCallFlag(false);
      sendNotification('no-valid-account');
    }
  }).catch(() => {
    props.dispatch(saveAuthenticationFlag(false));
    setServiceCallFlag(false);
    sendNotification('technical-error');
  });
};

const getContractNumber = (props, quickPolicyInfo) => {
  let contractNumber = 0;
  let contractPrefix = quickPolicyInfo?.contract?.cstmAttrTxt?.[0]?.cstmAttrTxt ? quickPolicyInfo?.contract?.cstmAttrTxt?.[0]?.cstmAttrTxt : quickPolicyInfo?.product?.contractPrefix;
  if (quickPolicyInfo.contract.contractNmbr === 41107 || quickPolicyInfo.contract.contractNmbr === 41108) {
    contractNumber = "004662";
  } else {
    if (quickPolicyInfo?.product?.productTypeName?.includes(productTypeORSO) && props.scheme == S800Acronym && quickPolicyInfo.contract.contractNmbr < 10000) {
      contractPrefix = contractPrefix + "01";
      contractNumber = String(quickPolicyInfo.contract.contractNmbr).padStart(4, "0");
    } else {
      contractNumber = String(quickPolicyInfo.contract.contractNmbr).padStart(6, "0");
    }
  }

  return contractPrefix + contractNumber;
};
const compareWebCycleDateWithExistingWebCycleDate = async (props, apiWebCycleDate) => {
  try {
    if (new Date(props.webCycleDate).getTime() < new Date(apiWebCycleDate).getTime()) {
      if (props?.scheme === SMP) {
        AccountUtilsCobrand.saveAccountDetails(props.dispatch, props?.scheme, props.clientNmbr, props?.clientNmbrList, props?.pisysToken);
      } else {
        await getAccountSummaryDetails(props, props.pisysToken, props.clientNmbr);
      }

    }
  } catch {
    sendNotification('report-error-message');
  }
};
const sendNotification = (type) => {
  toast(<Toast type={type} />, toastOptions);
};
const getAccountSummaryDetails = async (props, pisysToken, clientNmbr) => {
  let headers = {
    'pisys_auth_token': pisysToken,
    'username': clientNmbr,
    'Accept-Language': 'en',
    'Access-Control-Allow-Origin': '*'
  };
  const requestHeaders = {
    'pisys_auth_token': pisysToken,
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };
  await accountApi.getAccountDetails(String(clientNmbr), requestHeaders).then(async (response) => {
    const data = response.data;
    const cycleDateRes = data?.cycleDt;
    const cycleDate = cycleDateRes?.find((date) => date?.dateTypeCd === '1');
    const latesPriceDate = data?.priceDates?.[0];
    let quickPolicyInfo = data?.policyMinVos;
    const filteredAccounts = data?.filteredAccountVOs;
    const feesAndChargesArray = data?.feesChargesResponseVO ?? [];
    const policyDetailsList = [];
    let filteredQuickPolicyInfo = [];
    quickPolicyInfo = firstStageFilter(quickPolicyInfo, props?.scheme);
    if (Array.isArray(quickPolicyInfo) && Array.isArray(filteredAccounts)) {
      for (let index = 0; index < filteredAccounts.length; index++) {
        const account = filteredAccounts[index];
        const filteredPolicy = quickPolicyInfo.find((policy) => policy?.clntRltnshpNmbr === account?.clntRltnShpNmbr && policy?.contract?.contractNmbr === account?.contractNmbr);
        if (filteredPolicy) {
          filteredQuickPolicyInfo.push(filteredPolicy);
        }
      }
    }
    if (Array.isArray(filteredQuickPolicyInfo) && filteredQuickPolicyInfo.length > 0) {
      let productAcronym = '';
      if (filteredQuickPolicyInfo[0]?.product?.productTypeName?.toUpperCase().includes("ORSO") && props.scheme == "S800") {
        productAcronym = 'S800';
      } else {
        productAcronym = filteredQuickPolicyInfo[0]?.product?.productAcronym?.toUpperCase().trim();
      }

      filteredQuickPolicyInfo.forEach(policy => {
        const policyDetail = data?.policyDetailsResponseList?.find((policyDtl) => policyDtl?.policyVO?.clntRltnshpNmbr === policy?.clntRltnshpNmbr) ?? {};
        policyDetailsList.push(policyDetail);
      });
      props.dispatch(saveCycleDate(cycleDate?.cycleDt));
      props.dispatch(saveAsOfDate(latesPriceDate?.priceDate));
      props.dispatch(saveCutOffHours(latesPriceDate?.cutOffHours ?? 16));
      props.dispatch(saveWebCycleDate(latesPriceDate?.webCycleDate));
      props.dispatch(saveLoginSchemeProduct(productAcronym));
      props.dispatch(saveQuickPolicyInfo(filteredQuickPolicyInfo));
      props.dispatch(saveAllPolicyDetails(policyDetailsList ?? []));
      props.dispatch(saveSelectedPolicyIndex(0));
      props.dispatch(saveAllPolicyFundInfo(data?.fundInfoRiskCurrencyVOsList ?? []));
      const fundInfo = data?.fundInfoRiskCurrencyVOsList?.find((fund) => fund?.contractNumber === filteredQuickPolicyInfo?.[0]?.contract?.contractNmbr);
      props.dispatch(saveFundInfoData(fundInfo?.fundInfoCurrencyRisks ?? []));
      props.dispatch(savePolicyWiseFeesAndCharges(feesAndChargesArray));
      const feesAndChargesData = feesAndChargesArray?.find((data) => data?.feeChargesSmry?.[0]?.clntRltnshpNmbr === filteredQuickPolicyInfo?.[0]?.clntRltnshpNmbr) ?? {};

      let temp = {};
      temp[filteredQuickPolicyInfo?.[0]?.clntRltnshpNmbr] = feesAndChargesData;
      props.dispatch(saveFeesChargeInfo(temp));
      props.dispatch(saveFeesAndCharges(feesAndChargesData));
      props.dispatch(saveClientDetails(data?.clientInfoResponse ?? {}));
      const contractCustomAttributes = data?.contrctCustomFieldResponse?.find((resp) => resp?.contractNmbr === filteredQuickPolicyInfo?.[0]?.contract?.contractNmbr)?.contractAttributeVOs ?? [];
      if (Array.isArray(contractCustomAttributes) && contractCustomAttributes.length > 0) {
        saveContractInfo(props, contractCustomAttributes);
      } else {
        getContractInfo(props, filteredQuickPolicyInfo?.[0]?.contract?.contractNmbr);
      }
      const productTypeNmbr = filteredQuickPolicyInfo?.[0].product?.productTypeNmbr?.toString();
      props.dispatch(saveProductTypeNmbr(productTypeNmbr));
      const webCycleDt = moment(latesPriceDate?.webCycleDate, "YYYY-MM-DD HH:mm:ss A").format("YYYY-MM-DD");
      addGlobalFundInfoWithDetails(props, fundInfo?.fundInfoCurrencyRisks ?? [], productTypeNmbr, webCycleDt, headers);
      await saveChineseOrgName(props, filteredQuickPolicyInfo, pisysToken, clientNmbr);
      props.dispatch(saveQuicPolicyClientInfo(filteredQuickPolicyInfo?.[0].client));
      props.dispatch(saveClntRltnshpNmbr(filteredQuickPolicyInfo?.[0]?.clntRltnshpNmbr?.toString() ?? ''));
      props.dispatch(saveContractNmbr(filteredQuickPolicyInfo?.[0]?.contract?.contractNmbr?.toString() ?? ''));
      filteredQuickPolicyInfo?.[0]?.terminationDate !== null ? props.dispatch(isPolicyTerminated(true)) : props.dispatch(isPolicyTerminated(false));
    } else {
      props.dispatch(saveLoginSchemeProduct(''));
      props.dispatch(saveQuickPolicyInfo([]));
      props.dispatch(saveAllPolicyDetails([]));
      props.dispatch(saveAuthenticationFlag(false));
      sendNotification('no-valid-account');
    }
  }).catch(() => {
    props.dispatch(saveAuthenticationFlag(false));
    sendNotification('technical-error');
  });
};

const getSelectedPolicyEnrollmentDayCount = (props, selectedPolicyClntRelNo, selectedPolicyContractNo, enrollmentPeriodList) => {
  const selectedPolicyEnrollment = enrollmentPeriodList?.filter((policy) => (policy?.clntRltnshpNmbr == selectedPolicyClntRelNo && policy?.contractNmbr == selectedPolicyContractNo));
  let enrollmentDayCount = 0;
  enrollmentDayCount = (selectedPolicyEnrollment && selectedPolicyEnrollment[0]) && selectedPolicyEnrollment[0]?.openEnrlDayCnt;
  props.dispatch(saveEnrollmentDayCount(enrollmentDayCount));
};

const getZnSchemeType = (contractCustomFields) => {
  const znSchemeTypeList = contractCustomFields?.filter((contract) => (contract?.cstmAttrCd == 158));
  let znSchemeType = "";
  if (0 < znSchemeTypeList?.length) {
    if (0 < znSchemeTypeList?.[0]?.customDataDetailsList?.length) {
      let sortednSchemeTypeList = znSchemeTypeList?.[0]?.customDataDetailsList?.sort(SortUtils.sortOnDate(chngDate, descendingOrder, DDMMYYYY));
      sortednSchemeTypeList = znSchemeTypeList?.[0]?.customDataDetailsList?.sort(SortUtils.sortOnDate(effectiveDate, descendingOrder, DDMMYYYY));
      znSchemeType = sortednSchemeTypeList?.[0]?.cstmAttrTxt;
    }
  }
  return znSchemeType;
};

const getProductList = (filteredAccountsList) => {
  let productList = [];
  for (let index = filteredAccountsList.length - 1; index >= 0; index--) {
    productList.push(filteredAccountsList?.[index].product?.productTypeNmbr);
  }
  return productList;
};

export default { firstStageFilter, getProductList, getSelectedPolicyEnrollmentDayCount, getZnSchemeType, secondStageFilter, getAllPolicyDetails, loadPolicyDetailsUsingSummary, getQualifyingBalance, getContributionDetails, saveChineseOrgName, saveAccountDetails, getContractNumber, compareWebCycleDateWithExistingWebCycleDate };
