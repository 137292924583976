import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import Toast from "../../Components/Toast";
import Bubble from "../../SharedComponents/Bubble";
import GainAndLossPopUp from "../../SharedComponents/GainAndLossPopUp";
import OverlayTip from "../../SharedComponents/OverlayTip";
import { saveTotalAccountBalance } from '../../actions/DashboardAction';
import ArrowRight from "../../assets/Arrow-right-blue.svg";
import { HKAIIB } from "../../translations/common/commonConstants";
import { FormatUtils, PortfolioUtils, UiUtils } from "../../utils";
import "./DBAccBalance.css";

const renderDrownList = (
  t,
  selectedduration
) => {
  return (
    <>
      <div
        className="nav-link nav-link-dropdown-section"
      >
        <span className="acc-balance-dropdown-title">
          {t(`dashboard.${selectedduration}`)}
        </span>

      </div>
    </>
  );
};

const DBAccBalance = (props) => {
  const { t, i18n } = useTranslation("common");
  const [durations, setdurations] = useState(null);
  const [selectedduration, setselectedduration] = useState(null);
  // const [gainLossData, setGainLossData] = useState({});
  const [interGrpData, setInterGrpData] = useState({});
  const [serviceCallFlag, setServiceCallFlag] = useState(false);
  const [totalAccountBalance, setTotalAccountBalance] = useState(0);

  let webCycleDt = props.webCycleDate && moment(props.webCycleDate, "YYYY-MM-DD HH:mm:ss A").toDate();
  /* let year = new Date(webCycleDt).getFullYear();
  let month = new Date(webCycleDt).getMonth() + 1;
  let lastDay =  moment(new Date(year, month, 0)).toDate(); */
  const history = useHistory();

  const toastOptions = {
    position: 'top-center',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    rtl: false,
    closeButton: false,
  };

  const sendNotification = (type) => {
    toast(<Toast type={type} />, toastOptions);
  };


  const onClickViewPortfolio = () => {
    props.dispatch(saveTotalAccountBalance(totalAccountBalance));
    history.push('/portfolio');
  };

  const getGainLoss = () => {

    setServiceCallFlag(true);
    setTotalAccountBalance(props.totalAmt);

    PortfolioUtils.getIntraGrpTranferData(props, webCycleDt).then((resp) => {
      setInterGrpData(resp);
      setTotalAccountBalance(resp?.[0]?.gainNLossVo?.accountBalance);
      setServiceCallFlag(false);
    }).catch(() => {
      setServiceCallFlag(false);
      sendNotification('report-error-message');
    });

  };

  useEffect(() => {
    getGainLoss();
    setselectedduration("sinceInception");
    setdurations(["3Months", "6Months", "1Year", "sinceInception"]);
  }, []);

  let totalAccountBalanceValue = FormatUtils.formatToDecimal(totalAccountBalance).split(".");
  const netContribution = interGrpData?.[0]?.gainNLossVo?.totalNetContribution;
  const gainLossAmt = totalAccountBalance - (netContribution ?? 0);
  const gainAndLossPercent = !isNaN(interGrpData?.[0]?.gainNLossVo?.percentage) ? Number(interGrpData?.[0]?.gainNLossVo?.percentage) : 0;
  if (durations && selectedduration && !serviceCallFlag) {
    return (
      <>
        <div data-testid='db-acc-balance' className="container">
          <div className=" row acc-balance-section">
            <span className="col-12 dbab-m20 mt-3 acc-balance-section-title">
              {t("dashboard.accBalance")}
            </span>
            <span className="col-12 acc-balance-as-at-date">
              {t("dashboard.accBalanceAsAt")} {moment(webCycleDt, "YYYY-MM-DD HH:mm:ss A").format(i18n.language === 'en' ? 'D MMM YYYY' : 'YYYY年M月D日')}
            </span>
            <div className="col-12 dbab-m20-new acc-balance-d-center-new">
              <label className="acc-balance-equals">=</label>
              <span className="acc-balance-amount">
                {totalAccountBalanceValue[0]}
              </span>
              <label className="acc-balance-decimal">.{totalAccountBalanceValue[1]}</label>
              <span className='acc-balance-bubble'>
                <Bubble height="23px" width="56px">
                  <span className="acc-balance-currency-text">
                    {props.scheme == HKAIIB ? t("currencies.usd") : t("fundSwitchIn.hkd")}
                  </span>
                </Bubble>
              </span>
            </div>
          </div>
        </div>
        <div className="line1"></div>
        <div className="container">
          <div className=" row acc-stats-section">
            <div className="col-12 dbab-m15 ">
              {renderDrownList(
                t,
                selectedduration
              )}
            </div>
            <div className="col-12 dbab-m20">
              <span className="acc-balance-net-contri-text">
                {t("dashboard.NetContri")}
              </span>
              <span>
                <OverlayTip msg={UiUtils.determineMPFTermination(2) ? t("mpfClosure.OverlayTipMessage") : t("dashboard.OverlayTipMessage")} />
              </span>
            </div>
            <div className="col-12 acc-balance-d-center">
              <span className="acc-balance-stats-amount" data-testid="db-acc-balance-netContri">
                {(netContribution ?? 0) < 0 ? `(${(FormatUtils.formatToDecimal(Math.abs((netContribution ?? 0))))})` : FormatUtils.formatToDecimal((netContribution ?? 0))}
              </span>
              <span>
                <Bubble height="23px" width="56px">
                  <span className="acc-balance-currency-text">
                    {props.scheme == HKAIIB ? t("currencies.usd") : t("fundSwitchIn.hkd")}
                  </span>
                </Bubble>
              </span>
            </div>
            <span className="col-12 acc-balance-return-gainloss-text">
              +
            </span>
            <span className="col-12 dbab-m20 acc-balance-return-gainloss-text">
              {t("dashboard.RGLoss")}
              <GainAndLossPopUp />
            </span>
            <div className="col-12 mb-4 acc-balance-d-center d-flex flex-wrap">
              <div className={`acc-balance-stats-amount ${gainLossAmt < 0 ? 'loss-amt' : 'gain-amt'}`} data-testid="db-acc-balance-gainLossAmt">
                {gainLossAmt < 0 ? `(${(FormatUtils.formatToDecimal(Math.abs(gainLossAmt)))})` : FormatUtils.formatToDecimal((gainLossAmt))}
              </div>
              <div className={`gain-loss-badge ${gainAndLossPercent < 0 && 'loss-badge'}`}>
                {`${gainAndLossPercent > 0 ? '+' : ''}${FormatUtils.formatToNumber(gainAndLossPercent, 2)}%`}
              </div>
              <Bubble height="24px" width="48px">
                <span className="acc-balance-currency-text">
                  {props.scheme == HKAIIB ? t("currencies.usd") : t("fundSwitchIn.hkd")}
                </span>
              </Bubble>
            </div>
          </div>
        </div>
        <div className="line1"></div>
        <div className="container">
          <div className="acc-linkto-section acc-balance-d-center">
            <span data-testid="viewPortfolio" className="d-none d-lg-block d-md-block dbab-m15 acc-balance-view-portfolio-text" onClick={() => { onClickViewPortfolio(); }}>
              {t("dashboard.ViewPFolio")}
            </span>
            <span data-testid="viewPortfolio" className="d-block d-sm-block d-md-none dbab-m15 acc-balance-view-portfolio-text" onClick={() => { onClickViewPortfolio(); }}>
              {t("dashboard.ViewPFolio")}
            </span>
            <img
              className="dbab-m15 acc-balance-view-portfolio-image"
              src={ArrowRight}
            />
          </div>
        </div>
      </>
    );
  } else {
    return <div className="d-flex justify-content-center">
      <div className="spinner-border spinner m-5" role="status">
        <span className="sr-only"></span>
      </div>
    </div>;
  }
};


const mapStateToProps = (state) => {
  return {
    loginId: state.empLogin.loginId,
    updateDone: state.portfolio.updateDone,
    scheme: state.empLogin.scheme,
    clientNmbr: state.empLogin.clientNmbr,
    pisysToken: state.token.pisysToken,
    webCycleDate: state.empLogin.webCycleDate,
  };
};

DBAccBalance.propTypes = {
  props: PropTypes.any,
  dispatch: PropTypes.func,
  scheme: PropTypes.string,
  loginId: PropTypes.string,
  updateDone: PropTypes.bool,
  clientNmbr: PropTypes.string,
  pisysToken: PropTypes.string,
  webCycleDate: PropTypes.string,
  totalAmt: PropTypes.number
};

export default connect(mapStateToProps)(DBAccBalance);