//Common
export const SAVE_CLIENT_INFO = "SAVE_CLIENT_INFO";
export const SAVE_MULTIPLE_CLIENT_INFO = "SAVE_MULTIPLE_CLIENT_INFO";
export const SAVE_ENGLISH_DROP_DOWN_INFO = "SAVE_ENGLISH_DROP_DOWN_INFO";
export const SAVE_CHINESE_DROP_DOWN_INFO = "SAVE_CHINESE_DROP_DOWN_INFO";
export const SAVE_COUNTRY_DROP_DOWN = "SAVE_COUNTRY_DROP_DOWN";
export const OPEN_VERIFY_PAGE = "OPEN_VERIFY_PAGE";
export const SAVE_CYCLE_DATE = "SAVE_CYCLE_DATE";
export const SAVE_LOGIN_SCHEME = "SAVE_LOGIN_SCHEME";
export const SAVE_LOGIN_SCHEME_PRODUCT = "SAVE_LOGIN_SCHEME_PRODUCT";
export const SAVE_QUICK_POLICY_INFO = "SAVE_QUICK_POLICY_INFO";
export const SAVE_SELECTED_QUICK_POLICY_INFO = "SAVE_SELECTED_QUICK_POLICY_INFO";
export const SAVE_PWC_CHECK = "SAVE_PWC_CHECK";
export const SET_PWC_SERVICE_FLAG = "SAVE_PWC_SERVICE_FLAG";
export const SAVE_AS_OF_DATE = "SAVE_AS_OF_DATE";
export const SAVE_LAST_LOGIN_DATE = "SAVE_LAST_LOGIN_DATE";
export const SAVE_FILTERED_ENGLISH_DROP_DOWN_INFO = "SAVE_FILTERED_ENGLISH_DROP_DOWN_INFO";
export const SAVE_FILTERED_CHINESE_DROP_DOWN_INFO = "SAVE_FILTERED_CHINESE_DROP_DOWN_INFO";
export const SAVE_CUT_OFF_HRS = "SAVE_CUT_OFF_HRS";
export const SAVE_WEB_CYCLE_DATE = "SAVE_WEB_CYCLE_DATE";
export const SAVE_CURRENT_LOADED_PAGE_ON_UI = "SAVE_CURRENT_LOADED_PAGE_ON_UI";
export const RESET_ACCOUNT_INFO = "RESET_ACCOUNT_INFO";
export const SAVE_ORSO_ACC_FLAG = "SAVE_ORSO_ACC_FLAG";
export const SAVE_RESTRICT_ACCESS_FLAG = "SAVE_RESTRICT_ACCESS_FLAG";

//Token Info
export const SAVE_PISYS_TOKEN = "SAVE_PISYS_TOKEN";
export const SAVE_AUTH_TOKEN = "SAVE_AUTH_TOKEN";

//Login Info
export const SAVE_CLIENT_NMBR = "SAVE_CLIENT_NMBR";
export const SAVE_CLIENT_NMBR_LIST = "SAVE_CLIENT_NMBR_LIST";
export const SAVE_LOGIN_ID = "SAVE_LOGIN_ID";
export const SAVE_CLIENT_SCHEME = "SAVE_CLIENT_SCHEME";
export const SAVE_CLNT_RLTNSHP_NMBR = "SAVE_CLNT_RLTNSHP_NMBR";
export const SAVE_PRODUCT_TYPE_NMBR = "SAVE_PRODUCT_TYPE_NMBR";
export const IS_FIRST_TIME_LOGIN = "IS_FIRST_TIME_LOGIN";
export const SAVE_CONTRACT_NMBR = "SAVE_CONTRACT_NMBR";
export const IS_POLICY_TERMINDATED = "IS_POLICY_TERMINDATED";
export const SAVE_QUICK_POLICY_CLNT_INFO = "SAVE_QUICK_POLICY_CLNT_INFO";
export const SAVE_ORG_CHINESE_NAMES = "SAVE_ORG_CHINESE_NAMES";
export const SAVE_SELECT_ACC_FIRST_LOGIN = "SAVE_SELECT_ACC_FIRST_LOGIN";
export const SAVE_FEES_CHARGE_INFO = "SAVE_FEES_CHARGE_INFO";
export const RESET_LOGIN_INFO = "RESET_LOGIN_INFO";

//Other Contact Info
export const SAVE_CONTACT_INFO_DETAILS = "SAVE_CONTACT_INFO_DETAILS";
export const SAVE_CONTACT_INFO_UPDATE_FLAG = "SAVE_CONTACT_INFO_UPDATE_FLAG";
export const SAVE_CONTACT_UPDATE_JSON = "SAVE_CONTACT_UPDATE_JSON";
export const SAVE_CONTACT_VERIFY_EDIT_FLAG = "SAVE_CONTACT_VERIFY_EDIT_FLAG";

//General Info
export const SAVE_MAX_AGE = "SAVE_MAX_AGE";

//Email Info
export const SAVE_EMAIL_INFO_DETAILS = "SAVE_EMAIL_INFO_DETAILS";
export const SAVE_EMAIL_INFO_UPDATE_FLAG = "SAVE_EMAIL_INFO_UPDATE_FLAG";
export const SAVE_EMAIL_UPDATE_JSON = "SAVE_EMAIL_UPDATE_JSON";
export const SAVE_EMAIL_VERIFY_EDIT_FLAG = "SAVE_EMAIL_VERIFY_EDIT_FLAG";
export const SAVE_EMAIL_UPDATE_OKTA_JSON = "SAVE_EMAIL_UPDATE_OKTA_JSON";

//Mobile Info
export const SAVE_MOBILE_INFO_DETAILS = "SAVE_MOBILE_INFO_DETAILS";
export const SAVE_MOBILE_INFO_UPDATE_FLAG = "SAVE_MOBILE_INFO_UPDATE_FLAG";
export const SAVE_MOBILE_UPDATE_JSON = "SAVE_MOBILE_UPDATE_JSON";
export const SAVE_MOBILE_VERIFY_EDIT_FLAG = "SAVE_MOBILE_VERIFY_EDIT_FLAG";
export const SAVE_MOBILE_UPDATE_OKTA_JSON = "SAVE_MOBILE_UPDATE_OKTA_JSON";

//Report
export const SAVE_REPORT_DATA = "SAVE_REPORT_DATA";

//FundSwitchInfo
export const SAVE_CONTRIBUTION_TYPE = "SAVE_CONTRIBUTION_TYPE";
export const SAVE_SELECTED_FUND_FOR_SWITCH_OUT = "SAVE_SELECTED_FUND_FOR_SWITCH_OUT";
export const SAVE_SELECTED_ASSET_TYPE = "SAVE_SELECTED_ASSET_TYPE";
export const SAVE_SWITCH_IN_DATA = "SAVE_SWITCH_IN_DATA";
export const SAVE_INVESTMENT_DIRECTION = "SAVE_INVESTMENT_DIRECTION";
export const SAVE_SWITCHIN_CONFIRMED_FUNDS = "SAVE_SWITCHIN_CONFIRMED_FUNDS";
export const SAVE_SELECTED_FUNDS_FOR_SWITCH_IN = "SAVE_SELECTED_FUNDS_FOR_SWITCH_IN";
export const SAVE_FUND_SWITCH_DATA = "SAVE_FUND_SWITCH_DATA";
export const RESET_FUND_SWITCH_DATA = "RESET_FUND_SWITCH_DATA";
export const REMOVE_FUND_SWITCH_DATA = "REMOVE_FUND_SWITCH_DATA";
export const UPDATE_FUND_SWITCH_DATA = "UPDATE_FUND_SWITCH_DATA";
export const SAVE_SWITCH_NEXT_FLAG = "SAVE_SWITCH_NEXT_FLAG";
export const SAVE_MAN_INVESTMENT_DETAILS = "SAVE_MAN_INVESTMENT_DETAILS";
export const SAVE_VOL_INVESTMENT_DETAILS = "SAVE_VOL_INVESTMENT_DETAILS";
export const SAVE_BAL_CHECK_EDIT = "SAVE_BAL_CHECK_EDIT";
export const SAVE_EE_INVESTMENT_DETAILS = "SAVE_EE_INVESTMENT_DETAILS";
export const SAVE_ER_INVESTMENT_DETAILS = "SAVE_ER_INVESTMENT_DETAILS";
export const SAVE_INVESTMENT_DETAILS = "SAVE_INVESTMENT_DETAILS";
export const SAVE_SWITCH_OUT_FUNDS = "SAVE_SWITCH_OUT_FUNDS";
export const SAVE_ORSO_FUND_SWITCH_DATA = "SAVE_ORSO_FUND_SWITCH_DATA";
export const SAVE_ER_PENDING_FUNDS = "SAVE_ER_PENDING_FUNDS";
export const SAVE_EE_PENDING_FUNDS = "SAVE_EE_PENDING_FUNDS";
export const SAVE_ER_PENDING_FOR_SAME_DAY = "SAVE_ER_PENDING_FOR_SAME_DAY";
export const SAVE_EE_PENDING_FOR_SAME_DAY = "SAVE_EE_PENDING_FOR_SAME_DAY";
export const SAVE_GFC_FUNDS = "SAVE_GFC_FUNDS";
export const SAVE_GFC_INVESTMENT_FLAG = "SAVE_GFC_INVESTMENT_FLAG";

//FundSwitchOut
export const FUND_SWITCH_OUT_PERCENTAGE = "FUND_SWITCH_OUT_PERCENTAGE";
export const FUND_SWITCH_ESTIMATED_AMOUNT = "FUND_SWITCH_ESTIMATED_AMOUNT";
export const SAVE_PENDING_FUNDS_LIST = "SAVE_PENDING_FUNDS_LIST";

//Authentication
export const SAVE_AUTHENTICATION_FLAG = "SAVE_AUTHENTICATION_FLAG";

//FundSwitch In
export const ADD_FUND_SWITCH_IN_PERCENT = "ADD_FUND_SWITCH_IN_PERCENT";
export const UPDATE_FUND_SWITCH_IN_PERCENT = "UPDATE_FUND_SWITCH_IN_PERCENT";
export const CLEAR_SWITCH_IN_FUNDS_PERCENT = "CLEAR_SWITCH_IN_FUNDS_PERCENT";
export const SAVE_INVESTMENT_TRANSFER_DETAILS = "SAVE_INVESTMENT_TRANSFER_DETAILS";
export const SAVE_FUND_INFO_DATA = "SAVE_FUND_INFO_DATA";
export const SAVE_ALL_POLICY_FUND_INFO = "SAVE_ALL_POLICY_FUND_INFO";

//Changes
export const CHANGE_FUND_DETAILS = "CHANGE_FUND_DETAILS";
export const SAVE_CHANGES_LIST = "SAVE_CHANGES_LIST";
export const CHANGE_STATUS_TYPE = "CHANGE_STATUS_TYPE";
export const SAVE_CHANGES_PAGINATION = "SAVE_CHANGES_PAGINATION";
export const SAVE_FILTERED_CHANGES_LIST = "SAVE_FILTERED_CHANGES_LIST";
export const RESET_CHANGES_LIST = "RESET_CHANGES_LIST";
export const CHANGES_LIST_LOADING = "CHANGES_LIST_LOADING";
export const SAVE_CURRENT_PAGE_NO = "SAVE_CURRENT_PAGE_NO";
export const SAVE_CURRENT_POSTS_ON_PAGE = "SAVE_CURRENT_POSTS_ON_PAGE";
export const SAVE_FUND_STATUS = "SAVE_FUND_STATUS";
export const SAVE_FUND_FROM_DATE = "SAVE_FUND_FROM_DATE";
export const SAVE_FUND_TO_DATE = "SAVE_FUND_TO_DATE";
export const SAVE_CHANGES_PAGE_EDIT_FLAG = "SAVE_CHANGES_PAGE_EDIT_FLAG";

//FundSwitch Future Asset
export const SAVE_FUNDWISE_PERCENTAGE_ALLOCATION_FUTURE = "SAVE_FUNDWISE_PERCENTAGE_ALLOCATION_FUTURE";

//Accounts Dashboard
export const SAVE_ACCOUNT_INFO = "SAVE_ACCOUNT_INFO";
export const SAVE_CORE_ORSO_ACCOUNT = "SAVE_CORE_ORSO_ACCOUNT";
export const SAVE_POLICY_DETAILS = "SAVE_POLICY_DETAILS";
export const SAVE_ALL_POLICY_DETAILS = "SAVE_ALL_POLICY_DETAILS";
export const SAVE_SELECTED_POLICY_INDEX = "SAVE_SELECTED_POLICY_INDEX";
export const SAVE_SELECTED_ACCOUNT_BALANCE = "SAVE_SELECTED_ACCOUNT_BALANCE";
export const HAVE_SELECTED_ACCOUNT = "HAVE_SELECTED_ACCOUNT";
export const SAVE_POLICY_ENROLLMENT_PERIOD_LIST = "SAVE_POLICY_ENROLLMENT_PERIOD_LISTT";
export const SAVE_ENROLLMENT_DAY_COUNT = "SAVE_ENROLLMENT_DAY_COUNT";
export const IS_AGENCY_PLAN = "IS_AGENCY_PLAN";

//Transaction Contribution History 
export const SAVE_TRANSACTION_CONTRIBUION_HISTORY = "SAVE_TRANSACTION_CONTRIBUION_HISTORY";
export const SAVE_PENDING_FUND = "SAVE_PENDING_FUND";

//Contribution Record
export const SAVE_SELECTED_CONTRIBUTION_RECORD_INDEX = "SAVE_SELECTED_CONTRIBUTION_RECORD_INDEX";

//Contribution Records List
export const SAVE_CONTRIBUTION_RECORDS_LIST = "SAVE_CONTRIBUTION_RECORDS_LIST";

// To and From date - contribution record
export const SAVE_CONTRIBUTION_TO_DATE = "SAVE_CONTRIBUTION_TO_DATE";
export const SAVE_CONTRIBUTION_FROM_DATE = "SAVE_CONTRIBUTION_FROM_DATE";
export const SAVE_CONTRIBUTION_RECORD_TYPE = "SAVE_CONTRIBUTION_RECORD_TYPE";

//Transaction details
export const SAVE_TRANSACTION_DETAILS = "SAVE_TRANSACTION_DETAILS";
export const SAVE_TRANSACTION_HISTORY = "SAVE_TRANSACTION_HISTORY";

//Two Factor Authentication
export const SAVE_SELECTED_CHANNEL = "SAVE_SELECTED_CHANNEL";
export const SAVE_CHANNEL_DATA = "SAVE_CHANNEL_DATA";
export const SAVE_AUTHENTICATE_MFA_DATA = "SAVE_AUTHENTICATE_MFA_DATA";
export const SAVE_SEND_OTP_RESPONSE = "SAVE_SEND_OTP_RESPONSE";
export const SAVE_MFA_ACTIVATION_RESPONSE = "SAVE_MFA_ACTIVATION_RESPONSE";

//Merger Info 
export const SET_IS_MERGER = "SET_IS_MERGER";
export const SAVE_MERGER_ATTRIBUTES = "SAVE_MERGER_ATTRIBUTES";
export const SAVE_NB_EXPENCE = "SAVE_NB_EXPENCE";
export const RESET_MERGER_INFO = "RESET_MERGER_INFO";

//Dashboard
export const SAVE_GAIN_LOSS_DATA = "SAVE_GAIN_LOSS_DATA";
export const SAVE_TOTAL_ACCOUNT_BALANCE = "SAVE_TOTAL_ACCOUNT_BALANCE";
export const SAVE_CUSTOM_FIELDS = "SAVE_CUSTOM_FIELDS";
export const SAVE_CUSTOM_FIELDS_ARRAY = "SAVE_CUSTOM_FIELDS_ARRAY";
export const SAVE_POPUP_ALERT_STATUS = "SAVE_POPUP_ALERT_STATUS";

//portfolio
export const SAVE_INTRAGRP_TRANSFER = "SAVE_INTRAGRP_TRANSFER";
export const SAVE_CONTRIBUTION_DETAILS = "SAVE_CONTRIBUTION_DETAILS";
export const RESET_PORTFOLIO_DETAILS = "RESET_PORTFOLIO_DETAILS";
export const SET_UPDATE_DONE = "SET_UPDATE_DONE";

//Fund Performance
export const SAVE_SCHEME_DATA = "SAVE_SCHEME_DATA";
export const SAVE_SCHEME_800_DATA = "SAVE_SCHEME_800_DATA";
export const SAVE_SCHEME_600_DATA = "SAVE_SCHEME_600_DATA";
export const SAVE_SCHEME_200_DATA = "SAVE_SCHEME_200_DATA";
export const SAVE_SCHEME_500_DATA = "SAVE_SCHEME_500_DATA";
export const SAVE_LATEST_DATE = "SAVE_LATEST_DATE";
export const RESET_FUND_PERFORMANCE = "RESET_FUND_PERFORMANCE";
export const SAVE_SELECTED_FUND_PRICE_PERFORMANCE = "SAVE_SELECTED_FUND_PRICE_PERFORMANCE";
export const SAVE_FEES_CHARGES = "SAVE_FEES_CHARGES";
export const SAVE_POLICY_WISE_FEES_CHARGES = "SAVE_POLICY_WISE_FEES_CHARGES";
export const SAVE_FUND_HISTORY = "SAVE_FUND_HISTORY";
export const SAVE_REDIRECT_FUND_PRICE_PERFORMANCE = "SAVE_REDIRECT_FUND_PRICE_PERFORMANCE";
export const SAVE_FUND_FACT_SHEET_LINK = "SAVE_FUND_FACT_SHEET_LINK";
export const SET_FUND_DETAIL_REDIRECTION = "SET_FUND_DETAIL_REDIRECTION";
export const SAVE_FUND_CLASS_DATA = "SAVE_FUND_CLASS_DATA";
export const SAVE_FUND_INFO_CONTRACT_NMBR = "SAVE_FUND_INFO_CONTRACT_NMBR";
export const SAVE_FUND_INFO_PRODUCT_NMBR = "SAVE_FUND_INFO_PRODUCT_NMBR";

//Contract Custom Feils
export const SAVE_CUSTOM_CONTRACT_FILD = "SAVE_CUSTOM_CONTRACT_FILD";
export const SAVE_MERGED_CONTRACT_CUSTOM_ATTRIBUTES = "SAVE_MERGED_CONTRACT_CUSTOM_ATTRIBUTES";
// SSO constants(SuperLogOn)
export const SAVE_CODE_CHALLENGE = "SAVE_CODE_CHALLENGE";
export const SAVE_CODE_VERIFIER = "SAVE_CODE_VERIFIER";
export const SAVE_NONCE = "SAVE_NONCE";
export const SAVE_STATE = "SAVE_STATE";
