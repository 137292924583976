import { instance } from '../AxiosInterceptor';

export default {
    getPolicyDetails: (policyjson, requestHeaders) =>
        instance({
            'method': 'POST',
            'url': '/accounts/policy',
            headers: requestHeaders,
            data: policyjson
        }),

    getContributionRecords: (fromDt, toDt, clntRltnShpNmbr, requestHeaders, schemeNm) =>
        instance({
            'method': 'GET',
            'url': '/transactions/transactionhistory',
            headers: requestHeaders,
            params: {
                clntRltnShpNmbr: clntRltnShpNmbr,
                fromDate: fromDt,
                toDate: toDt,
                schemeName: schemeNm
            }
        }),
    getTransactionContributionHistory: (fromDt, toDt, clntRltnShpNmbr, openingBalanceDate, closingBalanceDate, contractNumber, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/transactions/transactioncontributionhistoryservice',
            headers: requestHeaders,
            params: {
                clntRltnShpNmbr: clntRltnShpNmbr,
                fromDate: fromDt,
                toDate: toDt,
                contractNmbr: contractNumber,
                fromAsOfDt: openingBalanceDate,
                toAsOfDt: closingBalanceDate
            }
        }),

    getContributionDetails: (requestData, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/transactions/transactiondetails',
            headers: requestHeaders,
            params: {
                clntRltnShpNmbr: requestData.clntRltnShpNmbr,
                trnsHstSeqNmbr: requestData.trnsHstSeqNmbr,
                trnsHstEfctvDt: requestData.trnsHstEfctvDt,
                history: 1,
                wire: 1,
                investment: 1,
                earningSplits: 1,
                altAddress: 1,
                feesTaxes: 1
            }
        }),
    getTransactionDetailsInfo: (requestData, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/transactions/transactiondetailinfo',
            headers: requestHeaders,
            params: {
                clntRltnShpNmbr: requestData.clntRltnShpNmbr,
                trnsHstSeqNmbr: requestData.trnsHstSeqNmbr,
                trnsHstEfctvDt: requestData.trnsHstEfctvDt,
                history: true,
                wire: true,
                investment: true,
                earningSplits: true,
                altAddress: true,
                feesTaxes: true
            }
        }),
    getFeesAndChargesRecords: (clntRltnShpNmbr, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/accounts/feesandcharges',
            headers: requestHeaders,
            params: {
                clntRltnShpNmbr: clntRltnShpNmbr
            }
        }),

    getQuickPolicyInfo: (clientId, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/accounts/quickpolicyinfo?clientId=' + clientId,
            headers: requestHeaders
        }),

    getAsOfDate: (productTypeNmbr, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/investments/latestpricedate?productTypeNmbr=' + productTypeNmbr,
            headers: requestHeaders
        }),

    getQualifyingBalance: (requestData, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/accounts/qualifyingBalance',
            headers: requestHeaders,
            params: {
                clntRltnShpNmbr: requestData.clntRltnShpNmbr,
                asOfDt: requestData.asOfDt,
                rtnDtlFlag: requestData.rtnDtlFlag
            }
        }),
    getQualifyingBalanceHKAIIB: (requestData, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/accounts/qualifyingBalance',
            headers: requestHeaders,
            params: {
                clntRltnShpNmbr: requestData.clntRltnShpNmbr,
                asOfDt: requestData.asOfDt,
                rtnDtlFlag: requestData.rtnDtlFlag,
                schemeName: requestData.schemeName
            }
        }),
    getfilteredAccounts: (clientNmbr, requestHeaders) =>
        instance({
            'method': 'GET',
            'url': '/accounts/filteredaccounts',
            headers: requestHeaders,
            params: {
                clientNmbr: clientNmbr
            }
        }),
    getAccountDetails: (clientId, requestHeaders) =>
        instance({
            method: 'GET',
            url: '/accounts/accountSummary',
            headers: requestHeaders,
            params: {
                clientId
            }
        })
};