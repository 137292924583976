import accountApi from "../API/Account/accountApi";
import { saveAllPolicyDetails, saveLoginSchemeProduct, saveMergedContractCustomAttributes, saveOrsoAccFlag, saveQuickPolicyInfo } from "../actions/AccountAction";
import { saveAsOfDate, saveCutOffHours, saveWebCycleDate } from "../actions/EmployeeLoginAction";
import { savePolicyWiseFeesAndCharges } from "../actions/FundPerformanceAction";
import { saveAllPolicyFundInfo } from "../actions/FundSwitchAction";
import { saveClientDetails, saveCycleDate, saveMultipleClientDetails } from "../actions/LandingAction";
import { saveAuthenticationFlag } from "../actions/TwoFactorAuthenticationAction";
import { CORE_ORSO_ACCOUNT_S800, HKAIIB, S800Acronym, productTypeORSO } from "../translations/common/commonConstants";

const firstStageFilter = (quickPolicyInfo, schemeSelected) => {
  let filteredAccounts = [];
  for (let index = 0; index < quickPolicyInfo.length; index++) {
    const productAcronym = quickPolicyInfo[index]?.product?.productAcronym?.toUpperCase().trim();
    if (schemeSelected === "S800") {
      if (((productAcronym === "S800") ||
        (CORE_ORSO_ACCOUNT_S800.includes(quickPolicyInfo[index]?.product?.productTypeNmbr))) &&
        (quickPolicyInfo[index]?.narrative === null || quickPolicyInfo[index]?.narrative?.narrativePK?.nrtvTypeNmbr !== 107)
      ) {
        filteredAccounts.push(quickPolicyInfo[index]);
      }
    } else if (schemeSelected === HKAIIB) {
      if (
        (productAcronym === HKAIIB) &&
        (quickPolicyInfo[index]?.narrative === null ||
          quickPolicyInfo[index]?.narrative?.narrativePK?.nrtvTypeNmbr !== 107)
      ) {
        filteredAccounts.push(quickPolicyInfo[index]);
      }
    } else {
      if (
        (productAcronym === "SMAP" || productAcronym === "SIMP") &&
        (quickPolicyInfo[index]?.narrative === null ||
          quickPolicyInfo[index]?.narrative?.narrativePK?.nrtvTypeNmbr !== 107)
      ) {
        filteredAccounts.push(quickPolicyInfo[index]);
      }
    }
  }
  return filteredAccounts;
};

const saveAccountDetails = async (dispatch, scheme, clientNmbr, clientNmbrList, pisysToken, setServiceCallFlag, sendNotification) => {
  const requestHeaders = {
    'pisys_auth_token': pisysToken,
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };

  let accountSummaryMultiClientResponse = [];
  setServiceCallFlag(true);
  const responseData = (clientNmbrList).map(clientNmbr => accountApi.getAccountDetails(String(clientNmbr), requestHeaders));
  await Promise.all(responseData).then((responses) => {
    responses.forEach((response) => {
      if (response !== null && response.status == 200) {
        let decodedResponse = response?.data;
        accountSummaryMultiClientResponse.push(decodedResponse);
      }
    });
  }).catch(() => {
    dispatch(saveAuthenticationFlag(false));
    setServiceCallFlag(false);
    sendNotification('technical-error');
  });

  const cycleDateRes = accountSummaryMultiClientResponse[0]?.cycleDt;
  const cycleDate = cycleDateRes?.find((date) => date?.dateTypeCd === '1');

  let quickPolicyInfo = [];
  const filteredAccounts = [];
  const feesAndChargesArray = [];
  const mergedPolicyDetailsResponseList = [];
  const mergedFundInfoRiskCurrencyVOsList = [];
  const mergedContractCustomAttributes = [];
  accountSummaryMultiClientResponse.forEach(accountSummary => {
    quickPolicyInfo.push(...accountSummary.policyMinVos);
    filteredAccounts.push(...accountSummary.filteredAccountVOs);
    feesAndChargesArray.push(...accountSummary.feesChargesResponseVO);
    mergedPolicyDetailsResponseList.push(...accountSummary.policyDetailsResponseList);
    mergedFundInfoRiskCurrencyVOsList.push(...accountSummary.fundInfoRiskCurrencyVOsList);
    mergedContractCustomAttributes.push(...accountSummary.contrctCustomFieldResponse);
  });

  let filteredQuickPolicyInfo = [];
  const policyDetailsList = [];
  quickPolicyInfo = firstStageFilter(quickPolicyInfo, scheme);
  if (Array.isArray(quickPolicyInfo) && Array.isArray(filteredAccounts)) {
    for (let index = 0; index < filteredAccounts.length; index++) {
      const account = filteredAccounts[index];
      const filteredPolicy = quickPolicyInfo.find((policy) => policy?.clntRltnshpNmbr === account?.clntRltnShpNmbr && policy?.contract?.contractNmbr === account?.contractNmbr);
      if (filteredPolicy) {
        filteredQuickPolicyInfo.push(filteredPolicy);
        const policyDetail = mergedPolicyDetailsResponseList.find((policyDtl) => policyDtl?.policyVO?.clntRltnshpNmbr === filteredPolicy?.clntRltnshpNmbr) ?? {};
        policyDetailsList.push(policyDetail);
      }
    }
  }
  if (Array.isArray(filteredQuickPolicyInfo) && filteredQuickPolicyInfo.length > 0) {
    let productAcronym = '';
    if (filteredQuickPolicyInfo[0]?.product?.productTypeName?.toUpperCase().includes(productTypeORSO) && scheme == S800Acronym) {
      productAcronym = S800Acronym;
      dispatch(saveOrsoAccFlag(true));
    } else {
      productAcronym = filteredQuickPolicyInfo[0]?.product?.productAcronym?.toUpperCase().trim();
      dispatch(saveOrsoAccFlag(false));
    }

    // merge and store client info responses for all clients
    const mergedClientInfoResponses = [];
    accountSummaryMultiClientResponse?.forEach(response => mergedClientInfoResponses.push(response.clientInfoResponse));
    dispatch(saveMultipleClientDetails(mergedClientInfoResponses));

    dispatch(saveLoginSchemeProduct(productAcronym));
    dispatch(saveQuickPolicyInfo(filteredQuickPolicyInfo));
    dispatch(saveAllPolicyDetails(policyDetailsList ?? []));
    dispatch(saveAllPolicyFundInfo(mergedFundInfoRiskCurrencyVOsList ?? []));
    dispatch(savePolicyWiseFeesAndCharges(feesAndChargesArray));

    // stored default client data for latest policy effecctive date
    const accountSummaryResponseForLatestEffectiveDate = accountSummaryMultiClientResponse?.find(response => response?.clientInfoResponse?.clientNmbr == clientNmbr);
    dispatch(saveClientDetails(accountSummaryResponseForLatestEffectiveDate.clientInfoResponse ?? {}));

    const latesPriceDate = accountSummaryMultiClientResponse.find(response => response?.filteredAccountVOs.length !== 0)?.priceDates[0];
    dispatch(saveMergedContractCustomAttributes(mergedContractCustomAttributes));
    dispatch(saveCycleDate(cycleDate?.cycleDt));
    dispatch(saveAsOfDate(latesPriceDate?.priceDate));
    dispatch(saveCutOffHours(latesPriceDate?.cutOffHours ?? 16));
    dispatch(saveWebCycleDate(latesPriceDate?.webCycleDate));
  } else {
    dispatch(saveLoginSchemeProduct(''));
    dispatch(saveQuickPolicyInfo([]));
    dispatch(saveAllPolicyDetails([]));
    dispatch(saveAuthenticationFlag(false));
    setServiceCallFlag(false);
    sendNotification('no-valid-account');
  }
};

export default { firstStageFilter, saveAccountDetails };
