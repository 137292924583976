import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { savePopUpAlertStatus } from "../../actions/DashboardAction";
import { saveMergerAttributes, setIsMerger } from "../../actions/MergerInfoAction";
import api from "../../API/Account/accountApi";
import FundFactSheetIcon from "../../assets/shortcut_fund-fact-sheet.svg";
import KeyIcon from "../../assets/shortcut_key.svg";
import TransferIcon from "../../assets/shortcut_transfer.svg";
import UserInfoIcon from "../../assets/shortcut_userinfo.svg";
import "../../css/Dashboard.css";
import CardContainer from "../../SharedComponents/CardContainer";
import Loading from "../../SharedComponents/LoadingSection";
import Logger from "../../SharedComponents/Logger";
import PromotionalBanner from "../../SharedComponents/PromotionalBanner";
import WelcomeBlock from "../../SharedComponents/WelcomeBlock";
import { DD_MM_YYYY_HH_mm_ss, PRODUCT_TYPE_NMBR_ORSO_CHUBB, PRODUCT_TYPE_NMBR_ORSO_CRC, PRODUCT_TYPE_NMBR_ORSO_FTLIFE, PRODUCT_TYPE_NMBR_ORSO_HKSI, PRODUCT_TYPE_NMBR_ORSO_MAERSK, PRODUCT_TYPE_NMBR_ORSO_PD, PRODUCT_TYPE_NMBR_ORSO_PTC, SMAP_PRODUCT, asiaHongKong, enGB, faqGfcS800_EN, faqGfcS800_ZH, faqGfcSmap_EN, faqGfcSmap_ZH, linkForKeyNoteGfcS800_EN, linkForKeyNoteGfcS800_ZH, linkForKeyNoteGfcSmap_EN, linkForKeyNoteGfcSmap_ZH, schemeS800, transTyp } from "../../translations/common/commonConstants";
import { DashBoardUtils, FormatUtils, SortUtils, UiUtils, getContractInfo } from "../../utils";
import DBAccBalance from "./DBAccBalance";
import DBFundDistribution from "./DBFundDistribution";
import DBFundsList from "./DBFundsList";
import DBShortcut from "./DBShortcut";
import Modal from "../../SharedComponents/Modal";

const renderWelcomeBlock = (props, accountType) => {
  const clientName = FormatUtils.getClientName(props?.clientInfo);
  return (
    <WelcomeBlock
      contractNmbr={props.contractNmbr}
      accountType={accountType}
      username={clientName}
    />
  );
};

const renderAccountBalanceCard = (policyList, webCycleDate, clientNmbr, clntRltnshpNmbr, pisysToken, data, totalAmt) => {
  return (
    <CardContainer>
      <DBAccBalance data-testid="db-acc-balance" policyList={policyList} webCycleDate={webCycleDate} clientNmbr={clientNmbr} clntRltnshpNmbr={clntRltnshpNmbr} pisysToken={pisysToken} data={data} totalAmt={totalAmt} />
    </CardContainer>
  );
};

const renderFundsDistCard = (data, totalAmt) => {
  return (
    <CardContainer>
      <DBFundDistribution data={data} totalAmt={totalAmt} />
    </CardContainer>
  );
};

const renderFundsListCard = (data, totalAmt) => {
  return (
    <CardContainer>
      <DBFundsList data={data} totalAmt={totalAmt} />
    </CardContainer>
  );
};

const renderShortcutCard = (icon, title, goToPage, goToLink) => {
  return (
    <CardContainer>
      <DBShortcut
        icon={icon}
        title={title}
        goToPage={goToPage}
        goToLink={goToLink}
      />
    </CardContainer>
  );
};

const renderPromotionalBanner = () => {
  return (
    <div className="dashboard-promo-banner">
      <PromotionalBanner />
    </div>
  );
};

const Dashboard = (props) => {
  const { t, i18n } = useTranslation("common");
  const [data, setdata] = useState([]);
  const [totalAmt, setTotalAmt] = useState(null);
  const selectedQuickPolicyInfo = props?.quickPolicyInfo[props.selectedPolicyIndex];
  const accountType = selectedQuickPolicyInfo.accountType;
  const [remarksArr, setRemarkArr] = useState([]);
  const [serviceCallFlag, setServiceCallFlag] = useState(true);
  const [bannerRequired] = useState(false);
  const [remarkShowFlag, setRemarkShowFlag] = useState(true);
  const schemeName = props?.quickPolicyInfo[props.selectedPolicyIndex]?.product?.productAcronym;
  const productTypeNmbrForOrso = props.quickPolicyInfo[props.selectedPolicyIndex]?.product?.productTypeNmbr
  const productTypeNumberOrsoArr = [PRODUCT_TYPE_NMBR_ORSO_CRC, PRODUCT_TYPE_NMBR_ORSO_MAERSK,PRODUCT_TYPE_NMBR_ORSO_CHUBB,PRODUCT_TYPE_NMBR_ORSO_PTC,PRODUCT_TYPE_NMBR_ORSO_FTLIFE,PRODUCT_TYPE_NMBR_ORSO_HKSI,PRODUCT_TYPE_NMBR_ORSO_PD]
  let faqLinkOrso , faqLinkOrso_ZH

  switch(productTypeNmbrForOrso){
    case PRODUCT_TYPE_NMBR_ORSO_CRC:
        faqLinkOrso = "https://172.17.84.163/Principal.webapp/download/GFC_CRC_FAQ_EN.PDF"
        faqLinkOrso_ZH = "https://172.17.84.163/Principal.webapp/download/GFC_CRC_FAQ_CH.PDF"
        break;
    case PRODUCT_TYPE_NMBR_ORSO_PD:
        faqLinkOrso = "https://172.17.84.163/Principal.webapp/download/GFC_PD_FAQ_EN.PDF"
        faqLinkOrso_ZH = "https://172.17.84.163/Principal.webapp/download/GFC_PD_FAQ_CH.PDF"
        break;
    case PRODUCT_TYPE_NMBR_ORSO_HKSI:
        faqLinkOrso = "https://172.17.84.163/Principal.webapp/download/GFC_QBE_FAQ_EN.PDF"
        faqLinkOrso_ZH = "https://172.17.84.163/Principal.webapp/download/GFC_QBE_FAQ_CH.PDF"
        break;
    case PRODUCT_TYPE_NMBR_ORSO_MAERSK:
        faqLinkOrso = "https://172.17.84.163/Principal.webapp/download/GFC_MAERSK_FAQ_EN.PDF"
        faqLinkOrso_ZH = "https://172.17.84.163/Principal.webapp/download/GFC_MAERSK_FAQ_CH.PDF"
        break;
    case PRODUCT_TYPE_NMBR_ORSO_FTLIFE:
        faqLinkOrso = "https://172.17.84.163/Principal.webapp/download/GFC_FTL_FAQ_EN.PDF"
        faqLinkOrso_ZH = "https://172.17.84.163/Principal.webapp/download/GFC_FTL_FAQ_CH.PDF"
        break;
    case PRODUCT_TYPE_NMBR_ORSO_CHUBB:
        faqLinkOrso = "https://172.17.84.163/Principal.webapp/download/GFC_CHUBB_FAQ_EN.PDF"
        faqLinkOrso_ZH = "https://172.17.84.163/Principal.webapp/download/GFC_CHUBB_FAQ_CH.PDF"
        break;
    case PRODUCT_TYPE_NMBR_ORSO_PTC:
        faqLinkOrso = "https://172.17.84.163/Principal.webapp/download/GFC_PTC_FAQ_EN.PDF"
        faqLinkOrso_ZH = "https://172.17.84.163/Principal.webapp/download/GFC_PTC_FAQ_CH.PDF"
        break;
      default:
        break;
  }

  let keyNoteLink, keyNoteLink_ZH, faqLink, faqLink_ZH;
  if (schemeName?.includes(schemeS800)) {

    keyNoteLink = linkForKeyNoteGfcS800_EN;
    faqLink = faqGfcS800_EN;
    keyNoteLink_ZH = linkForKeyNoteGfcS800_ZH;
    faqLink_ZH = faqGfcS800_ZH;
  }
  else{
    keyNoteLink = linkForKeyNoteGfcSmap_EN;
    faqLink = faqGfcSmap_EN;
    keyNoteLink_ZH = linkForKeyNoteGfcSmap_ZH;
    faqLink_ZH = faqGfcSmap_ZH;
  }

  const renderWarningPopUp = (t, props) => {

    return (

      <div className={"db-popup-modal-blurbg pds-modal db-hkpd-modal"} id="popup-exp-alert">
        <div className="pds-modal-dialog db-popup-modal-dialog" role="dialog" aria-labelledby="modal-title" aria-hidden="false">
          <div className='container col-12'>
            <h4>{t("dashboard.hkpdWarningPopupHeading")}</h4>
            <div className='db-popup-text'>

              <p>{t("dashboard.hkpdWarningPopupText1")}</p>
              <p>{t("dashboard.hkpdWarningPopupText2")}</p>

              <ul className="list-bullet col-12">
                <li>{t("dashboard.hkpdWarningPopupEmail")}<a href="https://www.principal.com.hk/contact-us?_ga=2.215575765.390996390.1657007102-930760776.1638256833">hkinfo@principal.com</a></li>
                <li>{t("dashboard.hkpdWarningPopupHotlines")} 2827 1233</li>
              </ul>
              <p>{t("dashboard.hkpdWarningPopupText3")}</p>
            </div>
            <div className="pds-modal-cta">
              <button className='db-popup-ok-btn pds-modal-close-dialog' data-dismiss="db-popup-modal-blurbg pds-modal db-popup-modal" data-gtm='modal-cta' onClick={() => { props.dispatch(savePopUpAlertStatus(true)) }}>{t("common.ok")}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderTerminationNotice = (t, scheme) => {
    return (

      <div className={"db-popup-modal-blurbg pds-modal db-popup-modal"} id="popup-exp-alert">
        <div className="pds-modal-dialog db-popup-modal-dialog" role="dialog" aria-labelledby="modal-title" aria-hidden="false">
          <div className='container col-12'>
            <h4>{t("dashboard.terminationMsgHeading")}</h4>
           <div className='db-popup-text'>
                    <p>{scheme?.includes(schemeS800) ? t("dashboard.terminationMsgs800Text1") : t("dashboard.terminationMsgSmartText1")}</p>
                    <p>{scheme?.includes(schemeS800) ? t("dashboard.terminationMsgText3Mb") : t("dashboard.terminationMsgText3MbSmart")}</p>
                    <p>{scheme?.includes(schemeS800) ? t("dashboard.terminationMsgText4Mb"): t("dashboard.terminationMsgText4MbSmart")}</p>
                    <p>{t("dashboard.terminationMsgClickhereMb1")}<u><a className="el-link" target="_blank" rel="noreferrer" href ={i18n.language === 'en' ? keyNoteLink : keyNoteLink_ZH }>{t("dashboard.here")}</a></u>{t("dashboard.terminationMsgClickhereMb2")}</p>
                    <p>{t("dashboard.terminationMsgClickhereFaqMb1")}<u><a className="el-link" target="_blank" rel="noreferrer" href ={i18n.language === 'en' ? faqLink : faqLink_ZH}>{t("dashboard.here")}</a></u>{t("dashboard.terminationMsgClickhereFaqMb2")}</p>
                  
            </div>
            <div className="pds-modal-cta">
              <button className='db-popup-ok-btn pds-modal-close-dialog' data-dismiss="db-popup-modal-blurbg pds-modal db-popup-modal" data-gtm='modal-cta' onClick={() => { props.dispatch(savePopUpAlertStatus(true)) }}>{t("common.ok")}</button>
            </div>
          </div>
        </div>
      </div>
    )

  }
  const renderTerminationNoticeOrso = (t, props) => {
    return (

      <div className={"db-popup-modal-blurbg pds-modal db-popup-modal"} id="popup-exp-alert">
        <div className="pds-modal-dialog db-popup-modal-dialog" role="dialog" aria-labelledby="modal-title" aria-hidden="false">
          <div className='container col-12'>
            <h4>{t("dashboard.terminationMsgHeading")}</h4>
            <div className='db-popup-text'>
              <p>{props.isOrsoAccount && productTypeNmbrForOrso === PRODUCT_TYPE_NMBR_ORSO_FTLIFE ? t("dashboard.terminationMsgOrsoText1FT") : productTypeNmbrForOrso === PRODUCT_TYPE_NMBR_ORSO_PD ? t("dashboard.terminationMsgOrsoText1PD") : t("dashboard.terminationMsgOrsoText1")}</p>
              <p>{t("dashboard.terminationMsgText3Orso")}</p>
              <p>{t("dashboard.terminationMsgText4Orso")}</p>
              <p>{t("dashboard.terminationMsgClickhereFaqMb1")}<u><a className="el-link" target="_blank" rel="noreferrer" href={i18n.language === 'en' ? faqLinkOrso : faqLinkOrso_ZH}>{t("dashboard.here")}</a></u>{t("dashboard.terminationMsgClickhereFaqMb2")}</p>

            </div>
            <div className="pds-modal-cta">
              <button className='db-popup-ok-btn pds-modal-close-dialog' data-dismiss="db-popup-modal-blurbg pds-modal db-popup-modal" data-gtm='modal-cta' onClick={() => { props.dispatch(savePopUpAlertStatus(true)) }}>{t("common.ok")}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    getContractInfo(props, props.contractNmbr).then((info) => {
      setServiceCallFlag(true);
      if (info) {
        props.dispatch(setIsMerger(info.length > 0 && true))
        props.dispatch(saveMergerAttributes(info))
        checkForRemarks(info);
        setServiceCallFlag(false);
      }

    })
    let policyList = [];
    if (props.policyList && props.policyList.length > 1) {
      policyList = props.policyList[props.selectedPolicyIndex];
    } else if (props.policyList && props.policyList.length === 1) {
      policyList = props.policyList[0];
    } else {
      policyList = props.policyList;
    }
    let total = DashBoardUtils.getTotalInvestmentAmount(policyList);
    setdata(DashBoardUtils.getDonutChartData(policyList));
    setTotalAmt(total);
  }, []);
  const checkForRemarks = (mergerAttributes) => {
    let sortedMergerAttr = mergerAttributes?.sort(SortUtils.sortOnDate("efctvDt", "asc", "DD/MM/YYYY"));
    let headers = {
      'pisys_auth_token': props.pisysToken,
      'username': props.clientNmbr,
      'Accept-Language': 'en',
      'Access-Control-Allow-Origin': '*'
    }
    const clntRltnshpNmbr = (props.clntRltnshpNmbr);
    let responseArr = [];
    for (let i = 0; i < sortedMergerAttr.length; i++) {
      const encodedToDt = (sortedMergerAttr[i].efctvDt);
      const encodedFromDt = (sortedMergerAttr[i].efctvDt);
      responseArr.push(api.getContributionRecords(encodedFromDt, encodedToDt, clntRltnshpNmbr, headers))
    }
    let remarkFlag = [];
    Promise.all(responseArr).then((responses) => {
      responses.forEach((response) => {
        if (response.status == 200) {
          let decodedResponse = response.data
          decodedResponse = decodedResponse?.transactionHistoryResponseVos ? decodedResponse.transactionHistoryResponseVos : [];
          if (Array.isArray(decodedResponse) && decodedResponse.length > 0) {
            let flag = false;
            decodedResponse.forEach(element => {
              if (element?.subTrnsNm?.includes(transTyp)) {
                flag = true;
                if (flag == true) {
                  remarkFlag.push(true);
                }
              }
              return;
            });
          } else {
            remarkFlag.push(false);
          }
        }
      });
      setRemarkArr(remarkFlag);
      setRemarkShowFlag(false);
    }).catch(() => { setServiceCallFlag(false); setRemarkArr([]) })
  }
  const startDate = moment.utc("2022-08-16").set({ "hour": 8, "minute": 0 })
  const endDate = moment.utc("2022-12-01").set({ "hour": 8, "minute": 0 })
  // eslint-disable-next-line no-undef
  const termMsgStartDate = moment(process.env.REACT_APP_GFC_NOTICE_START_DATE, DD_MM_YYYY_HH_mm_ss, true);
  // eslint-disable-next-line no-undef
  const termMsgEndDate = moment(process.env.REACT_APP_GFC_NOTICE_END_DATE, DD_MM_YYYY_HH_mm_ss, true);
  const currentDate = moment.utc(new Date);

  const currentDateHk = new Date();
  const hkTimeString = currentDateHk.toLocaleString(enGB, { timeZone: asiaHongKong });
  const hkTime = moment(hkTimeString, DD_MM_YYYY_HH_mm_ss, true);
  let termMsgStartDateOrso , termMsgEndDateOrso;

  if(props?.isOrsoAccount){
  
    switch (productTypeNmbrForOrso) {
      case PRODUCT_TYPE_NMBR_ORSO_CRC:
        // eslint-disable-next-line no-undef
        termMsgStartDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_START_DATE_ORSO_CRC, DD_MM_YYYY_HH_mm_ss, true);
        // eslint-disable-next-line no-undef
        termMsgEndDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_END_DATE_ORSO_CRC, DD_MM_YYYY_HH_mm_ss, true);
        break;
      case PRODUCT_TYPE_NMBR_ORSO_MAERSK:
        // eslint-disable-next-line no-undef
        termMsgStartDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_START_DATE_ORSO_MAERSK, DD_MM_YYYY_HH_mm_ss, true);
        // eslint-disable-next-line no-undef
        termMsgEndDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_END_DATE_ORSO_MAERSK, DD_MM_YYYY_HH_mm_ss, true);
        break;
      case PRODUCT_TYPE_NMBR_ORSO_CHUBB:
        // eslint-disable-next-line no-undef
        termMsgStartDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_START_DATE_ORSO_CHUBB, DD_MM_YYYY_HH_mm_ss, true);
        // eslint-disable-next-line no-undef
        termMsgEndDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_END_DATE_ORSO_CHUBB, DD_MM_YYYY_HH_mm_ss, true);
        break;
      case PRODUCT_TYPE_NMBR_ORSO_FTLIFE:
        // eslint-disable-next-line no-undef
        termMsgStartDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_START_DATE_ORSO_FTLIFE, DD_MM_YYYY_HH_mm_ss, true);
        // eslint-disable-next-line no-undef
        termMsgEndDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_END_DATE_ORSO_FTLIFE, DD_MM_YYYY_HH_mm_ss, true);
        break;
      case PRODUCT_TYPE_NMBR_ORSO_PTC:
        // eslint-disable-next-line no-undef
        termMsgStartDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_START_DATE_ORSO_PTC, DD_MM_YYYY_HH_mm_ss, true);
        // eslint-disable-next-line no-undef
        termMsgEndDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_END_DATE_ORSO_PTC, DD_MM_YYYY_HH_mm_ss, true);
        break;
        case PRODUCT_TYPE_NMBR_ORSO_HKSI:
        // eslint-disable-next-line no-undef
        termMsgStartDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_START_DATE_ORSO_HKSI, DD_MM_YYYY_HH_mm_ss, true);
        // eslint-disable-next-line no-undef
        termMsgEndDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_END_DATE_ORSO_HKSI, DD_MM_YYYY_HH_mm_ss, true);
        break;
        case PRODUCT_TYPE_NMBR_ORSO_PD:
          // eslint-disable-next-line no-undef
          termMsgStartDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_START_DATE_ORSO_PD, DD_MM_YYYY_HH_mm_ss, true);
          // eslint-disable-next-line no-undef
          termMsgEndDateOrso = moment(process.env.REACT_APP_GFC_NOTICE_END_DATE_ORSO_PD, DD_MM_YYYY_HH_mm_ss, true);
          break;
      default:
        break;
    }
  }

  if (totalAmt !== null && serviceCallFlag === false && !remarkShowFlag) {
    let sortedMergerAttr = props.mergerAttributes?.sort(SortUtils.sortOnDate("efctvDt", "asc", "DD/MM/YYYY"));
    return (
      <>
        <Logger logImpression data={{ page: "Dashboard Page" }}>
          { productTypeNmbrForOrso === 125 && currentDate > startDate && currentDate < endDate && !(props.popUpAlertStatus) && renderWarningPopUp(t, props)}
          { (schemeName?.includes(schemeS800) || schemeName?.includes(SMAP_PRODUCT)) && (hkTime.toDate() > (termMsgStartDate?.toDate()) && hkTime.toDate() < termMsgEndDate?.toDate())  && !(props.popUpAlertStatus) && renderTerminationNotice(t, schemeName)}
          { ( productTypeNumberOrsoArr.includes(productTypeNmbrForOrso)) && (hkTime.toDate() > (termMsgStartDateOrso?.toDate()) && hkTime.toDate() < termMsgEndDateOrso?.toDate())  && !(props.popUpAlertStatus) && renderTerminationNoticeOrso(t, props)}
          {UiUtils.determineMPFTermination("initialTerminationNote", Number(props.productTypeNmbr)) &&
              <Modal openOnLoad={true}
                title={t("common.impNotice")}
                textLine1={t("mpfClosure.mpfClosureNote4")}
                textLine2={t("mpfClosure.mpfClosureNote2")}
                textLine3={t("mpfClosure.mpfClosureNote3")}
                redirect={"/dashboard"}
              />
            }

          <div className="main-content" data-testid="dashBoard-test-id">
            <div className="container dashboard-container">
              <div className="row dashboard-welcome-container">
                {renderWelcomeBlock(props, accountType)}
              </div>

              <div className="row">
                <div className="col-lg-4 col-sm-12 dashboard-acc-balance-card" data-testid="db-acc-balance">
                  {renderAccountBalanceCard(
                    props.policyList.length ? props.policyList[props.selectedPolicyIndex] : props.policyList, props.webCycleDate, props.clientNmbr, props.clntRltnshpNmbr, props.pisysToken, data, totalAmt
                  )}
                </div>
                <div className="col-lg-8 col-sm-12 dashboard-acc-fund-dist-card" data-testid="db-fund-distribution">
                  {renderFundsDistCard(data, totalAmt)}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-sm-12 dashboard-acc-funds-table-card" data-testid="db-funds-list">
                  {renderFundsListCard(data, totalAmt)}
                </div>
              </div>
              <div className="row">
                <div className="col-12 dashboard-acc-funds-table-card">
                  <span className="dashboard-remarks-section-line1">{t("accBalByFund.note")}</span>
                  <label className="dashboard-remarks-section-line2">{t("dashboard.decimalNote")}</label>
                </div>
              </div>
              {DashBoardUtils.isSEP(props?.policyList, props.selectedPolicyIndex, selectedQuickPolicyInfo) && <div className="row">
                <div className="col-lg-12 col-sm-12 dashboard-acc-funds-table-card">
                  <label className="dashboard-remarks-section-line1">{t("common.impNotice")}</label>
                  <label className="dashboard-remarks-section-line2">{t("dashboard.impNote")}</label>
                </div>
              </div>}
              <div className="row dasboard-shortcut-cards-section">
                <div className="col-lg-3 col-sm-6 col-xs-6 dashboard-shortcut-card">
                  {renderShortcutCard(
                    UserInfoIcon,
                    t("dashboard.PersonalInfo"),
                    "/profile"
                  )}
                </div>
                <div className="col-lg-3 col-sm-6 col-xs-6 dashboard-shortcut-card">
                  {renderShortcutCard(
                    KeyIcon,
                    t("dashboard.ChangePin"),
                    "/changePin"
                  )}
                </div>
                <div className="col-lg-3 col-sm-6 col-xs-6 dashboard-shortcut-card">
                  {renderShortcutCard(
                    FundFactSheetIcon,
                    t("dashboard.WhatsNew"),
                    null,
                    i18n.language === 'zh' ? "https://www.principal.com.hk/zh#block-views-block-latest-news-articles-block-1-2" : "https://www.principal.com.hk#block-views-block-latest-news-articles-block-1-2"
                  )}
                </div>
                <div className="col-lg-3 col-sm-6 col-xs-6 dashboard-shortcut-card">
                  {renderShortcutCard(
                    TransferIcon,
                    t("dashboard.FundSwitching"),
                    "/fundSwitch"
                  )}
                </div>
              </div>
              {
                (props.isMerger && remarksArr.indexOf(true) > -1) &&
                <div className="row dashboard-remarks-section">
                  <span className="col-12 dashboard-remarks-section-line1">
                    {t("dashboard.remarksline1")}
                  </span>
                  {sortedMergerAttr && sortedMergerAttr.map((obj, index) => {
                    const schemeArr = i18n.language === 'zh' ? DashBoardUtils.getSchemeName(obj.cstmAttrTxt, 'zh') : DashBoardUtils.getSchemeName(obj.cstmAttrTxt, 'en');
                    return (
                      <>
                        {schemeArr.length > 0 && remarksArr?.[index] &&
                          <span className="col-12 dashboard-remarks-section-line2" key={index}>
                            {t("dashboard.remarksline2")}{moment(obj.efctvDt, "DD/MM/YYYY").format(i18n.language === 'en' ? "DD MMM YYYY" : 'YYYY年M月D日')} {i18n.language === 'zh' ? "起" : ""} , {schemeArr[0]} {t("dashboard.remarksline3")} {schemeArr[1]}
                          </span>
                        }</>)
                  })}
                </div>
              }

            </div>
          </div>
          {bannerRequired ? renderPromotionalBanner() : (false)}
        </Logger>
      </>
    )
  } else {
    return (<Loading />)
  }
};

Dashboard.propTypes = {
  policyList: PropTypes.array,
  webCycleDate: PropTypes.string,
  clientNmbr: PropTypes.string,
  clntRltnshpNmbr: PropTypes.string,
  pisysToken: PropTypes.string,
  quickPolicyInfo: PropTypes.array,
  selectedPolicyIndex: PropTypes.number,
  isMerger: PropTypes.bool,
  mergerAttributes: PropTypes.array,
  props: PropTypes.any,
  dispatch: PropTypes.func,
  contractNmbr: PropTypes.string,
  loginId: PropTypes.string,
  clientInfo: PropTypes.object,
  popUpAlertStatus: PropTypes.bool,
  productTypeNmbr: PropTypes.string,
  scheme: PropTypes.string,
  contributionType: PropTypes.string,
  isOrsoAccount: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    policyList: state.twoAccounts.allPolicyList,
    selectedPolicyIndex: state.twoAccounts.selectedPolicyIndex,
    webCycleDate: state.empLogin.webCycleDate,
    clientNmbr: state.empLogin.clientNmbr,
    clntRltnshpNmbr: state.empLogin.clntRltnshpNmbr,
    pisysToken: state.token.pisysToken,
    quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
    isMerger: state.mergerInfo.isMerger,
    mergerAttributes: state.mergerInfo.mergerAttributes,
    quickPolicyClientInfo: state.empLogin.quickPolicyClientInfo,
    contractNmbr: state.empLogin.contractNmbr,
    loginId: state.empLogin.loginId,
    clientInfo: state.landing.clientInfo,
    popUpAlertStatus: state.dashboard.popUpAlertStatus,
    productTypeNmbr: state.empLogin.productTypeNmbr,
    scheme: state.empLogin.scheme,
    contributionType: state.fundSwitch.contributionType,
    isOrsoAccount: state.twoAccounts.isOrsoAccount,
  };
};

const DashboardComponent = connect(mapStateToProps)(Dashboard);
export default DashboardComponent;