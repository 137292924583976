import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { saveRestrictAccessFlag } from '../../../../actions/LandingAction';
import { openVerifyPage } from "../../../../actions/VerifyAction";
import Icon_Address from "../../../../assets/Icon/icon_address.svg";
import Verification from "../../../../Components/Verification/Verification";
import "../../../../css/AddressInfo.css";
import { schemeAIIB, schemeS800, SMP } from '../../../../translations/common/commonConstants';
import { getValidJrsdNmbr, UiUtils } from '../../../../utils';
import { AddressUtil } from "../../../../utils/index";
import OtherCountryAddr from './OtherCountryAddr';


AddressInfoComponent.propTypes = {
  props: PropTypes.any,
  filteredEngCountryDropDown: PropTypes.arrayOf(PropTypes.object),
  filteredChineseCountryDropDown: PropTypes.arrayOf(PropTypes.object),
  clientInfo: PropTypes.object,
  address: PropTypes.any,
  verifyData: PropTypes.object,
  dispatch: PropTypes.func,
  onEdit: PropTypes.func,
  activeEdit: PropTypes.bool,
  scheme: PropTypes.string,
  productTypeNmbr: PropTypes.string,
}

function AddressInfoComponent(props) {
  const { t, i18n } = useTranslation("common");
  const history = useHistory();
  let [strtNoErrFlg] = useState(false);
  let [cityErrFlag, setCityErrFlag] = useState(false);
  const language = i18n.language;
  let countryDropDownEn = '';
  let countryDropDownZh = '';
  let address = '';
  let country = "";
  let defaultCountry = "";
  let jurisdictionNumber = "";
  let jurisdictionNmbr = "";
  address = AddressUtil.getAddressType(address, props);

  if (props.verifyData && props.verifyData.fromVerifyFlag) {
    jurisdictionNumber = getValidJrsdNmbr(props.verifyData.countryCode);
  } else {
    jurisdictionNumber = getValidJrsdNmbr(address.addressPltcJrsdNmbr);
  }
  jurisdictionNmbr = getValidJrsdNmbr(address.addressPltcJrsdNmbr);

  if (language === 'en') {
    countryDropDownEn = props.filteredEngCountryDropDown;
    (countryDropDownEn && countryDropDownEn !== undefined && countryDropDownEn.length) && countryDropDownEn.map((opt) => {
      if (opt.pltcJrsdNmbr === jurisdictionNumber) {
        country = opt.countryNm;
        defaultCountry = opt.pltcJrsdNmbr;
      }
    })
  } else if (language === 'zh') {
    countryDropDownZh = props.filteredChineseCountryDropDown;
    (countryDropDownZh && countryDropDownZh !== undefined && countryDropDownZh.length) && countryDropDownZh.map((opt) => {
      if (opt.pltcJrsdNmbr === jurisdictionNumber) {
        country = opt.countryNm;
        defaultCountry = opt.pltcJrsdNmbr;
      }
    })
  }
  if (props.scheme === schemeAIIB) {
    if (country != "" && country != undefined && (country === "Hong Kong SAR" || country === "HONG KONG" || country === "香港" || country === "香港特別行政區")) {
      country = countryDropDownEn[1].countryNm;
      defaultCountry = countryDropDownEn[1].pltcJrsdNmbr;
    }
  }

  if (props.verifyData.fromVerifyFlag) {
    var doc = document.getElementById("addressSection");
    if (doc !== undefined && doc !== null) {
      setTimeout(function () {
        document.getElementById("addressSection").click();
      }, 2);
    }
  }
  const [state, setState] = useState({
    displayAddressInfoFlag: true,
    cityEditFlag: false,
    streetNameEditFlag: false,
    isHKCountry: country != "" && country != undefined && (country === "Hong Kong SAR" || country === "HONG KONG" || country === "香港" || country === "香港特別行政區") ? true : false,
    editAddressInfoFlag: false,
    updateAddressInfoFlag: false,
    disableUpdate: false,
    room: (props.verifyData && props.verifyData.room != undefined) ? props.verifyData.room : '',
    block: (props.verifyData && props.verifyData.block != undefined) ? props.verifyData.block : '',
    floor: (props.verifyData && props.verifyData.floor != undefined) ? props.verifyData.floor : '',
    nameOfBuilding: (props.verifyData && props.verifyData.nameOfBuilding != undefined) ? props.verifyData.nameOfBuilding : '',
    streetNumber: (props.verifyData && props.verifyData.streetNumber != undefined) ? props.verifyData.streetNumber : '',
    nameOfStreet: (props.verifyData && props.verifyData.nameOfStreet != undefined) ? props.verifyData.nameOfStreet : '',
    city: props.verifyData && props.verifyData.fromVerifyFlag ? props.verifyData.city : ((props.clientInfo && props.clientInfo != undefined && props.clientInfo.mainAddress) ? props.clientInfo.mainAddress.cityNm : ''),
    postOrZip: (props.verifyData && props.verifyData.postOrZip != undefined) ? props.verifyData.postOrZip : '',
    territory: (props.verifyData && props.verifyData.territoryDisplay != undefined && props.verifyData.territoryDisplay != "") ? props.verifyData.territoryDisplay : '',
    selectLangPref: (props.verifyData && props.verifyData.selectLangPref != undefined) ? props.verifyData.selectLangPref : "E",
    countryCode: (defaultCountry != "" && defaultCountry != undefined) ? defaultCountry : '',
    addressLine1: address && address.addressLineTxt[0] ? address.addressLineTxt[0].addrLineTxt : '',
    addressLine2: address && address.addressLineTxt[1] ? address.addressLineTxt[1].addrLineTxt : '',
    addressLine3: address && address.addressLineTxt[2] ? address.addressLineTxt[2].addrLineTxt : '',
    addressLine4: address && address.addressLineTxt[3] ? address.addressLineTxt[3].addrLineTxt : '',
    fromVerifyFlag: (props.verifyData && props.verifyData.fromVerifyFlag != undefined) ? props.verifyData.fromVerifyFlag : false,
    mailingAddressType: (props.scheme === schemeS800 || props.scheme === SMP) ? ((props.clientInfo && props.clientInfo != undefined && props.clientInfo.mainAddress) ? props.clientInfo.mainAddress.addressTypeNmbr : '') : (props.clientInfo && props.clientInfo.clientAddresses && address) ? address.addressTypeNmbr : '',
    addressTypeNmbr: address ? address.addressTypeNmbr : 1,
    address: address,
    showComponent: false,
    verifyData: {},
    pltclJurNumber: jurisdictionNumber,
    nonHKAddrLine1: (props.verifyData && props.verifyData.nonHKAddressLine1 != undefined) ? props.verifyData.nonHKAddressLine1 : address && address.addressLineTxt[0] ? address.addressLineTxt[0].addrLineTxt : '',
    nonHKAddrLine2: (props.verifyData && props.verifyData.nonHKAddressLine2 != undefined) ? props.verifyData.nonHKAddressLine2 : address && address.addressLineTxt[1] ? address.addressLineTxt[1].addrLineTxt : '',
    nonHKAddrLine3: (props.verifyData && props.verifyData.nonHKAddressLine3 != undefined) ? props.verifyData.nonHKAddressLine3 : address && address.addressLineTxt[2] ? address.addressLineTxt[2].addrLineTxt : ''
  });

  const revertCountryInfo = () => {
    if (language === 'en') {
      countryDropDownEn = props.filteredEngCountryDropDown;
      (countryDropDownEn && countryDropDownEn !== undefined && countryDropDownEn.length) && countryDropDownEn.map((opt) => {
        if (opt.pltcJrsdNmbr === jurisdictionNmbr) {
          country = opt.countryNm;
          defaultCountry = opt.pltcJrsdNmbr;
        }
      })
    } else if (language === 'zh') {
      countryDropDownZh = props.filteredChineseCountryDropDown;
      (countryDropDownZh && countryDropDownZh !== undefined && countryDropDownZh.length) && countryDropDownZh.map((opt) => {
        if (opt.pltcJrsdNmbr === jurisdictionNmbr) {
          country = opt.countryNm;
          defaultCountry = opt.pltcJrsdNmbr;
        }
      })
    }
    setState({
      pltclJurNumber: jurisdictionNmbr,
      countryCode: (defaultCountry != "" && defaultCountry != undefined) ? defaultCountry : '',
    })
  }
  const handleAddressInfoEdit = (e) => {
    e.preventDefault();
    if (UiUtils.determineMPFTermination("profileInfoCutOff", Number(props.productTypeNmbr))) {
      props.dispatch(saveRestrictAccessFlag(true));
      props.onEdit(false);
      setState({
        ...state,
        editAddressInfoFlag: false,
        fromVerifyFlag: false,
      });
      window.scrollTo(0, 0);
    } else {
      if (e.target.name === "editButton") {
        if (props.scheme?.includes(schemeAIIB) && [100, 101].includes(state.pltclJurNumber)) {
          jurisdictionNmbr = getValidJrsdNmbr(countryDropDownEn[1].pltcJrsdNmbr);
          state.pltclJurNumber = countryDropDownEn[1].pltcJrsdNmbr;
          state.countryCode = countryDropDownEn[1].pltcJrsdNmbr;
        }
        setState({
          ...state,
          editAddressInfoFlag: true,
          disableUpdate: true,
          isHKCountry: (props.scheme?.includes(schemeS800) || props.scheme?.includes(SMP)) ? true : false,
          "room": '',
          "block": '',
          "floor": '',
          "nameOfBuilding": '',
          "streetNumber": '',
          "nameOfStreet": '',
          "city": '',
          "postOrZip": '',
          "selectLangPref": 'E',
          "territory": "",
          "countryCode": (props.scheme?.includes(schemeS800) || props.scheme?.includes(SMP)) ? 101 : defaultCountry,
          pltclJurNumber: (props.scheme?.includes(schemeS800) || props.scheme?.includes(SMP)) ? 101 : state?.pltclJurNumber,
          "nonHKAddressLine1": "",
          "nonHKAddressLine2": "",
          "nonHKAddressLine3": "",
          nonHKAddrLine1: "",
          nonHKAddrLine2: "",
          nonHKAddrLine3: ""
        });
        props.onEdit(true);
      } else if (e.target.name === "cancel") {
        revertCountryInfo();
        setState({
          ...state,
          editAddressInfoFlag: false,
          isHKCountry: country != "" && country != undefined && (country === "Hong Kong SAR" || country === "HONG KONG" || country === "香港" || country === "香港特別行政區") ? true : false,
          disableUpdate: false,
          fromVerifyFlag: false,
          countryCode: (defaultCountry != "" && defaultCountry != undefined) ? defaultCountry : '',
          nonHKAddrLine1: "",
          nonHKAddrLine2: "",
          nonHKAddrLine3: "",
          pltclJurNumber: jurisdictionNmbr
        });

        if (props.verifyData != undefined) {
          const verifyData = {
            "updatedSection": "",
            "room": "",
            "block": "",
            "floor": "",
            "nameOfBuilding": "",
            "streetNumber": "",
            "nameOfStreet": "",
            "city": "",
            "postOrZip": "",
            "countryCode": "",
            "selectLangPref": "E",
            "territory": "",
            "verifyAddress": "",
            "fromVerifyFlag": false,
            "nonHKAddressLine1": "",
            "nonHKAddressLine2": "",
            "nonHKAddressLine3": "",
          };
          props.dispatch(
            openVerifyPage(verifyData),
          );
          state.fromVerifyFlag = false;
        } else {
          state.fromVerifyFlag = false;
        }
        props.onEdit(false);
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name === 'room') {
      if (e.target.value !== null) {
        state.room = e.target.value;
      }
    }

    if (e.target.name === 'block') {
      if (e.target.value !== null) {
        state.block = e.target.value;
      }
    }

    if (e.target.name === 'floor') {
      if (e.target.value !== null) {
        state.floor = e.target.value;
      }
    }

    if (e.target.name === 'selectLangPref') {
      if (e.target.value !== null && e.target.value !== "") {
        state.selectLangPref = e.target.value;
      }
    }

    if (e.target.name === 'territory') {
      if (e.target.value !== null) {
        setState({
          ...state,
          territory: e.target.value
        })
        state.territory = e.target.value;
      }
    }

    if (e.target.name === 'nameOfBuilding') {
      if (e.target.value !== null) {
        state.nameOfBuilding = e.target.value;
      }
    }

    if (e.target.name === 'streetNumber') {
      if (e.target.value !== null) {
        state.streetNumber = e.target.value;
      }
    }

    if (e.target.name === 'nameOfStreet') {
      if (e.target.value) {
        state.nameOfStreet = e.target.value;
        state.streetNameEditFlag = true;
      }
    }

    if (e.target.name === 'city') {
      if (e.target.value) {
        state.city = e.target.value;
        setCityErrFlag(false);
        state.cityEditFlag = true;
      } else {
        setCityErrFlag(true);
      }
    }

    if (e.target.name === 'postOrZip') {
      if (e.target.value !== null) {
        state.postOrZip = e.target.value;
      }
    }

    if (e.target.name === 'countryCode') {
      if (e.target.value !== null && e.target.value !== "") {
        state.countryCode = e.target.value;
        if (language === 'en') {
          (props.filteredEngCountryDropDown && props.filteredEngCountryDropDown !== undefined && props.filteredEngCountryDropDown.length) && props.filteredEngCountryDropDown.map((opt) => {
            if (opt?.pltcJrsdNmbr?.toString() === state.countryCode) {
              state.pltclJurNumber = opt.pltcJrsdNmbr;
            }
          })
        } else if (language === 'zh') {
          (props.filteredChineseCountryDropDown && props.filteredChineseCountryDropDown !== undefined && props.filteredChineseCountryDropDown.length) && props.filteredChineseCountryDropDown.map((opt) => {
            if (opt?.pltcJrsdNmbr?.toString() === state.countryCode) {
              state.pltclJurNumber = opt.pltcJrsdNmbr;
            }
          })
        }

        if (state.pltclJurNumber === 100 || state.pltclJurNumber === 101) {
          setState(
            {
              ...state,
              isHKCountry: true
            }
          );
          state.isHKCountry = true;
        } else {
          setState({
            ...state,
            territory: '',
            isHKCountry: false
          });
          state.territory = '';
          state.isHKCountry = false;
        }
      }
    }
    disableUpdateBtn();
  };

  const disableUpdateBtn = () => {
    if (state.cityEditFlag && cityErrFlag === false) {
      setState(
        {
          ...state,
          disableUpdate: false
        }
      );
    } else if (state.cityEditFlag && (cityErrFlag === true)) {

      setState(
        {
          ...state,
          disableUpdate: true
        }
      );
    }
  }

  useEffect(() => {
    disableUpdateBtn();
  }, [cityErrFlag])

  useEffect(() => {
    if (props.verifyData.fromVerifyFlag) {
      props.onEdit(true)
    }
  })

  const isNumeric = (event) => {
    event = event ? event : window.event;
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };

  const onlyAlphabets = (event) => {
    event = event ? event : window.event;
    var charCode = event.which ? event.which : event.keyCode;
    if (!((charCode > 31 && (charCode < 48 || charCode > 57)))) {
      event.preventDefault();
    }
  }

  function updateBtnState(flag) {
    if (flag)
      setState({
        ...state,
        disableUpdate: false
      });
    else
      setState({
        ...state,
        disableUpdate: true
      });
  }

  function handleCallback(addrLine1, addrLine2, addrLine3) {
    setState({
      ...state,
      nonHKAddrLine1: addrLine1,
      nonHKAddrLine2: addrLine2,
      nonHKAddrLine3: addrLine3
    });
    state.nonHKAddrLine1 = addrLine1;
    state.nonHKAddrLine2 = addrLine2;
    state.nonHKAddrLine3 = addrLine3;
  }

  function onUpdate(e) {
    e.preventDefault();
    if (language === 'en') {
      (props.filteredEngCountryDropDown && props.filteredEngCountryDropDown !== undefined && props.filteredEngCountryDropDown.length) && props.filteredEngCountryDropDown.map((opt) => {
        if (opt.pltcJrsdNmbr === state.pltclJurNumber) {
          state.countryCode = opt.countryNm
        }
      })
    } else if (language === 'zh') {
      countryDropDownZh = props.filteredChineseCountryDropDown;
      (countryDropDownZh && countryDropDownZh !== undefined && countryDropDownZh.length) && countryDropDownZh.map((opt) => {
        if (opt.pltcJrsdNmbr === state.pltclJurNumber) {
          state.countryCode = opt.countryNm
        }
      })
    }

    const verifyData = {
      "updatedSection": "correspondenceAddr",
      "room": state.room,
      "block": state.block,
      "floor": state.floor,
      "nameOfBuilding": state.nameOfBuilding,
      "streetNumber": state.streetNumber,
      "nameOfStreet": state.nameOfStreet,
      "city": state.city,
      "postOrZip": state.postOrZip,
      "countryCode": state.countryCode,
      "selectLangPref": state.selectLangPref,
      "territory": t(state.territory),
      "territoryDisplay": state.territory,
      "verifyAddress": state.address,
      "fromVerifyFlag": false,
      "isHKCountry": state.isHKCountry,
      "nonHKAddressLine1": !state.isHKCountry ? state.nonHKAddrLine1 : "",
      "nonHKAddressLine2": !state.isHKCountry ? state.nonHKAddrLine2 : "",
      "nonHKAddressLine3": !state.isHKCountry ? state.nonHKAddrLine3 : "",
      "addressTypeNmbr": state.addressTypeNmbr,
      "scheme": props.scheme
    }
    props.dispatch(
      openVerifyPage(verifyData)
    );
    history.push('/verify');
  }

  let englishCountryList = [];
  let chineseCountryList = [];

  if (state.countryCode === "" || state.countryCode === null) {
    englishCountryList = props.filteredEngCountryDropDown.concat("");
    chineseCountryList = props.filteredChineseCountryDropDown.concat("");
  } else {
    englishCountryList = props.filteredEngCountryDropDown;
    englishCountryList = englishCountryList.filter((item) => {
      if (item.pltcJrsdNmbr === 167 || item.pltcJrsdNmbr === 169 || item.pltcJrsdNmbr === 175)
        return false;
      return true;
    });
    englishCountryList.sort((item, item1) => {
      if (item1.countryNm > item.countryNm)
        return -1;
      return 1;
    });
    chineseCountryList = props.filteredChineseCountryDropDown;
    chineseCountryList = chineseCountryList.filter((item) => {
      if (item.pltcJrsdNmbr === 167 || item.pltcJrsdNmbr === 169 || item.pltcJrsdNmbr === 175)
        return false;
      return true;
    });
  }


  return (
    <React.Fragment>
      <div className="row">
        {state.showComponent ? (
          <Verification />
        ) : (
            <div className="pds-card info-body address-section rsc-card" id="address-section">
              {state.displayAddressInfoFlag === true ? (
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h3 className="info-title">{t('addressInfo.addressTitle')}</h3>
                    {state.editAddressInfoFlag === true || props.verifyData.fromVerifyFlag === true ? (
                      <a
                        href="#"
                        className="edit-link blue-cancel"
                        name="cancel"
                        onClick={handleAddressInfoEdit}
                      >
                        {t("common.cancel")}
                      </a>
                    ) : (
                        <a
                          href="#"
                          className={props.activeEdit ? "edit-link inactive" : "edit-link"}
                          name="editButton"
                          onClick={handleAddressInfoEdit}
                        >
                          {t("common.edit")}
                        </a>
                      )}
                  </div>
                  <form>
                    {state.editAddressInfoFlag === false && state.fromVerifyFlag === false ? (
                      (props.scheme === schemeS800 || props.scheme === SMP) ?
                        (!(props?.clientInfo?.clientAddresses) || (address &&
                          ['BAD ADDRESS,BAD ADDRESS'].includes(address.addressLocationNm)) || (props?.clientInfo?.clientAddresses.length <= 0)
                          || address === ""
                        ) ?
                          <div className="row">
                            <label className="invalid-Email_lbl col-xl-8 col-sm-12 col-md-8 col-lg-12 col-12">
                              <img className="email-icon" src={Icon_Address}></img>{t("addressInfo.invalidAddressMessage")}
                            </label>
                          </div>
                          : <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                              <div className="left-align">
                                <ul className="list-unstyled inner-label addr-wrd">
                                  <li>{state.addressLine1}</li>
                                  <li>{state.addressLine2}</li>
                                  <li>{state.addressLine3}</li>
                                  <li>{state.addressLine4}</li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                              <h4 className="left-align">{t('addressInfo.country/Region')}
                                {i18n.language === 'en' ?
                                  props.filteredEngCountryDropDown.map((opt, index) => {
                                    if (opt.pltcJrsdNmbr === jurisdictionNmbr) {
                                      return <label key={index} className="inner-label">{opt.countryNm}</label>
                                    }
                                  })
                                  : props.filteredChineseCountryDropDown.map((opt, index) => {
                                    if (opt.pltcJrsdNmbr === jurisdictionNmbr) {
                                      return <label key={index} className="inner-label">{opt.countryNm}</label>
                                    }
                                  })}
                              </h4>
                            </div>
                          </div>
                        :
                        (
                          (!(props?.clientInfo?.clientAddresses) || (address &&
                            ['BAD ADDRESS,BAD ADDRESS'].includes(address.addressLocationNm)) || (props?.clientInfo?.clientAddresses.length <= 0)
                            || address === ""
                          )
                            ?
                            <div className="row">
                              <label className="invalid-Email_lbl col-xl-8 col-sm-12 col-md-8 col-lg-12 col-12">
                                <img className="email-icon" src={Icon_Address}></img>{t("addressInfo.invalidAddressMessage")}
                              </label>
                            </div>
                            :
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <div className="left-align">
                                  <ul className="list-unstyled inner-label addr-wrd">
                                    <li>{state.addressLine1}</li>
                                    <li>{state.addressLine2}</li>
                                    <li>{state.addressLine3}</li>
                                    <li>{state.addressLine4}</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <h4 className="left-align">{t('addressInfo.country/Region')}
                                  {i18n.language === 'en' ?
                                    props.filteredEngCountryDropDown.map((opt, index) => {
                                      if (opt.pltcJrsdNmbr === jurisdictionNmbr) {
                                        return <label key={index} className="inner-label">{opt.countryNm}</label>
                                      }
                                    })
                                    : props.filteredChineseCountryDropDown.map((opt, index) => {
                                      if (opt.pltcJrsdNmbr === jurisdictionNmbr) {
                                        return <label key={index} className="inner-label">{opt.countryNm}</label>
                                      }
                                    })}
                                </h4>
                              </div>
                            </div>
                        )
                    ) : <React.Fragment>
                        {state.isHKCountry &&
                          <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                              <label className="left-align margin-select-top">
                                <font>{t("addressInfo.addressLanguage")}</font>
                              </label>
                              <select id="selectPlaceholder" aria-label="Select placeholder"
                                name="selectLangPref"
                                data-testid="langSelect"
                                className="select-arrow form-input form-text required form-control addr-formInput select-input-bold rsc-select"
                                onChange={(e) => handleChange(e)}
                                defaultValue={props.verifyData.fromVerifyFlag ? props.verifyData.selectLangPref : state.selectLangPref}
                              >
                                <option value='E' data-testid="select-option">{t("addressInfo.english")}</option>
                                <option value='C' data-testid="select-option">{t("addressInfo.chinese")}</option>
                              </select>
                            </div>
                            <div className="w-100"></div>
                          </div>
                        }

                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                            <label className="left-align">
                              <font>{t("addressInfo.countryCode")}</font>
                            </label>
                            <ul className="list-unstyled inner-label">
                              <li><select id="selectPlaceholder" aria-label="Select placeholder"
                                name="countryCode"
                                maxLength="26"
                                data-testid="countryCodeTest"
                                className="form-input form-text required form-control addr-formInput select-input-bold select-arrow rsc-select"
                                onChange={(e) => handleChange(e)}
                                value={state.countryCode}
                              >
                                {language === 'en' ? englishCountryList.map((opt, i) => <option key={i} value={opt.pltcJrsdNmbr}>{opt.countryNm}</option>) :
                                  chineseCountryList.map((opt, i) => <option key={i} value={opt.pltcJrsdNmbr}>{opt.countryNm}</option>)}
                              </select></li>
                            </ul>
                          </div>
                        </div>
                        {
                          (!state.isHKCountry) ?
                            <OtherCountryAddr updateBtnState={updateBtnState} parentCallback={handleCallback}
                              nonHKAddr1={state.nonHKAddrLine1}
                              nonHKAddr2={state.nonHKAddrLine2}
                              nonHKAddr3={state.nonHKAddrLine3}
                            />
                            :
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <label className="left-align">
                                  <font>{t("addressInfo.roomorflat")}</font>
                                </label>
                                <input type="text"
                                  name="room"
                                  maxLength="4"
                                  data-testid="roomTest"
                                  defaultValue={props.verifyData.fromVerifyFlag ? props.verifyData.room : ''}
                                  className="form-input form-text required form-control addr-formInput"
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                              <div className="col-xl-4 col-lg-4 col-md-4 col-6 col-xs-6">
                                <label className="left-align">
                                  <font>{t("addressInfo.floor")}</font>
                                </label>
                                <input type="text"
                                  name="floor"
                                  data-testid="floorTest"
                                  maxLength="4"
                                  defaultValue={props.verifyData.fromVerifyFlag ? props.verifyData.floor : ''}
                                  className="form-input form-text required form-control addr-formInput"
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                              <div className="col-xl-4 col-lg-4 col-md-4 col-6 col-xs-6">
                                <label className="left-align">
                                  <font>{t("addressInfo.block")}</font>
                                </label>
                                <input type="text"
                                  name="block"
                                  maxLength="4"
                                  data-testid="blocktest"
                                  className="form-input form-text required form-control addr-formInput"
                                  onChange={(e) => handleChange(e)}
                                  defaultValue={props.verifyData.fromVerifyFlag ? props.verifyData.block : ''}
                                />
                              </div>


                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <label className="left-align">
                                  <font>{t("addressInfo.nameOfBuilding")}</font>
                                </label>
                                <input type="text"
                                  name="nameOfBuilding"
                                  maxLength="45"
                                  data-testid="nameOfBuildingTest"
                                  className="form-input form-text required form-control addr-formInput"
                                  onChange={(e) => handleChange(e)}
                                  defaultValue={props.verifyData.fromVerifyFlag ? props.verifyData.nameOfBuilding : ''}
                                />
                              </div>


                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <label className="left-align">
                                  <font>{t("addressInfo.streetNumber")}</font>
                                </label>
                                <input type="text"
                                  name="streetNumber"
                                  maxLength="4"
                                  onKeyPress={isNumeric}
                                  onPaste={isNumeric}
                                  data-testid="streetNumberTest"
                                  className={
                                    strtNoErrFlg
                                      ? "form-input form-text required form-control addr-formInput error-msg-box verify-btn-input"
                                      : "form-input form-text required form-control addr-formInput"
                                  }
                                  onChange={(e) => handleChange(e)}
                                  defaultValue={props.verifyData.fromVerifyFlag ? props.verifyData.streetNumber : ''}
                                />
                                {strtNoErrFlg && (
                                  <div className="pds-error common-pds-error pinErrorAddress" data-testid="streetNumErr">
                                    {t("addressInfo.streetNoErrMessage")}
                                  </div>
                                )}
                              </div>
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <label className="left-align">
                                  <font>{t("addressInfo.nameOfStreet")}</font>
                                </label>
                                <input type="text"
                                  name="nameOfStreet"
                                  maxLength="40"
                                  onKeyPress={onlyAlphabets}
                                  data-testid="nameOfStreetTest"
                                  className="form-input form-text required form-control addr-formInput"
                                  onChange={(e) => handleChange(e)}
                                  defaultValue={props.verifyData.fromVerifyFlag ? props.verifyData.nameOfStreet : ''}
                                />
                              </div>

                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <label className="left-align">
                                  <font>{t("addressInfo.territory")}</font>
                                </label>
                                <select id="selectPlaceholder" aria-label="Select placeholder" name="territory" data-testid="territoryTest"
                                  className="select-arrow form-input form-text required form-control addr-formInput select-input-bold rsc-select"
                                  onChange={handleChange}
                                  value={state.territory}
                                  defaultValue={state.territory}
                                  disabled={!(state.isHKCountry)}
                                >
                                  <option value="" hidden selected></option>
                                  <option value="addressInfo.territoryHongKong">{t("addressInfo.territoryHongKong")}</option>
                                  <option value="addressInfo.territoryKowloon">{t("addressInfo.territoryKowloon")}</option>
                                  <option value="addressInfo.newTerritory">{t("addressInfo.newTerritory")}</option>
                                </select>
                              </div>


                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <label className="left-align">
                                  <font>{t("addressInfo.city")}</font>
                                </label>
                                <input type="text"
                                  name="city"
                                  maxLength="22"
                                  onKeyPress={onlyAlphabets}
                                  data-testid="cityTest"
                                  className={
                                    cityErrFlag
                                      ? "form-input form-text required form-control addr-formInput error-msg-box verify-btn-input"
                                      : "form-input form-text required form-control addr-formInput"
                                  }
                                  onChange={(e) => handleChange(e)}
                                  defaultValue={props.verifyData.fromVerifyFlag ? props.verifyData.city : ''}
                                />
                                {cityErrFlag && (
                                  <div className="pds-error common-pds-error pinErrorAddress" data-testid="cityErr">
                                    {t("addressInfo.cityErrMsg")}
                                  </div>
                                )}
                              </div>

                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                <label className="left-align">
                                  <font>{t("addressInfo.postOrZip")}</font>
                                </label>
                                <input type="text"
                                  name="postOrZip"
                                  maxLength="8"
                                  onKeyPress={isNumeric}
                                  onPaste={isNumeric}
                                  data-testid="postOrZipTest"
                                  className="form-input form-text required form-control addr-formInput"
                                  onChange={(e) => handleChange(e)}
                                  defaultValue={props.verifyData.fromVerifyFlag ? props.verifyData.postOrZip : ''}
                                />
                              </div>
                            </div>
                        }

                        <div className="row">
                          <div className="col-md-4 float-left">
                            <button
                              className="pds-button pds-button-tertiary btn-blue"
                              name="update"
                              data-testid="updateBtn"
                              disabled={state.disableUpdate}
                              onClick={(e) => onUpdate(e)}
                            >
                              {t("common.update")}
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    }
                  </form>
                </div>
              ) : null}
            </div>
          )}
      </div>
    </React.Fragment >
  );
}

const mapStateToProps = (state) => {
  return {
    clientInfo: state.landing.clientInfo,
    countryDropDown: state.commonDropDown.countryDropDown,
    verifyData: state.verify.verifyData,
    filteredEngCountryDropDown: state.commonDropDown.filteredEngCountryDropDown,
    filteredChineseCountryDropDown: state.commonDropDown.filteredChineseCountryDropDown,
    scheme: state.empLogin.scheme,
    selectedQuickPolicyInfo: state.twoAccounts.selectedQuickPolicyInfo,
    isOrsoAccount: state.twoAccounts.isOrsoAccount,
    productTypeNmbr: state.empLogin.productTypeNmbr,
  };
}
const AddressInfoComp = connect(mapStateToProps)(AddressInfoComponent)

export default class AddressInfo extends React.Component {
  constructor() {
    super();
  }
  render() {
    return <AddressInfoComp onEdit={this.props.onEdit} activeEdit={this.props.activeEdit}></AddressInfoComp>
  }
}

AddressInfo.propTypes = {
  onEdit: PropTypes.func,
  activeEdit: PropTypes.bool,
  selectedQuickPolicyInfo: PropTypes.object,
  isOrsoAccount: PropTypes.bool
}