import moment from "moment";
import React from "react";
import { DD_MM_YYYY_HH_mm_ss, PRODUCT_TYPE_NMBR_ORSO_CHUBB, PRODUCT_TYPE_NMBR_ORSO_CRC, PRODUCT_TYPE_NMBR_ORSO_FTLIFE, PRODUCT_TYPE_NMBR_ORSO_HKSI, PRODUCT_TYPE_NMBR_ORSO_MAERSK, PRODUCT_TYPE_NMBR_ORSO_PD, PRODUCT_TYPE_NMBR_ORSO_PTC, asiaHongKong, enGB, productTypeNumbersMPF } from "../translations/common/commonConstants";

const callMedalliaUpdate = () => {
    if (window.KAMPYLE_ONSITE_SDK && typeof window.KAMPYLE_ONSITE_SDK.updatePageView === 'function') {
        window.KAMPYLE_ONSITE_SDK.updatePageView();
    }
};

const determineMPFTermination = (phase, productTypeNmbr = 0) => {
    let isMPFTerminated = false;
    const currentDateHk = new Date();
    const hkTimeString = currentDateHk.toLocaleString(enGB, { timeZone: asiaHongKong });
    const hkTime = moment(hkTimeString, DD_MM_YYYY_HH_mm_ss, true);
    //   eslint-disable-next-line no-undef
    const mpfTerminationDatePhase0 = moment(process.env.REACT_APP_MPF_TERMINATION_DATE_PHASE_0, DD_MM_YYYY_HH_mm_ss, true);
    //   eslint-disable-next-line no-undef
    const mpfTerminationDatePhase2 = moment(process.env.REACT_APP_MPF_TERMINATION_DATE_PHASE_2, DD_MM_YYYY_HH_mm_ss, true);
    //   eslint-disable-next-line no-undef
    const fundSwitchCutOffDate = moment(process.env.REACT_APP_MPF_TERMINATION_FUND_SWITCHING, DD_MM_YYYY_HH_mm_ss, true);
    //   eslint-disable-next-line no-undef
    const profileInfoCutOffDate = moment(process.env.REACT_APP_MPF_TERMINATION_PROFILE_UPDATE, DD_MM_YYYY_HH_mm_ss, true);
    //   eslint-disable-next-line no-undef
    const initialTerminationNote = moment(process.env.REACT_APP_MPF_TERMINATION_INITIAL_NOTE, DD_MM_YYYY_HH_mm_ss, true);
    
    if (phase == 0) {
        isMPFTerminated = hkTime.toDate() > mpfTerminationDatePhase0?.toDate();
    } else if (phase == 2) {
        isMPFTerminated = hkTime.toDate() > mpfTerminationDatePhase2?.toDate();
    } else if (phase == "fundSwitchCutOff") {
        isMPFTerminated = (hkTime.toDate() > fundSwitchCutOffDate?.toDate());
    } else if (phase == "profileInfoCutOff") {
        isMPFTerminated = (hkTime.toDate() > profileInfoCutOffDate?.toDate());
    } else if (phase == "initialTerminationNote") {
        isMPFTerminated = (hkTime.toDate() > initialTerminationNote?.toDate());
    }
    return productTypeNmbr == 0 ? isMPFTerminated : (isMPFTerminated && productTypeNumbersMPF.includes(productTypeNmbr));
};

const displayNote = (text) => {
    return (
        <React.Fragment>
            <p className="text-red m-0">{text}</p>
        </React.Fragment>
    );
};

const checkIsEffectiveforGFCOrso = () => {
    let isEffectiveTimeForOrso = false;
    // eslint-disable-next-line no-undef
    let startDate = moment(process.env.REACT_APP_GFC_EFFECTIVE_DATE_FOR_ORSO, DD_MM_YYYY_HH_mm_ss, true);
    // eslint-disable-next-line no-undef
    let endDate = moment(process.env.REACT_APP_GFC_EFFECTIVE_END_DATE_FOR_ORSO, DD_MM_YYYY_HH_mm_ss, true);

    const date = new Date();
    const currentDateString = date.toLocaleString(enGB, { timeZone: asiaHongKong });
    const currentDate = moment(currentDateString, DD_MM_YYYY_HH_mm_ss, true);
    if (currentDate.toDate() > startDate.toDate() && currentDate.toDate() < endDate.toDate()) {
        isEffectiveTimeForOrso = true;
    }
    return isEffectiveTimeForOrso;
};

const disableCurrentInvButton = (props) => {
    const productTypeNum = props.quickPolicyInfo[props.selectedPolicyIndex]?.product?.productTypeNmbr;
    const productTypeNumberOrsoArr = [PRODUCT_TYPE_NMBR_ORSO_CRC, PRODUCT_TYPE_NMBR_ORSO_MAERSK, PRODUCT_TYPE_NMBR_ORSO_CHUBB, PRODUCT_TYPE_NMBR_ORSO_PTC, PRODUCT_TYPE_NMBR_ORSO_FTLIFE, PRODUCT_TYPE_NMBR_ORSO_HKSI, PRODUCT_TYPE_NMBR_ORSO_PD];
    const isOrsoEffectiveGFCUser = productTypeNumberOrsoArr.includes(productTypeNum);

    let enableButton = true;
    let isEffectiveTime = checkIsEffectiveforGFCOrso();
    if (isEffectiveTime && isOrsoEffectiveGFCUser) {
        enableButton = false;
    }
    return enableButton;
};

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export default { callMedalliaUpdate, checkIsEffectiveforGFCOrso, determineMPFTermination, displayNote, scrollToTop, disableCurrentInvButton };