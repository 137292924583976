import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import customDataUpdateAPI from "../../API/PersonalInfo/api";
import Header from "../../SharedComponents/Header";
import Logger from "../../SharedComponents/Logger.js";
import {
  saveContactInfoDetails,
  saveContactInfoUpdateFlag,
  saveContactUpdateJson,
  saveContactVerifyEditFlag,
  saveEmailInfoDetails,
  saveEmailInfoUpdateFlag,
  saveEmailUpdateJson,
  saveEmailUpdateOktaJson, saveEmailVerifyEditFlag,
  saveMobileInfoDetails,
  saveMobileInfoUpdateFlag,
  saveMobileUpdateJson,
  saveMobileUpdateOktaJson, saveMobileVerifyEditFlag
} from "../../actions/LandingAction";
import { openVerifyPage } from "../../actions/VerifyAction";
import pwdShow from "../../assets/Icon_Password_Hide.svg";
import pwdHide from "../../assets/Icon_Password_Show.svg";
import arrow from "../../assets/breadcrumb-icon.png";
import "../../css/AddressInfo.css";
import "../../css/Verification.css";
import { COBRAND, S800, SIMP, SMAP, SMP, VERIFICATION, mainAddressTypeNo, schemeS800 } from "../../translations/common/commonConstants";
import { AddressUtil, MFAUtils } from "../../utils/index.js";
import Toast from "../Toast";
import api from "./../../API/PersonalInfo/api";

VerificationComponent.propTypes = {
  props: PropTypes.any,
  filteredEngCountryDropDown: PropTypes.arrayOf(PropTypes.object),
  filteredChineseCountryDropDown: PropTypes.arrayOf(PropTypes.object),
  verifyData: PropTypes.object,
  verifyAddress: PropTypes.object,
  contactInfoDetails: PropTypes.object,
  contactInfoUpdateFlag: PropTypes.bool,
  contactInfoUpdateJson: PropTypes.object,
  address: PropTypes.any,
  dispatch: PropTypes.func,
  emailInfoDetails: PropTypes.object,
  emailInfoUpdateFlag: PropTypes.bool,
  emailInfoUpdateJson: PropTypes.object,
  mobileInfoDetails: PropTypes.object,
  mobileInfoUpdateFlag: PropTypes.bool,
  mobileInfoUpdateJson: PropTypes.object,
  clientNmbr: PropTypes.string,
  loginId: PropTypes.string,
  authToken: PropTypes.string,
  pisysToken: PropTypes.string,
  scheme: PropTypes.string,
  cycleDate: PropTypes.string,
  quickPolicyInfo: PropTypes.object,
  allPolicyList: PropTypes.object,
  mobileInfoUpdateOktaJson: PropTypes.object,
  emailInfoUpdateOktaJson: PropTypes.object,
  authMFAData: PropTypes.object,
  clientNmbrList: PropTypes.array,
  loginSchemeProduct: PropTypes.string,
  productTypeNmbr: PropTypes.string,
  clntRltnshpNmbr: PropTypes.string,
};

function VerificationComponent(props) {

  const schemeDetailsMap = {
    "S800": {
      schemecode: 255,
      schemestatement: S800
    },
    "SIMP": {
      schemecode: 256,
      schemestatement: SIMP
    },
    "SMAP": {
      schemecode: 257,
      schemestatement: SMAP
    }
  };

  const filteredClientNmbrList = [];

  props?.quickPolicyInfo?.forEach(policy => {
    props?.clientNmbrList?.forEach(clientNmbr => {
      if (policy.client.clientId == clientNmbr) {
        filteredClientNmbrList.push(clientNmbr);
      }
    });
  });

  const { t, i18n } = useTranslation("common");
  const [passwordShown, setPasswordShown] = useState(false);
  let [serviceCallFlag, setServiceCallFlag] = useState(false);
  const [checkSaveAllClientsData, setCheckSaveAllClientsData] = useState(false);

  const handleChangeCheckSaveAllClientsData = () => {
    setCheckSaveAllClientsData(!checkSaveAllClientsData);
  };

  // for multi client handling
  let multiClientDataUpdateArray = [];

  const toastOptions = {
    position: 'top-center',
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    rtl: false,
    closeButton: false,
  };

  const sendNotification = (type) => {
    toast(<Toast type={type} />, toastOptions);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const history = useHistory();
  useEffect(() => {
    if (state.verifyUpdateSuccess === true && props.contactInfoUpdateFlag) {
      resetContactPropsValue();
    } else if (
      state.verifyUpdateSuccess === true &&
      props.emailInfoUpdateFlag
    ) {
      resetEmailPropsValue();
    } else if (
      state.verifyUpdateSuccess === true &&
      props.mobileInfoUpdateFlag
    ) {
      resetMobilePropsValue();
    } else if (state.verifyUpdateSuccess === true && props.verifyData) {
      props.verifyData.fromVerifyFlag = false;
      resetAddressInfo();
    }
  });

  let jurNum = "";
  let countryName = "";
  const language = i18n.language;
  let countryDropDownEn = "";
  let countryDropDownZh = "";
  let [defaultValues, setDefaultValues] = useState(0);

  if (props.verifyData.updatedSection) {
    if (language === "en") {
      countryDropDownEn = props.filteredEngCountryDropDown;
      countryDropDownEn &&
        countryDropDownEn !== undefined &&
        countryDropDownEn.length &&
        countryDropDownEn.map((opt) => {
          if (opt.countryNm === props.verifyData.countryCode) {
            jurNum = opt.pltcJrsdNmbr;
            countryName = opt.countryNm;
          }
        });
    } else if (language === "zh") {
      countryDropDownZh = props.filteredChineseCountryDropDown;
      countryDropDownZh &&
        countryDropDownZh !== undefined &&
        countryDropDownZh.length &&
        countryDropDownZh.map((opt) => {
          if (opt.countryNm === props.verifyData.countryCode) {
            jurNum = opt.pltcJrsdNmbr;
            countryName = opt.countryNm;
          }
        });
    }
  }

  var updatedSection = props.verifyData.updatedSection;
  if (props.contactInfoUpdateFlag === true) {
    updatedSection = "contactInfo";
  } else if (props.emailInfoUpdateFlag === true) {
    updatedSection = "emailInfo";
  } else if (props.mobileInfoUpdateFlag === true) {
    updatedSection = "mobileInfo";
  }

  const [state, setState] = useState({
    room: updatedSection === "correspondenceAddr" ? props.verifyData.room : "",
    floor:
      updatedSection === "correspondenceAddr" ? props.verifyData.floor : "",
    block:
      updatedSection === "correspondenceAddr" ? props.verifyData.block : "",
    city: updatedSection === "correspondenceAddr" ? props.verifyData.city : "",
    streetNumber:
      updatedSection === "correspondenceAddr"
        ? props.verifyData.streetNumber
        : "",
    nameOfStreet:
      updatedSection === "correspondenceAddr"
        ? props.verifyData.nameOfStreet
        : "",
    nameOfBuilding:
      updatedSection === "correspondenceAddr"
        ? props.verifyData.nameOfBuilding
        : "",
    postOrZip:
      updatedSection === "correspondenceAddr" ? props.verifyData.postOrZip : "",
    countryCode: updatedSection === "correspondenceAddr" ? countryName : "",
    languagePreference:
      updatedSection === "correspondenceAddr"
        ? props.verifyData.selectLangPref
        : "E",
    territory:
      updatedSection === "correspondenceAddr"
        ? props.verifyData.territory
        : "Hong Kong",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    addressLine4: "",
    addressLine5: "",
    editAddressInfoFlag: false,
    pin: "",
    pinErrorFlag: false,
    verifyPinFlag: false,
    disableUpdate: true,
    addressTypeNumber:
      updatedSection === "correspondenceAddr" ? (props.verifyData.scheme === schemeS800 ?
        ((props.verifyData.verifyAddress ? props.verifyData.verifyAddress.addressTypeNmbr : mainAddressTypeNo)) :
        (props.verifyData && props.verifyData.addressTypeNmbr ? props.verifyData.addressTypeNmbr : mainAddressTypeNo)) : "",
    addressTypeName:
      updatedSection === "correspondenceAddr"
        ? props.verifyData.verifyAddress.addressTypeNm
        : "",
    pltJurNum: updatedSection === "correspondenceAddr" ? jurNum : "",
    addressLocationNm:
      updatedSection === "correspondenceAddr"
        ? props.verifyData.verifyAddress.addressLocationNm
        : "",
    fromVerifyFlag: false,
    verifyUpdateSuccess: false,
    isHKCountry: updatedSection === "correspondenceAddr"
      ? props.verifyData.isHKCountry
      : "",
  });

  const checkPin = (event) => {
    if (event.target.value !== null && event.target.value !== "") {
      setState({
        ...state,
        pin: event.target.value,
        disableVerify: false,
        disableUpdate: false,
        pinErrorFlag: false,
      });
    } else {
      setState({
        ...state,
        pin: event.target.value,
        disableVerify: true,
        disableUpdate: true,
        verifyPinFlag: false,
      });
    }
  };

  if (updatedSection === "correspondenceAddr") {
    useEffect(() => {
      setDefaultValues(2);
      defaultValues;
      AddressUtil.formatAddress(state, props);

    });
  }

  const resetContactPropsValue = () => {
    props.dispatch(saveContactVerifyEditFlag(false));
    props.dispatch(saveContactInfoUpdateFlag(false));
    props.dispatch(saveContactInfoDetails({}));
    props.dispatch(saveContactUpdateJson({}));
  };
  const resetEmailPropsValue = () => {
    props.dispatch(saveEmailVerifyEditFlag(false));
    props.dispatch(saveEmailInfoUpdateFlag(false));
    props.dispatch(saveEmailInfoDetails({}));
    props.dispatch(saveEmailUpdateJson({}));
    props.dispatch(saveEmailUpdateOktaJson({}));
  };
  const resetMobilePropsValue = () => {
    props.dispatch(saveMobileVerifyEditFlag(false));
    props.dispatch(saveMobileInfoUpdateFlag(false));
    props.dispatch(saveMobileInfoDetails({}));
    props.dispatch(saveMobileUpdateJson({}));
    props.dispatch(saveMobileUpdateOktaJson({}));
  };

  const setFlags = () => {
    setState({
      ...state,
      editAddressInfoFlag: true,
      displayAddressInfoFlag: false,
      verifyPinFlag: false,
      pinErrorFlag: true,
      verifyUpdateSuccess: true,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let checkPinJson = {
      password: state.pin,
      govtId: "",
      scheme: (props.scheme == SMP ? COBRAND : props.scheme),
      loginId: props.loginId
    };

    const headers = {
      'pisys_auth_token': props.pisysToken,
      'username': props.loginId,
      'Access-Control-Allow-Origin': '*'
    };

    const updateUserInfoHeaders = {
      'Access-Control-Allow-Origin': '*',
      'pisys_auth_token': props.pisysToken
    };

    var updateData = {};
    var updateOktaData = {};
    let mergedObj;
    let sepFlag = false;
    let rltClntNmbr = [];

    try {
      if (props.contactInfoUpdateFlag || props.mobileInfoUpdateFlag || (props.verifyData.updatedSection === "correspondenceAddr")) {
        props.allPolicyList.forEach(policyDetails => {
          props.quickPolicyInfo.forEach(quickPolicy => {
            if (policyDetails.policyVO.clntRltnshpNmbr === quickPolicy.clntRltnshpNmbr) {
              policyDetails.policyVO.policyHistoryList.forEach(policy => {
                if (policy.memberClassNmbr === 303 && quickPolicy.orgTypeNm === "SELF-EMPLOYED") {
                  rltClntNmbr.push(quickPolicy.client.rltClntNmbr);
                  if (!sepFlag) {
                    sepFlag = true;
                  }
                }
              });
            }
          });
        });
      }
    } catch {
      sepFlag = false;
      rltClntNmbr = [];
    }

    let mobileOtherDetailsUpdateFlag = false;
    let encodedOktaRequest = {};
    if (props.contactInfoUpdateFlag === true) {
      updateData = props.contactInfoUpdateJson;

      // multiclient handling for contact info
      if (checkSaveAllClientsData) {
        filteredClientNmbrList.forEach(clientNmbr =>
          multiClientDataUpdateArray.push(
            {
              "clientNmbr": clientNmbr,
              clntRltnShpNmbr: parseInt(props.clntRltnshpNmbr),
              "clntPhoneInfoList": updateData.clntPhoneInfoList
            }
          )
        );
      }

    } else if (props.emailInfoUpdateFlag === true) {
      updateData = props.emailInfoUpdateJson;
      updateOktaData = props.emailInfoUpdateOktaJson;
      encodedOktaRequest = JSON.stringify(updateOktaData);
      let multiClientData = [];

      // multiclient handling for email info
      if (checkSaveAllClientsData) {
        filteredClientNmbrList.forEach(clientNmbr => {
          props.emailInfoUpdateJson.forEach(emailInfoRecord => {
            let emailInfo = { ...emailInfoRecord };
            emailInfo.clientNmbr = clientNmbr;
            if (emailInfoRecord.cstmAttrCd === 256 || emailInfoRecord.cstmAttrCd === 257) {
              emailInfo.cstmAttrCd = schemeDetailsMap[props.quickPolicyInfo.find(quickPolicy => quickPolicy?.client?.clientId == clientNmbr).product?.productAcronym?.toUpperCase().trim()].schemecode;
              emailInfo.cstmAttrNm = schemeDetailsMap[props.quickPolicyInfo.find(quickPolicy => quickPolicy?.client?.clientId == clientNmbr).product?.productAcronym?.toUpperCase().trim()].schemestatement;
            }
            multiClientData.push(emailInfo);
          });
        });
      }
      multiClientDataUpdateArray.push(multiClientData);
    } else if (props.mobileInfoUpdateFlag === true) {
      updateData = props.mobileInfoUpdateJson;
      if (updateData.mobileOtherDetailsEditFlag) {
        mobileOtherDetailsUpdateFlag = true;
      }
      updateOktaData = props.mobileInfoUpdateOktaJson;
      encodedOktaRequest = JSON.stringify(updateOktaData);
    } else {
      let arr = [];
      arr = AddressUtil.getAddressLinesArray(state, props);
      updateData = {
        clientNmbr: props.clientNmbr,
        clntRltnShpNmbr: parseInt(props.clntRltnshpNmbr),
        addressDetailsList: [
          {
            addressTypeNmbr: state.addressTypeNumber,
            addressTypeNm: state.addressTypeName,
            addressLineTxt: arr,
            addressPltcJrsdNmbr: state.pltJurNum,
            addressLocationNm: state.countryCode,
            deleteRecord: false,
          },
        ],
      };

      // multiclient handling for address info
      if (checkSaveAllClientsData) {
        filteredClientNmbrList.forEach(clientNmbr =>
          multiClientDataUpdateArray.push(
            {
              clientNmbr: clientNmbr,
              clntRltnShpNmbr: parseInt(props.clntRltnshpNmbr),
              addressDetailsList: [
                {
                  addressTypeNmbr: state.addressTypeNumber,
                  addressTypeNm: state.addressTypeName,
                  addressLineTxt: arr,
                  addressPltcJrsdNmbr: state.pltJurNum,
                  addressLocationNm: state.countryCode,
                  deleteRecord: false,
                },
              ],
            }
          )
        );
      }
    }

    mergedObj = updateData;
    setServiceCallFlag(true);
    MFAUtils.authenticateMFA(checkPinJson).then((response) => {
      if (response.status === 200) {
        setState({
          ...state,
          displayAddressInfoFlag: true,
          disableUpdate: false,
          pinErrorFlag: false,
          verifyPinFlag: false,
        });
        state.verifyUpdateSuccess = true;
        if (props.contactInfoUpdateFlag === true) {
          let encodedRequest = mergedObj;
          let encodedRequestDataArray = multiClientDataUpdateArray;
          let encodedRequestArray = [];
          if (checkSaveAllClientsData) {
            encodedRequestDataArray.forEach(encodedRequest =>
              encodedRequestArray.push(api.updateClientPhoneDetails(encodedRequest, headers))
            );
          } else {
            encodedRequestArray.push(api.updateClientPhoneDetails(encodedRequest, headers));
          }

          Promise.all(encodedRequestArray).then(async () => {
            if (sepFlag) {
              await Promise.all(rltClntNmbr.map(async (rltClntNm) => {
                mergedObj.clientNmbr = rltClntNm;
                encodedRequest = mergedObj;
                await api.updateClientPhoneDetails(encodedRequest, headers).then(() => {
                  setState({
                    ...state,
                    verifyUpdateSuccess: true,
                  });
                  history.push("/profile");
                  sendNotification("success");
                }).catch(() => {
                  setFlags();
                  history.push("/profile");
                  sendNotification("error");
                });
              }));
            } else {
              setState({
                ...state,
                verifyUpdateSuccess: true,
              });
              history.push("/profile");
              sendNotification("success");
            }
          }).catch((error) => {
            setFlags();
            history.push("/profile");
            if (error.response.data.error_Message == "Operation Not Allowed.") {
              sendNotification('no-valid-account');
            } else {
              sendNotification("error");
            }
          });
        } else if (props.verifyData.updatedSection === "correspondenceAddr") {
          let encodedRequest = mergedObj;
          let encodedRequestDataArray = multiClientDataUpdateArray;
          let encodedRequestArray = [];
          if (checkSaveAllClientsData) {
            encodedRequestDataArray.forEach(encodedRequest =>
              encodedRequestArray.push(api.updateClientAddressDetails(encodedRequest, headers))
            );
          } else {
            encodedRequestArray.push(api.updateClientAddressDetails(encodedRequest, headers));
          }

          Promise.all(encodedRequestArray).then(async () => {
            if (sepFlag) {
              await Promise.all(rltClntNmbr.map(async (rltClntNm) => {
                mergedObj.clientNmbr = rltClntNm;
                encodedRequest = mergedObj;
                await api.updateClientAddressDetails(encodedRequest, headers)
                  .then(() => {
                    setState({
                      ...state,
                      verifyUpdateSuccess: true,
                    });
                    history.push("/profile");
                    sendNotification("success");
                  }).catch(() => {
                    setFlags();
                    history.push("/profile");
                    sendNotification("error");
                  });
              }));
            } else {
              setState({
                ...state,
                verifyUpdateSuccess: true,
              });
              history.push("/profile");
              sendNotification("success");
            }
          })
            .catch((error) => {
              setFlags();
              history.push("/profile");
              if (error.response.data.error_Message == "Operation Not Allowed.") {
                sendNotification('no-valid-account');
              } else {
                sendNotification("error");
              }
            });
        } else if (props.mobileInfoUpdateFlag === true) {
          let mobileNmEncodedRequest = {};
          let mobileOtherDetailsEncodedRequest = [];
          mobileNmEncodedRequest = { ...mergedObj.mobileDetails };
          if (!mobileOtherDetailsUpdateFlag) {
            mobileOtherDetailsEncodedRequest = mergedObj.mobileOtherDetails;
          }
          let pisysServicePromises = [];
          let servicePromises = [];
          let mobileNmEncodedRequestArray = [];
          let mobileOtherDetailsEncodedRequestArray = [];
          if (props?.scheme === SMP && checkSaveAllClientsData) {

            filteredClientNmbrList.forEach((clientNmbr) => {


              if (mobileNmEncodedRequest?.clientNmbr) {
                let mobileNmRequest = { ...mobileNmEncodedRequest };
                mobileNmRequest.clientNmbr = clientNmbr;
                mobileNmEncodedRequestArray.push(mobileNmRequest);
              }
              if (!mobileOtherDetailsUpdateFlag) {

                const mobileOtherDetailsArray = [];
                for (let index = 0; index < mobileOtherDetailsEncodedRequest.length; index++) {
                  const otherDetail = { ...mobileOtherDetailsEncodedRequest[index] };
                  otherDetail.clientNmbr = clientNmbr;
                  mobileOtherDetailsArray.push(otherDetail);
                }

                mobileOtherDetailsEncodedRequestArray.push(mobileOtherDetailsArray);
              }
            });
          } else {
            mobileNmEncodedRequestArray.push(mobileNmEncodedRequest);
            if (!mobileOtherDetailsUpdateFlag) {
              mobileOtherDetailsEncodedRequestArray.push(mobileOtherDetailsEncodedRequest);
            }
          }

          if (props.mobileInfoDetails.conNoDetailsEditFlag) {

            api.updateClientDetailsOKTA(encodedOktaRequest, updateUserInfoHeaders).then(async () => {
              mobileNmEncodedRequestArray.forEach(mobileNmRequest => {
                pisysServicePromises.push(api.updateClientPhoneDetails(mobileNmRequest, headers));
              });
              Promise.all(pisysServicePromises).then(() => {

                if (!mobileOtherDetailsUpdateFlag) {
                  if (props.mobileInfoDetails.checkEcomDetailsEditFlag || props.mobileInfoDetails.lanEditFlag) {
                    mobileOtherDetailsEncodedRequestArray.forEach(mobileOtherDetailRequest => {
                      servicePromises.push(customDataUpdateAPI.updateCustomData(mobileOtherDetailRequest, headers));
                    });
                  }
                }
                if (sepFlag) {
                  rltClntNmbr.forEach(rltClntNm => {
                    if (props.mobileInfoDetails.conNoDetailsEditFlag) {
                      mobileNmEncodedRequestArray.forEach(mobileNmRequest => {
                        mobileNmRequest.clientNmbr = rltClntNm;
                        servicePromises.push(api.updateClientPhoneDetails(mobileNmRequest, headers));
                      });
                    }
                    if (!mobileOtherDetailsUpdateFlag && (props.mobileInfoDetails.checkEcomDetailsEditFlag || props.mobileInfoDetails.lanEditFlag)) {
                      mobileOtherDetailsEncodedRequestArray.forEach(mobileOtherDetailRequest => {
                        mobileOtherDetailRequest.clientNmbr = rltClntNm;
                        servicePromises.push(customDataUpdateAPI.updateCustomData(mobileOtherDetailRequest, headers));
                      });

                    }
                  });
                }

                if (servicePromises?.length !== 0) {
                  Promise.all(servicePromises).then(() => {
                    setState({
                      ...state,
                      verifyUpdateSuccess: true,
                    });
                    history.push("/profile");
                    sendNotification("success");
                  }).catch(() => {
                    setFlags();
                    history.push("/profile");
                    sendNotification("error");
                  });
                } else {
                  setState({
                    ...state,
                    verifyUpdateSuccess: true,
                  });
                  history.push("/profile");
                  sendNotification("success");
                }

              }).catch((error) => {
                setFlags();
                history.push("/profile");
                if (error.response.data.error_Message == "Operation Not Allowed.") {
                  sendNotification('no-valid-account');
                } else {
                  sendNotification('error');
                }
              });

            }).catch((error) => {
              setFlags();
              history.push("/profile");
              let errorDetails = (error.response.data);
              if (errorDetails.statusCode == 400) {
                if (error.response.data.error_Message == "Operation Not Allowed.") {
                  sendNotification('no-valid-account');
                } else {
                  sendNotification('invalid-phone');
                }
              } else {
                sendNotification("error");
              }
            });
          } else {
            if (!mobileOtherDetailsUpdateFlag) {
              if (props.mobileInfoDetails.checkEcomDetailsEditFlag || props.mobileInfoDetails.lanEditFlag) {
                mobileOtherDetailsEncodedRequestArray.forEach(mobileOtherDetailRequest => {
                  servicePromises.push(customDataUpdateAPI.updateCustomData(mobileOtherDetailRequest, headers));
                });
              }

            }
            if (sepFlag) {
              rltClntNmbr.forEach(rltClntNm => {
                if (props.mobileInfoDetails.conNoDetailsEditFlag) {
                  mobileNmEncodedRequestArray.forEach(mobileNmRequest => {
                    mobileNmRequest.clientNmbr = rltClntNm;
                    servicePromises.push(api.updateClientPhoneDetails(mobileNmRequest, headers));
                  });
                }
                if (!mobileOtherDetailsUpdateFlag && (props.mobileInfoDetails.checkEcomDetailsEditFlag || props.mobileInfoDetails.lanEditFlag)) {
                  mobileOtherDetailsEncodedRequestArray.forEach(mobileOtherDetailRequest => {
                    mobileOtherDetailRequest.clientNmbr = rltClntNm;
                    servicePromises.push(customDataUpdateAPI.updateCustomData(mobileOtherDetailRequest, headers));
                  });
                }
              });
            }
            Promise.all(servicePromises).then(() => {
              setState({
                ...state,
                verifyUpdateSuccess: true,
              });
              history.push("/profile");
              sendNotification("success");
            }).catch((error) => {
              setFlags();
              history.push("/profile");
              if (error.response.data.error_Message == "Operation Not Allowed.") {
                sendNotification('no-valid-account');
              } else {
                sendNotification("error");
              }
            });
          }
        } else if (props.emailInfoUpdateFlag === true) {
          let encodedRequest = mergedObj;
          let encodedRequestDataArray = multiClientDataUpdateArray;
          let encodedRequestArray = [];
          if (checkSaveAllClientsData) {
            encodedRequestDataArray.forEach(encodedRequest =>
              encodedRequestArray.push(customDataUpdateAPI.updateCustomData(encodedRequest, headers))
            );
          } else {
            encodedRequestArray.push(customDataUpdateAPI.updateCustomData(encodedRequest, headers));
          }

          Promise.all(encodedRequestArray).then(() => {
            if ((props.emailInfoDetails.emailIdDetailsEditFlag === true && props.emailInfoDetails.checkDetailsEditFlag === false) ||
              (props.emailInfoDetails.emailIdDetailsEditFlag === true && props.emailInfoDetails.checkDetailsEditFlag === true)) {
              api.updateClientDetailsOKTA(encodedOktaRequest, updateUserInfoHeaders).then(() => {
                setState({
                  ...state,
                  verifyUpdateSuccess: true,
                });
                history.push("/profile");
                sendNotification("success");
              }).catch(() => {
                setFlags();
                history.push("/profile");
                sendNotification("error");
              });
            }
            else {
              setState({
                ...state,
                verifyUpdateSuccess: true,
              });
              history.push("/profile");
              state.verifyUpdateSuccess = true;
              sendNotification("success");
            }
          }).catch((error) => {
            setFlags();
            history.push("/profile");
            if (error.response.data.error_Message == "Operation Not Allowed.") {
              sendNotification('no-valid-account');
            } else {
              sendNotification("error");
            }
          });
        }
      } else {
        setState({
          ...state,
          editAddressInfoFlag: true,
          displayAddressInfoFlag: false,
          verifyPinFlag: false,
          pinErrorFlag: true,
        });
      }
    }).catch(() => {
      setState({
        ...state,
        editAddressInfoFlag: true,
        displayAddressInfoFlag: false,
        verifyPinFlag: false,
        pinErrorFlag: true,
      });
      setServiceCallFlag(false);
    });

    state.disableUpdate = false;
    props.verifyData.fromVerifyFlag = false;
  };

  const resetAddressInfo = () => {
    props.dispatch(openVerifyPage({}));
  };

  function handleEdit(e) {
    e.preventDefault();
    if (props.contactInfoUpdateFlag === true) {
      props.dispatch(saveContactInfoUpdateFlag(false));
      props.dispatch(saveContactVerifyEditFlag(true));
    } else if (props.emailInfoUpdateFlag === true) {
      props.dispatch(saveEmailInfoUpdateFlag(false));
      props.dispatch(saveEmailVerifyEditFlag(true));
    } else if (props.mobileInfoUpdateFlag === true) {
      props.dispatch(saveMobileInfoUpdateFlag(false));
      props.dispatch(saveMobileVerifyEditFlag(true));
    } else {
      props.verifyData.fromVerifyFlag = true;
      props.verifyData.countryCode = state.pltJurNum;
    }
    history.push("/profile");
  }

  return (
    <React.Fragment>
      <Logger logImpression data={{ page: VERIFICATION }}>
        <Header />
        <div className="container">
          <nav className="d-none d-md-block d-lg-block d-xl-block row" aria-label="breadcrumb">
            <div className="col-12">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item active">
                  <a href="#" className="breadcr">
                    {t("titles.dashboard")}
                  </a>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <img className="breadcr img-margin" src={arrow} />
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  <label className="breadcr">
                    {t("addressInfo.verifyUpdatedInformation")}
                  </label>
                </li>
              </ol>
            </div>
          </nav>
        </div>
        <div className="main-content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 data-testid="infoTitle" className="mb-5 title-verifyInfo">
                  {t("addressInfo.verifyUpdatedInformation")}
                </h1>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-lg-10 col-sm-12 mx-auto">
                <div className="pds-card info-body" id="address-sect634zegfyion">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 mx-auto">
                        {updatedSection === "correspondenceAddr" && (
                          <div className="d-flex justify-content-between mt-2">
                            {updatedSection === "correspondenceAddr" && (
                              <h3 className="info-title-verify">
                                {t("addressInfo.addressTitle")}
                              </h3>
                            )}
                            <a
                              href="#"
                              className="edit-link"
                              name="editButton"
                              onClick={(e) => handleEdit(e)}
                            >
                              {t("common.edit")}
                            </a>
                          </div>
                        )}
                        <form>
                          {updatedSection === "correspondenceAddr" && (
                            <div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="left-align ">
                                    <ul className="list-unstyled inner-label">
                                      <li>{state.addressLine1}</li>
                                      <li>{state.addressLine2}</li>
                                      <li>{state.addressLine3}</li>
                                      <li>{state.addressLine4}</li>
                                      <li>{state.addressLine5}</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {updatedSection === "contactInfo" && (
                            <div className="row col-md-12 col-sm-12">
                              {props.contactInfoUpdateFlag && (
                                <div className="row col-md-11 col-sm-11">
                                  {props.contactInfoDetails
                                    .homeDetailsEditFlag && (
                                      <div className="col-sm-12 col-md-4 contactVerify">
                                        <div className="row">
                                          <label className="lbl-verifyField">
                                            {t("otherContact.home")}
                                          </label>
                                        </div>
                                        <div className="row">
                                          <label className="val_verifyField">
                                            {(props.contactInfoDetails.homeCountryCode && props.contactInfoDetails.homeNm) && props.contactInfoDetails.homeCountryCode === "+852" ?
                                              props.contactInfoDetails.homeCountryCode !== undefined && props.contactInfoDetails.homeCountryCode + " " + props.contactInfoDetails.homeNm.match(/.{1,4}/g).join(' ')
                                              : props.contactInfoDetails.homeCountryCode !== undefined && props.contactInfoDetails.homeCountryCode.replace("+", "") + props.contactInfoDetails.homeNm}
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  {props.contactInfoDetails
                                    .officeDetailsEditFlag && (
                                      <div className="col-sm-12 col-md-4 contactVerify">
                                        <div className="row">
                                          <label className="lbl-verifyField">
                                            {t("otherContact.office")}
                                          </label>
                                        </div>
                                        <div className="row">
                                          <label className="val_verifyField">
                                            {(props.contactInfoDetails.officeCountryCode && props.contactInfoDetails.officeNm) && props.contactInfoDetails.officeCountryCode === "+852" ?
                                              props.contactInfoDetails.officeCountryCode !== undefined && props.contactInfoDetails.officeCountryCode + " " + props.contactInfoDetails.officeNm.match(/.{1,4}/g).join(' ')
                                              : props.contactInfoDetails.officeCountryCode !== undefined && props.contactInfoDetails.officeCountryCode.replace("+", "") + props.contactInfoDetails.officeNm}
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  {props.contactInfoDetails
                                    .faxDetailsEditFlag && (
                                      <div className="col-sm-12 col-md-4 contactVerify">
                                        <div className="row">
                                          <label className="lbl-verifyField">
                                            {t("otherContact.fax")}
                                          </label>
                                        </div>
                                        <div className="row">
                                          <label className="val_verifyField">
                                            {(props.contactInfoDetails.faxCountryCode && props.contactInfoDetails.faxNm) && props.contactInfoDetails.faxCountryCode === "+852" ?
                                              props.contactInfoDetails.faxCountryCode !== undefined && props.contactInfoDetails.faxCountryCode + " " + props.contactInfoDetails.faxNm.match(/.{1,4}/g).join(' ')
                                              : props.contactInfoDetails.faxCountryCode !== undefined && props.contactInfoDetails.faxCountryCode.replace("+", "") + props.contactInfoDetails.faxNm}
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                              <div className="row ml-auto">
                                <a
                                  href="#"
                                  className="edit-link"
                                  name="editButton"
                                  onClick={(e) => handleEdit(e)}
                                >
                                  {t("common.edit")}
                                </a>
                              </div>
                            </div>
                          )}
                          {updatedSection === "emailInfo" && (
                            <div className="row col-md-12 col-sm-12">
                              {props.emailInfoUpdateFlag && (
                                <div className="row col-md-11 col-sm-11">
                                  <label className="lbl-verifyField">
                                    {t("emailInfo.email")}
                                  </label>
                                  {props.emailInfoDetails
                                    .emailIdDetailsEditFlag && (
                                      <div className="col-12 contactVerify">
                                        <div className="row">
                                          <label className="val_verifyField">
                                            {props.emailInfoDetails.emailAddress}
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  {props.emailInfoDetails
                                    .checkDetailsEditFlag && (
                                      <div className="col-12 contactVerify">
                                        <div className="row">
                                          <div className="pds-checkbox mb-4 mb-sm-4 mb-md-0 mb-xl-0 mb-lg-0 verify-checkbox">
                                            <input
                                              type="checkbox"
                                              id="checkbox4"
                                              checked={
                                                props.emailInfoDetails
                                                  .estatementForSIMP
                                              }
                                              disabled
                                            />
                                            <label
                                              htmlFor="checkbox4"
                                              className="checkbox-label-verify"
                                            >
                                              {t("emailInfo.checkLabel")}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                              <div className="row ml-auto">
                                <a
                                  href="#"
                                  className="edit-link"
                                  name="editButton"
                                  onClick={(e) => handleEdit(e)}
                                >
                                  {t("common.edit")}
                                </a>
                              </div>
                            </div>
                          )}
                          {updatedSection === "mobileInfo" && (
                            <div className="row col-md-12 col-sm-12">
                              {props.mobileInfoUpdateFlag && (
                                <div className="row col-md-11 col-sm-11">
                                  <label className="lbl-verifyField">
                                    {t("mobile.title")}
                                  </label>
                                  {props.mobileInfoDetails
                                    .conNoDetailsEditFlag && (
                                      <div className="col-12 contactVerify">

                                        <div className="row">
                                          <label className="val_verifyField">
                                            {(props.mobileInfoDetails.phoneCd && props.mobileInfoDetails.mobileContactNumber) && props.mobileInfoDetails.phoneCd === "+852" ?
                                              props.mobileInfoDetails.phoneCd !== undefined && props.mobileInfoDetails.phoneCd + " " + props.mobileInfoDetails.mobileContactNumber.match(/.{1,4}/g).join(' ')
                                              : props.mobileInfoDetails.phoneCd !== undefined && props.mobileInfoDetails.phoneCd.replace("+", "") + props.mobileInfoDetails.mobileContactNumber}
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  {props.mobileInfoDetails
                                    .checkEcomDetailsEditFlag && (
                                      <div className="col-12 contactVerify">
                                        <div className="row">
                                          <div className="pds-checkbox">
                                            <input
                                              type="checkbox"
                                              id="checkbox5"
                                              checked={
                                                props.mobileInfoDetails
                                                  .smsSubscription
                                              }
                                              disabled
                                            />
                                            <label
                                              className="checkbox-label"
                                              htmlFor="checkbox5"
                                            >
                                              {t("mobile.registerechannelServices")}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  {props.mobileInfoDetails.lanEditFlag && (
                                    <div className="col-12 contactVerify">
                                      <div className="row mt-3">
                                        <label className="lbl-verifyField">
                                          {t("mobile.languagePreference")}
                                        </label>
                                      </div>
                                      <div className="row">
                                        <label>
                                          {props.mobileInfoDetails.selectLangPref === 'E' ? t("mobile.english") : t("mobile.chinese")}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className="row ml-auto">
                                <a
                                  href="#"
                                  className="edit-link"
                                  name="editButton"
                                  onClick={(e) => handleEdit(e)}
                                >
                                  {t("common.edit")}
                                </a>
                              </div>
                            </div>
                          )}
                          {(props?.scheme === SMP) && (filteredClientNmbrList.length > 1) && <div className="col-12 checkSaveAllClientsData">
                            <div className="row">
                              <div className="pds-checkbox mb-4 mb-sm-4 mb-md-0 mb-xl-0 mb-lg-0 verify-checkbox">
                                <input
                                  type="checkbox"
                                  id="checkboxSaveAllClientsData"
                                  checked={checkSaveAllClientsData}
                                  onChange={handleChangeCheckSaveAllClientsData}
                                />
                                <label
                                  htmlFor="checkboxSaveAllClientsData"
                                  className="checkbox-label-saveAllClientsData"
                                >
                                  {t("common.checkSaveAllClientsData")}
                                </label>
                              </div>
                            </div>
                          </div>}
                          <hr className="line-2"></hr>
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <br />
                              <label className="enter-pin-to-confirm">
                                {t("common.enterPin")}
                              </label>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                              <br />
                              <input
                                id="inputPassword"
                                type={passwordShown ? "text" : "password"}
                                name="pin"
                                value={state.pin}
                                placeholder={t("common.enterPinPlaceholder")}
                                onChange={(e) => checkPin(e)}
                                className={
                                  state.pinErrorFlag
                                    ? "form-text err-form-control required form-margin error-msg-box verify-btn-input verify-pswd-ip"
                                    : "form-text required form-control form-margin verify-btn-input verify-pswd-ip"
                                }
                                noValidate
                              />
                              <img src={passwordShown ? pwdShow : pwdHide} className="verify-pwdToggle" onClick={togglePassword} />
                              {state.pinErrorFlag === true ? (
                                <div className="pds-error common-pds-error pinError">
                                  {t("common.incorrectPin")}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5">
                              <button
                                className="btn-blue pds-button pds-button-tertiary btn-verifysubmit verify-btn-input"
                                name="update"
                                // eslint-disable-next-line no-undef
                                disabled={!(Number(process.env.REACT_APP_WRITE_ACCESS)) || state.disableUpdate || serviceCallFlag}
                                onClick={(e) => onSubmit(e)}
                              >
                                {serviceCallFlag ? <span className="spinner-border spinner-border-sm verify-spinnerStyle" role="status" aria-hidden="true"></span> : t("common.submit")}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Logger>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    countryDropDown: state.commonDropDown.countryDropDown,
    verifyData: state.verify.verifyData,
    contactInfoDetails: state.landing.contactInfoDetails,
    contactInfoUpdateFlag: state.landing.contactInfoUpdateFlag,
    contactInfoUpdateJson: state.landing.contactInfoUpdateJson,
    emailInfoDetails: state.landing.emailInfoDetails,
    emailInfoUpdateFlag: state.landing.emailInfoUpdateFlag,
    emailInfoUpdateJson: state.landing.emailInfoUpdateJson,
    emailVerifyEditFlag: state.landing.emailVerifyEditFlag,
    mobileInfoDetails: state.landing.mobileInfoDetails,
    mobileInfoUpdateFlag: state.landing.mobileInfoUpdateFlag,
    mobileInfoUpdateJson: state.landing.mobileInfoUpdateJson,
    mobileVerifyEditFlag: state.landing.mobileVerifyEditFlag,
    clientNmbr: state.empLogin.clientNmbr,
    loginId: state.empLogin.loginId,
    filteredEngCountryDropDown: state.commonDropDown.filteredEngCountryDropDown,
    filteredChineseCountryDropDown: state.commonDropDown.filteredChineseCountryDropDown,
    pisysToken: state.token.pisysToken,
    authToken: state.token.authToken,
    scheme: state.empLogin.scheme,
    cycleDate: state.landing.cycleDate,
    quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
    allPolicyList: state.twoAccounts.allPolicyList,
    emailInfoUpdateOktaJson: state.landing.emailInfoUpdateOktaJson,
    mobileInfoUpdateOktaJson: state.landing.mobileInfoUpdateOktaJson,
    authMFAData: state.TFADetails.authMFAData,
    clientNmbrList: state.empLogin.clientNmbrList,
    loginSchemeProduct: state.twoAccounts.loginSchemeProduct,
    productTypeNmbr: state.empLogin.productTypeNmbr,
    clntRltnshpNmbr: state.empLogin.clntRltnshpNmbr,
  };
};
const VerifyComp = connect(mapStateToProps)(VerificationComponent);

export default class Verification extends React.Component {
  constructor() {
    super();
  }
  render() {
    return <VerifyComp></VerifyComp>;
  }
}