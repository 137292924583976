import dateFormat from 'dateformat';
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Slide, toast } from 'react-toastify';
import { savePopUpAlertStatus } from '../../../../src/actions/DashboardAction';
import Toast from '../../../Components/Toast';
import Logger from '../../../SharedComponents/Logger';
import { haveSelectedAccount, saveCoreOrsoAccount, saveLoginSchemeProduct, saveOrsoAccFlag, savePolicyDetails, saveSelectedAccountBalance, saveSelectedPolicyIndex, saveSelectedQuickPolicyInfo } from "../../../actions/AccountAction";
import { isPolicyTerminated, saveClientNmbr, saveClntRltnshpNmbr, saveContractNmbr, saveFeesChargeInfo, saveFirstTimeSelectAccoutnLoading, saveProductTypeNmbr, saveQuicPolicyClientInfo } from "../../../actions/EmployeeLoginAction";
import { saveFeesAndCharges } from '../../../actions/FundPerformanceAction';
import { saveFundInfoData } from '../../../actions/FundSwitchAction';
import { saveClientDetails } from '../../../actions/LandingAction';
import arrow from "../../../assets/Arrow-right-blue.svg";
import "../../../css/SelectAccount.css";
import { ChineseAM, ChinesePM, HKAIIB, S800Acronym, SMP, accountTypPa, accountTypSep, accountTypSvc, accountTypTvc, accountTypeEe, invalidDate, productTypeORSO } from "../../../translations/common/commonConstants";
import { APIUtils, AccountUtils, DashBoardUtils, FormatUtils, getContractInfo, logoutTo, saveContractInfo, saveLatestPriceDate } from "../../../utils";
import { addGlobalFundInfoWithDetails } from '../../../utils/FundInfoAPIUtils';
import { getCustomFieldsUtils } from "../../../utils/customFieldsUtils";

function SelectAccountComponent(props) {
  const { t, i18n } = useTranslation("common");
  let filteredAccountsList = props.quickPolicyInfo;
  const history = useHistory();
  let spacesToAddForUsername = 0;
  let [policyTotalAmountArr, setpolicyTotalAmount] = useState([]);
  const [policyDetails, setPolicyDetails] = useState([]);
  const [loading, setloading] = useState(true);
  const webCycleDate = (props.webCycleDate !== null && props.webCycleDate !== "") && moment(props.webCycleDate, "YYYY-MM-DD HH:mm:ss A").format(i18n.language === 'en' ? 'DD MMM YYYY' : 'YYYY年M月D日');
  const lastLoginDt = props?.lastLoginDate && moment(props.lastLoginDate, "DD MM YYYY hh:mm A").format(i18n.language === 'en' ? 'D MMM YYYY hh:mm A' : 'YYYY年M月D日')?.toUpperCase() + `${i18n.language === 'zh' ? (props?.lastLoginDate?.includes("PM") ? ChinesePM : ChineseAM) + moment(props.lastLoginDate, "DD MM YYYY hh:mm A").format('hh:mm') : ""}`;
  let clientNmbr = String(props.clientNmbr).padStart(7, "0");
  let memberCid = String(props?.authMFAData?.userName).padStart(8, "0");

  const headers = {
    pisys_auth_token: props.pisysToken,
    username: props.clientNmbr,
    "Accept-Language": "en",
    "Access-Control-Allow-Origin": "*"
  };

  const toastOptions = {
    position: 'top-center',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    rtl: false,
    closeButton: false
  };

  const sendNotification = (type) => {
    toast(<Toast type={type} />, toastOptions);
  };

  useEffect(async () => {
    if (filteredAccountsList.length >= 1 && props.firstTimeLoadSelectAccount) {
      props.dispatch(saveFirstTimeSelectAccoutnLoading(false));
    }
    if (filteredAccountsList.length === 1) {
      if (props?.scheme === 'SMP') {
        props.dispatch(saveSelectedPolicyIndex(0));
        props.dispatch(saveClntRltnshpNmbr(filteredAccountsList[0]?.clntRltnshpNmbr?.toString() ?? ''));
        props.dispatch(saveContractNmbr(filteredAccountsList[0]?.contract?.contractNmbr?.toString() ?? ''));
        saveContractCustomAttributes(filteredAccountsList[0], props);
        props.dispatch(saveClientNmbr(filteredAccountsList[0]?.client?.clientId));
        const productTypeNmbr = filteredAccountsList?.[0].product?.productTypeNmbr?.toString();
        props.dispatch(saveProductTypeNmbr(productTypeNmbr));
        const fundInfo = props?.allPolicyFundInfo?.find((fund) => fund?.contractNumber === filteredAccountsList[0]?.contract?.contractNmbr);
        props.dispatch(saveFundInfoData(fundInfo?.fundInfoCurrencyRisks ?? []));
        const webCycleDt = moment(props?.webCycleDate, "YYYY-MM-DD HH:mm:ss A").format("YYYY-MM-DD");
        addGlobalFundInfoWithDetails(props, fundInfo?.fundInfoCurrencyRisks ?? [], filteredAccountsList[0]?.product?.productTypeNmbr, webCycleDt, headers);
        props.dispatch(saveQuicPolicyClientInfo(filteredAccountsList?.[0].client));
      }

      APIUtils.pwcClientCheck(filteredAccountsList?.[0]?.clntRltnshpNmbr, headers, props);
      await getCustomFieldsUtils(props, filteredAccountsList[0]?.product?.productTypeNmbr);
      props.policyDetailsList.forEach((value) => {
        policyDetails.push(value);
        let totalAmt = 0;
        value.policyAccountValueVOList.forEach((item) => {
          totalAmt = totalAmt + item.investmentAmt;
        });
        policyTotalAmountArr.push(totalAmt);
      });

      setpolicyTotalAmount([...policyTotalAmountArr]);
      setPolicyDetails([...policyDetails]);
      props.dispatch(saveSelectedQuickPolicyInfo(filteredAccountsList[0]));
      props.dispatch(savePolicyDetails(policyDetails[0]));
      props.dispatch(saveSelectedAccountBalance(policyTotalAmountArr[0]));

      let isCBFund = DashBoardUtils.checkCBFund(props.policyDetailsList);
      let total = 0;
      props.policyDetailsList.forEach((obj) => {
        obj.policyAccountValueVOList.forEach((o) => {
          total = total + o.investmentAmt;
        });
      });
      if (isCBFund && total === 0) {
        // eslint-disable-next-line no-undef
        let path = logoutTo(props?.scheme, !!(Number(process.env.REACT_APP_WRITE_ACCESS)));
        history.push(path);
        sendNotification('no-valid-policy');
      } else {
        history.push("/dashboard");
      }
      props.dispatch(haveSelectedAccount(true));
      AccountUtils.getSelectedPolicyEnrollmentDayCount(props, filteredAccountsList[0]?.clntRltnshpNmbr, filteredAccountsList[0]?.contract?.contractNmbr, props.policyEnrollmentPeriodList);
    } else {
      props.policyDetailsList.forEach((value) => {
        policyDetails.push(value);
        let totalAmt = 0;
        value.policyAccountValueVOList.forEach((item) => {
          totalAmt = totalAmt + item.investmentAmt;
        });
        policyTotalAmountArr.push(totalAmt);
      });

      setpolicyTotalAmount([...policyTotalAmountArr]);
      setPolicyDetails([...policyDetails]);
      setloading(false);
      props.dispatch(haveSelectedAccount(false));
    }
  }, []);

  const getFeesAndChargesList = async (selectedQuickPolicyInfo) => {
    const clntRltnShpNmbr = selectedQuickPolicyInfo?.clntRltnshpNmbr;
    if (props?.feesChargesInfo[clntRltnShpNmbr]) {
      let response = props?.feesChargesInfo?.[clntRltnShpNmbr] ? props.feesChargesInfo[clntRltnShpNmbr] : {};
      props.dispatch(saveFeesAndCharges(response));
    } else {
      const feesAndChargesArray = props?.policyWiseFeesCharges ?? [];
      const feesAndChargesData = feesAndChargesArray?.find((data) => data?.feeChargesSmry?.[0]?.clntRltnshpNmbr === clntRltnShpNmbr) ?? {};
      let temp = props?.feesChargesInfo ? props.feesChargesInfo : {};
      temp[clntRltnShpNmbr] = feesAndChargesData;
      props.dispatch(saveFeesChargeInfo(temp));
      props.dispatch(saveFeesAndCharges(feesAndChargesData));
    }
  };

  const saveContractCustomAttributes = (item, props) => {
    const contractCustomAttributes = props?.mergedContractCustomAttributes?.find((resp) => resp?.contractNmbr === item?.contract?.contractNmbr)?.contractAttributeVOs ?? [];
    if (Array.isArray(contractCustomAttributes) && contractCustomAttributes.length > 0) {
      saveContractInfo(props, contractCustomAttributes);
    } else {
      getContractInfo(props, item?.contract?.contractNmbr);
    }
  };

  const handleClick = (item, props, index, selectedAccountBalance) => {
    if (props?.scheme === 'SMP') {
      //save data of contract cstm attributes based on selected policy contract number
      const clientId = item?.client?.clientId;
      saveContractCustomAttributes(item, props);
      props.dispatch(saveClientNmbr(clientId));
      props.dispatch(saveQuicPolicyClientInfo(item.client));
      const clientInfo = props?.multipleClientInfo?.find(clientInfo => clientInfo.clientNmbr === clientId);
      props.dispatch(saveClientDetails(clientInfo));
    }
    saveLatestPriceDate(props, item.product.productTypeNmbr, headers);
    getFeesAndChargesList(item);
    getCustomFieldsUtils(props, item.product.productTypeNmbr);
    const detailedPolicyInfo = policyDetails.find((policy) => policy?.policyVO?.clntRltnshpNmbr === item?.clntRltnshpNmbr);
    APIUtils.pwcClientCheck(item?.clntRltnshpNmbr, headers, props);
    let productName = item?.product?.productAcronym?.toUpperCase().trim();
    if (item?.product?.productTypeName?.includes(productTypeORSO) && props.scheme == S800Acronym) {
      props.dispatch(saveOrsoAccFlag(true));
      props.dispatch(savePopUpAlertStatus(false));
      productName = S800Acronym;
    } else {
      props.dispatch(saveOrsoAccFlag(false));
    }
    props.dispatch(saveSelectedPolicyIndex(index));
    props.dispatch(saveLoginSchemeProduct(productName));
    props.dispatch(saveCoreOrsoAccount(false));
    props.dispatch(saveSelectedQuickPolicyInfo(item));
    props.dispatch(saveClntRltnshpNmbr(item.clntRltnshpNmbr.toString()));
    props.dispatch(saveContractNmbr(item.contract.contractNmbr.toString()));
    props.dispatch(saveProductTypeNmbr(item.product.productTypeNmbr.toString()));
    props.dispatch(saveSelectedAccountBalance(selectedAccountBalance));
    props.dispatch(haveSelectedAccount(true));
    item.terminationDate !== null ? props.dispatch(isPolicyTerminated(true)) : props.dispatch(isPolicyTerminated(false));
    props.dispatch(savePolicyDetails(detailedPolicyInfo));
    history.push("/dashboard");
    const fundInfo = props?.allPolicyFundInfo?.find((fund) => fund?.contractNumber === item?.contract?.contractNmbr);
    props.dispatch(saveFundInfoData(fundInfo?.fundInfoCurrencyRisks ?? []));
    const webCycleDt = moment(props?.webCycleDate, "YYYY-MM-DD HH:mm:ss A").format("YYYY-MM-DD");
    addGlobalFundInfoWithDetails(props, fundInfo?.fundInfoCurrencyRisks ?? [], item?.product?.productTypeNmbr, webCycleDt, headers);
    AccountUtils.getSelectedPolicyEnrollmentDayCount(props, item?.clntRltnshpNmbr, item?.contract?.contractNmbr, props.policyEnrollmentPeriodList);
  };

  const renderAccountCard = (item, props, index) => {
    let cardTitle = "";
    let accountType = item.accountType.toUpperCase().trim();
    if (accountType === accountTypPa) {
      cardTitle = 'selectAccount.personalAcc';
    } else if (accountType === accountTypSep) {
      cardTitle = 'selectAccount.selfEmp';
    } else if (accountType === accountTypTvc) {
      cardTitle = 'selectAccount.tvc';
    } else if (accountType === accountTypSvc) {
      cardTitle = 'selectAccount.svc';
    } else if (accountType === accountTypeEe) {
      if (props.scheme === HKAIIB) {
        cardTitle = props?.policyDetailsList?.filter((x) => x?.policyVO?.clntRltnshpNmbr === item?.clntRltnshpNmbr)?.[0]?.policyVO?.policyHistoryList?.[0]?.planName;
      } else {
        cardTitle = props?.chineseOrgNames?.[index] ?? item?.client?.organizationNm.toLowerCase();
      }
    } else {
      cardTitle = accountType;
    }
    return (
      <div className="col-lg-6 col-xs-12 col-md-12">
        <div onClick={() => handleClick(item, props, index, policyTotalAmountArr[index])} className="sa-card" data-testid={"account-card"}>
          <label className="sa-memNmbr">{t("portfolio.contractNo")}
            <img src={arrow} alt="" className="sa-arrow" />
          </label>
          <label className="sa-memNmbr-value">{AccountUtils.getContractNumber(props, item)}</label>
          <span className="sa-ammount">
            {FormatUtils.formatToDecimal(policyTotalAmountArr[index])}
            {props.scheme === HKAIIB ? <p className="sa-hkd">{t("currencies.usd")}</p> : <p className="sa-hkd">{t("fundSwitchOut.hkd")}</p>}
          </span>
          <label className="sa-company">{t(cardTitle)}</label>
        </div>
      </div>
    );
  };

  const clientName = FormatUtils.getClientName(props?.clientInfo);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border spinner m-5" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <Logger logImpression data={{ page: "Select Account Page" }}>
          <div className="main-content">
            <div className="container">
              <div className="row">
                <div className="col-12 sa-welcome-block-margn">
                  <div className="row">
                    <div className="col-lg-8 col-xs-12 col-md-6">
                      <h1 className="sa-title">{t("dashboard.welcome")} {clientName}</h1>
                    </div>
                    <div className="col-lg-4 col-xs-12 col-md-6 sa-login">
                      {props?.scheme === SMP ? <label className="sa-membrNmbr float-details">{t("common.memberNmbr")} {memberCid}</label> : <label className="sa-membrNmbr float-details">{t("common.memberNmbr")} {clientNmbr}</label>}
                      {props?.lastLoginDate && <label className="sa-login float-details">{t("dashboard.lastLogin")} {lastLoginDt !== invalidDate ? lastLoginDt : dateFormat(props.lastLoginDate, i18n.language === 'en' ? 'd mmm yyyy HH:MM TT' : 'yyyy年m月d日')?.toUpperCase() + `${i18n.language === 'zh' ? (props?.lastLoginDate?.includes("PM") ? ChinesePM : ChineseAM) + moment(props.lastLoginDate, "DD MM YYYY hh:mm A").format('hh:mm') : ""}`}</label>}
                    </div>
                  </div>

                  <h4 className="sa-heading">{t("dashboard.title")}</h4>
                  <label className="sa-subHeading">{t("dashboard.selectAcc")}</label>

                  <div className="row">
                    {filteredAccountsList.map((item, index) => {
                      AccountUtils.getContractNumber(props, item);
                      spacesToAddForUsername = (item?.client?.organizationNm?.length > spacesToAddForUsername) ? (item?.client?.organizationNm?.length) : (spacesToAddForUsername);
                      return renderAccountCard(item, props, index, spacesToAddForUsername);
                    })}
                  </div>

                  <label className="sa-portfolio">
                    {t("dashboard.portfolioAll")} {webCycleDate}
                  </label>
                  <div className="row">
                    <div className="col-12 mt-2">
                      <label className="sa-note-title">{t("accBalByFund.note")}</label>
                      <label className="sa-note-line">{t("dashboard.decimalNote")}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Logger>
      </React.Fragment>
    );
  }
}

SelectAccountComponent.propTypes = {
  props: PropTypes.any,
  pisysToken: PropTypes.string,
  loginId: PropTypes.string,
  schemeProduct: PropTypes.string,
  scheme: PropTypes.string,
  quickPolicyInfo: PropTypes.array,
  clientNmbr: PropTypes.string,
  cycleDate: PropTypes.string,
  dispatch: PropTypes.func,
  webCycleDate: PropTypes.string,
  toastOptions: PropTypes.object,
  chineseOrgNames: PropTypes.array,
  firstTimeLoadSelectAccount: PropTypes.bool,
  feesChargesInfo: PropTypes.object,
  allPolicyFundInfo: PropTypes.array,
  policyWiseFeesCharges: PropTypes.array,
  clientInfo: PropTypes.object,
  multipleClientInfo: PropTypes.array,
  policyEnrollmentPeriodList: PropTypes.array,
  lastLoginDate: PropTypes.string,
  policyDetailsList: PropTypes.object,
  mergedContractCustomAttributes: PropTypes.array,
  authMFAData: PropTypes.object,
  selectedPolicyIndex: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    pisysToken: state.token.pisysToken,
    loginId: state.empLogin.loginId,
    schemeProduct: state.twoAccounts.loginSchemeProduct,
    policyDetailsList: state.twoAccounts.allPolicyList,
    quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
    scheme: state.empLogin.scheme,
    cycleDate: state.landing.cycleDate,
    clientNmbr: state.empLogin.clientNmbr,
    clntRltnshpNmbr: state.empLogin.clntRltnshpNmbr,
    webCycleDate: state.empLogin.webCycleDate,
    lastLoginDate: state.empLogin.lastLoginDate,
    firstTimeLoadSelectAccount: state.empLogin.firstTimeLoadSelectAccount,
    feesChargesInfo: state.empLogin.feesChargesInfo,
    policyWiseFeesCharges: state.fundPerformance.policyWiseFeesCharges,
    toastOptions: state.landing.toastOptions,
    quickPolicyClientInfo: state.empLogin.quickPolicyClientInfo,
    chineseOrgNames: state.empLogin.chineseOrgNames,
    allPolicyFundInfo: state.fundSwitch.allPolicyFundInfo,
    clientInfo: state.landing.clientInfo,
    multipleClientInfo: state.landing.multipleClientInfo,
    policyEnrollmentPeriodList: state.twoAccounts.policyEnrollmentPeriodList,
    mergedContractCustomAttributes: state.twoAccounts.mergedContractCustomAttributes,
    authMFAData: state.TFADetails.authMFAData
  };
};

export default connect(mapStateToProps)(SelectAccountComponent);