import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  saveContactInfoDetails,
  saveContactInfoUpdateFlag,
  saveContactUpdateJson,
  saveContactVerifyEditFlag,
  saveRestrictAccessFlag
} from "../../../../actions/LandingAction";
import { schemeAIIB, schemeS800, SMP } from "../../../../translations/common/commonConstants";
import { AddressUtil, ContactUtil, getValidJrsdNmbr, UiUtils } from "../../../../utils";

ContactInfo.propTypes = {
  props: PropTypes.any,
  countryDropDown: PropTypes.arrayOf(PropTypes.object),
  clientInfo: PropTypes.object,
  homeContactNumber: PropTypes.string,
  homeCountryCode: PropTypes.string,
  office: PropTypes.string,
  officeCountryCode: PropTypes.string,
  fax: PropTypes.string,
  contactInfoDetails: PropTypes.object,
  contactVerifyEditFlag: PropTypes.bool,
  faxCountryCode: PropTypes.string,
  dispatch: PropTypes.func,
  onEdit: PropTypes.func,
  activeEdit: PropTypes.bool,
  scheme: PropTypes.string,
  selectedQuickPolicyInfo: PropTypes.object,
  isOrsoAccount: PropTypes.bool,
  clntRltnshpNmbr: PropTypes.string,
  productTypeNmbr: PropTypes.string,
};

let pageRefreshFlag = true;

function ContactInfo(props) {
  const { t } = useTranslation("common");
  const history = useHistory();
  let [hkHomeNmLengthError, setHkHomeNmLengthError] = useState(false);
  let [nonHkHomeNmLengthError, setNonHkHomeNmLengthError] = useState(false);
  let [hkOfficeNmLengthError, setHkOfficeNmLenghtError] = useState(false);
  let [nonHkOfficeNmLengthError, setNonHkOfficeNmLenghtError] = useState(false);
  let [hkFaxNmLengthError, setHkFaxNmLengthError] = useState(false);
  let [nonHkFaxNmLengthError, setNonHkFaxNmLengthError] = useState(false);
  let [otherContactInfoEditFlag, setOtherContactInfoEditFlag] = useState(false);
  let [homeBadAddressFlag, setHomeBadAddressFlag] = useState(false);
  let [officeBadAddressFlag, setOfficeBadAddressFlag] = useState(false);
  let [faxBadAddressFlag, setFaxBadAddressFlag] = useState(false);

  useEffect(() => {
    if (history && history.action === 'POP' && pageRefreshFlag === true) {
      resetPropsValue();
      pageRefreshFlag = false;
    }
    let hkHome = false;
    let hkOffice = false;
    let hkFax = false;
    let hkHomePrev = false;
    let hkOfficePrev = false;
    let hkFaxPrev = false;
    if (state.homeNumber && state.hCountryCodeDrDown && state.homeNumber.length === 11 && state.homeNumber.startsWith('852') && (state.hCountryCodeDrDown === 100 || state.hCountryCodeDrDown === 101)) {
      hkHome = true;
    }
    if (state.prevHomeNumber && state.hCountryCodeDrDown && state.prevHomeNumber.length === 11 && state.prevHomeNumber.startsWith('852') && (state.prevHomePhoneCode === "+852")) {
      hkHomePrev = true;
    }
    if (state.officeNumber && state.oCountryCodeDrDown && state.officeNumber.length === 11 && state.officeNumber.startsWith('852') && (state.oCountryCodeDrDown === 100 || state.oCountryCodeDrDown === 101)) {
      hkOffice = true;
    }
    if (state.prevOfficeNumber && state.oCountryCodeDrDown && state.prevOfficeNumber.length === 11 && state.prevOfficeNumber.startsWith('852') && (state.prevOfficePhoneCode === "+852")) {
      hkOfficePrev = true;
    }
    if (state.faxNumber && state.fCountryCodeDrDown && state.faxNumber.length === 11 && state.faxNumber.startsWith('852') && (state.fCountryCodeDrDown === 100 || state.fCountryCodeDrDown === 101)) {
      hkFax = true;
    }
    if (state.prevFaxNumber && state.fCountryCodeDrDown && state.prevFaxNumber.length === 11 && state.prevFaxNumber.startsWith('852') && (state.prevFaxPhoneCode === "+852")) {
      hkFaxPrev = true;
    }
    setState({
      ...state,
      homeNumber: hkHome ? state.homeNumber.substring(3) : state.homeNumber,
      officeNumber: hkOffice ? state.officeNumber.substring(3) : state.officeNumber,
      faxNumber: hkFax ? state.faxNumber.substring(3) : state.faxNumber,
      prevHomeNumber: hkHomePrev ? state.prevHomeNumber.substring(3) : state.prevHomeNumber,
      prevOfficeNumber: hkOfficePrev ? state.prevOfficeNumber.substring(3) : state.prevOfficeNumber,
      prevFaxNumber: hkFaxPrev ? state.prevFaxNumber.substring(3) : state.prevFaxNumber,
    });
    checkHomeMBErrorMsgVisibility();
    checkOfficeMBErrorMsgVisibility();
    checkFaxMBErrorMsgVisibility();
    if (props.contactVerifyEditFlag) {
      props.onEdit(true);
    }
  }, []);

  const checkHomeMBErrorMsgVisibility = () => {
    if (props?.clientInfo?.homePltcJrsdNmbr == 166 || props?.clientInfo?.homePltcJrsdNmbr == 167 || !state?.homeNumber
      || ((props?.clientInfo?.homePltcJrsdNmbr == 166 || props?.clientInfo?.homePltcJrsdNmbr == 167) && !state?.homePhoneCode)) {
      setHomeBadAddressFlag(true);
    } else {
      setHomeBadAddressFlag(false);
    }
  };

  const checkOfficeMBErrorMsgVisibility = () => {
    if ((props.scheme === schemeS800 && !(props?.isOrsoAccount) || props.scheme === SMP)) {
      if (props?.clientInfo?.officePltcJrsdNmbr == 166 || props?.clientInfo?.officePltcJrsdNmbr == 167
        || !state?.officeNumber
        || ((props?.clientInfo?.officePltcJrsdNmbr == 166 || props?.clientInfo?.officePltcJrsdNmbr == 167) && !state?.officePhoneCode)) {
        setOfficeBadAddressFlag(true);
      } else {
        setOfficeBadAddressFlag(false);
      }
    } else {
      let orsoAddress = "";
      orsoAddress = AddressUtil.getAddressType(orsoAddress, props);
      if (['BAD ADDRESS,BAD ADDRESS'].includes(orsoAddress?.addressLocationNm)
        || orsoAddress?.addressPltcJrsdNmbr == 166 || orsoAddress?.addressPltcJrsdNmbr == 167
        || !state?.officeNumber
        || ((orsoAddress?.addressPltcJrsdNmbr == 166 || orsoAddress?.addressPltcJrsdNmbr == 167) && !state?.officePhoneCode)) {
        setOfficeBadAddressFlag(true);
      } else {
        setOfficeBadAddressFlag(false);
      }
    }
  };

  const checkFaxMBErrorMsgVisibility = () => {
    if (props?.clientInfo?.faxPltcJrsdNmbr == 166 || props?.clientInfo?.faxPltcJrsdNmbr == 167
      || !state?.faxNumber
      || ((props?.clientInfo?.faxPltcJrsdNmbr == 166 || props?.clientInfo?.faxPltcJrsdNmbr == 167) && !state?.faxPhoneCode)) {
      setFaxBadAddressFlag(true);
    } else {
      setFaxBadAddressFlag(false);
    }
  };

  let countries = props && props.countryDropDown ? props.countryDropDown : "";
  const homePltcJrsdNmbr = getValidJrsdNmbr(props?.clientInfo?.homePltcJrsdNmbr);
  const homeCountry = countries
    ? countries.filter(
      (x) => x.pltcJrsdNmbr === homePltcJrsdNmbr
    )
    : "";
  let ofcPhn = props?.clientInfo?.clientPhones ? props?.clientInfo?.clientPhones?.filter((clntPh) => (clntPh?.phoneTypeCd == 109 || clntPh?.phoneTypeCd == 110) && (clntPh?.phonePltcJrsdNmbr == 100 || clntPh?.phonePltcJrsdNmbr == 101)
  ) : [];
  const officePltcJrsdNmbr = getValidJrsdNmbr(ofcPhn?.[0]?.phonePltcJrsdNmbr);
  const officeCountry = countries
    ? countries?.filter(
      (x) => x.pltcJrsdNmbr === officePltcJrsdNmbr
    )
    : "";
  const faxPltcJrsdNmbr = getValidJrsdNmbr(props?.clientInfo?.faxPltcJrsdNmbr);
  const faxCountry = countries
    ? countries.filter(
      (x) => x.pltcJrsdNmbr === faxPltcJrsdNmbr
    )
    : "";

  var updatedContactDetails;
  if (props.contactVerifyEditFlag === true) {
    if (Object.keys(props.contactInfoDetails).length > 0) {
      updatedContactDetails = props.contactInfoDetails;
    }
    var doc = document.getElementById("otherContactSection");
    if (doc !== undefined && doc !== null) {
      setTimeout(function () {

        document.getElementById("otherContactSection").click();
      }, 2);
    }
  }
  var homePNumber =
    props.contactVerifyEditFlag && updatedContactDetails !== undefined
      ? updatedContactDetails.homeNm
      : props.clientInfo.homeContactNumber === undefined ||
        props.clientInfo.homeContactNumber === null
        ? ""
        : props.clientInfo.homeContactNumber;
  var homeCNumber =
    props.contactVerifyEditFlag && updatedContactDetails !== undefined
      ? updatedContactDetails.homePltclJrNum
      : props.clientInfo.homePltcJrsdNmbr === undefined ||
        props.clientInfo.homePltcJrsdNmbr === null
        ? ""
        : homeCountry.length > 0
          ? homeCountry[0].pltcJrsdNmbr
          : "";
  var homePhoneCode = props.contactVerifyEditFlag && updatedContactDetails !== undefined
    ? updatedContactDetails.homeCountryCode
    : props.clientInfo.homePltcJrsdNmbr === undefined ||
      props.clientInfo.homePltcJrsdNmbr === null
      ? ""
      : homeCountry.length > 0
        ? homeCountry[0].phoneCd
        : "";
  var prevHomePhoneCode = props.clientInfo.homePltcJrsdNmbr === undefined ||
    props.clientInfo.homePltcJrsdNmbr === null
    ? ""
    : homeCountry.length > 0
      ? homeCountry[0].phoneCd
      : "";

  let orsoOfficePhone = ContactUtil.getOrsoOfficePh(props.clientInfo.clientPhones);

  let officePhone = "";
  if (orsoOfficePhone === "" || orsoOfficePhone === undefined) {
    officePhone = ContactUtil.getOfficePhone(props.clientInfo.clientPhones);
  }
  let mpfOfficePhone = ContactUtil.getMpfOfficePhone(props.clientInfo.clientPhones);
  if (mpfOfficePhone === "" || mpfOfficePhone === undefined) {
    officePhone = ContactUtil.getOfficePhone(props.clientInfo.clientPhones);
  }
  var officePhoneCode =
    props.contactVerifyEditFlag && updatedContactDetails !== undefined
      ? updatedContactDetails.officeCountryCode
      : props.clientInfo.officePltcJrsdNmbr === undefined ||
        props.clientInfo.officePltcJrsdNmbr === null
        ? ""
        : officeCountry.length > 0
          ? officeCountry[0].phoneCd
          : "";

  var prevOfficePhoneCode = props.clientInfo.officePltcJrsdNmbr === undefined ||
    props.clientInfo.officePltcJrsdNmbr === null
    ? ""
    : officeCountry.length > 0
      ? officeCountry[0].phoneCd
      : "";

  var faxPhoneCode = props.contactVerifyEditFlag && updatedContactDetails !== undefined
    ? updatedContactDetails.faxCountryCode
    : props.clientInfo.faxPltcJrsdNmbr === undefined ||
      props.clientInfo.faxPltcJrsdNmbr === null
      ? ""
      : faxCountry.length > 0
        ? faxCountry[0].phoneCd
        : "";
  var prevFaxPhoneCode = props.clientInfo.faxPltcJrsdNmbr === undefined ||
    props.clientInfo.faxPltcJrsdNmbr === null
    ? ""
    : faxCountry.length > 0
      ? faxCountry[0].phoneCd
      : "";

  var officePNumber =
    ((props.scheme === schemeS800 && !(props?.isOrsoAccount)) || props.scheme === SMP) ? /*  (props?.clientInfo?.clientPhones && props.clientInfo.clientPhones.length > 0 ?
      mpfOfficePhone.phoneTxt ? mpfOfficePhone.phoneTxt : (officePhone ? officePhone.phoneTxt : "") : "") */
      (props.contactVerifyEditFlag && updatedContactDetails !== undefined
        ? updatedContactDetails.officeNm
        : props.clientInfo.office === undefined ||
          props.clientInfo.office === null
          ? ""
          : props.clientInfo.office) :
      (props?.clientInfo?.clientPhones && props.clientInfo.clientPhones.length > 0 ?
        orsoOfficePhone.phoneTxt ? orsoOfficePhone.phoneTxt : (officePhone ? officePhone.phoneTxt : "") : "");

  var officeCNumber =
    props.contactVerifyEditFlag && updatedContactDetails !== undefined
      ? updatedContactDetails.offPltclJrNum
      : props.clientInfo.officePltcJrsdNmbr === undefined ||
        props.clientInfo.officePltcJrsdNmbr === null
        ? ""
        : officeCountry.length > 0
          ? officeCountry[0].pltcJrsdNmbr
          : "";
  var faxPNumber =
    props.contactVerifyEditFlag && updatedContactDetails !== undefined
      ? updatedContactDetails.faxNm
      : props.clientInfo.fax === undefined || props.clientInfo.fax === null
        ? ""
        : props.clientInfo.fax;

  var faxCNumber =
    props.contactVerifyEditFlag && updatedContactDetails !== undefined
      ? updatedContactDetails.faxPltclJrNum
      : props.clientInfo.faxPltcJrsdNmbr === undefined ||
        props.clientInfo.faxPltcJrsdNmbr === null
        ? ""
        : faxCountry.length > 0
          ? faxCountry[0].pltcJrsdNmbr
          : "";
  const [state, setState] = useState({
    otherContactInfoDisplayFlag: true,
    otherContactInfoUpdateFlag: false,
    otherContactInfoVerifyPAge: false,

    homeNumber: homePNumber,
    homePhoneCode: homePhoneCode,
    prevHomePhoneCode: prevHomePhoneCode,
    officePhoneCode: officePhoneCode,
    prevOfficePhoneCode: prevOfficePhoneCode,
    faxPhoneCode: faxPhoneCode,
    prevFaxPhoneCode: prevFaxPhoneCode,
    prevHomeNumber: props.contactVerifyEditFlag
      ? props.clientInfo.homeContactNumber === undefined ||
        props.clientInfo.homeContactNumber === null
        ? ""
        : props.clientInfo.homeContactNumber
      : "",
    hcodeEditFlag:
      props.contactVerifyEditFlag && updatedContactDetails !== undefined
        ? updatedContactDetails.homeDetailsEditFlag
        : false,
    hcodeErrorFlag: true,
    hNmEditFlag:
      props.contactVerifyEditFlag && updatedContactDetails !== undefined
        ? updatedContactDetails.homeDetailsEditFlag
        : false,
    hNmErrorFlag: true,
    hCountryCodeDrDown: homeCNumber,
    prevHCountryCodeDrDown: props.contactVerifyEditFlag
      ? props.clientInfo.homePltcJrsdNmbr === undefined ||
        props.clientInfo.homePltcJrsdNmbr === null
        ? ""
        : homeCountry.length > 0
          ? homeCountry[0].pltcJrsdNmbr
          : ""
      : "",
    hOnlyNmAllowFlag: false,

    officeNumber: officePNumber,
    prevOfficeNumber: props.contactVerifyEditFlag
      ? props.clientInfo.office === undefined ||
        props.clientInfo.office === null
        ? ""
        : props.clientInfo.office
      : "",
    ocodeEditFlag:
      props.contactVerifyEditFlag && updatedContactDetails !== undefined
        ? updatedContactDetails.officeDetailsEditFlag
        : false,
    ocodeErrorFlag: true,
    oNmEditFlag:
      props.contactVerifyEditFlag && updatedContactDetails !== undefined
        ? updatedContactDetails.officeDetailsEditFlag
        : false,
    oNmErrorFlag: true,
    oCountryCodeDrDown: officeCNumber,
    prevoCountryCodeDrDown: props.contactVerifyEditFlag
      ? props.clientInfo.officePltcJrsdNmbr === undefined ||
        props.clientInfo.officePltcJrsdNmbr === null
        ? ""
        : officeCountry.length > 0
          ? officeCountry[0].pltcJrsdNmbr
          : ""
      : "",
    oOnlyNmAllowFlag: false,

    faxNumber: faxPNumber,
    prevFaxNumber: props.contactVerifyEditFlag
      ? props.clientInfo.fax === undefined || props.clientInfo.fax === null
        ? ""
        : props.clientInfo.fax
      : "",
    fcodeEditFlag:
      props.contactVerifyEditFlag && updatedContactDetails !== undefined
        ? updatedContactDetails.faxDetailsEditFlag
        : false,
    fcodeErrorFlag: true,
    fNmEditFlag:
      props.contactVerifyEditFlag && updatedContactDetails !== undefined
        ? updatedContactDetails.faxDetailsEditFlag
        : false,
    fNmErrorFlag: true,
    fCountryCodeDrDown: faxCNumber,
    prevfCountryCodeDrDown: props.contactVerifyEditFlag
      ? props.clientInfo.faxPltcJrsdNmbr === undefined ||
        props.clientInfo.faxPltcJrsdNmbr === null
        ? ""
        : faxCountry.length > 0
          ? faxCountry[0].pltcJrsdNmbr
          : ""
      : "",
    fOnlyNmAllowFlag: false,

    pin: "",
    pinErrorFlag: true,
    verifyPinFlag: true,
  });

  const handleOtherContactsInfoEdit = (e) => {
    e.preventDefault();
    if (UiUtils.determineMPFTermination("profileInfoCutOff", Number(props.productTypeNmbr))) {
      props.dispatch(saveRestrictAccessFlag(true));
      props.onEdit(false);
      window.scrollTo(0, 0);
    } else {
      if (e.target.name === "editButton") {
        setState({
          ...state,
          prevHomeNumber: state.homeNumber,
          prevHCountryCodeDrDown: state.hCountryCodeDrDown,
          prevOfficeNumber: state.officeNumber,
          prevoCountryCodeDrDown: state.oCountryCodeDrDown,
          prevFaxNumber: state.faxNumber,
          prevfCountryCodeDrDown: state.fCountryCodeDrDown,
          prevHomePhoneCode: state.homePhoneCode,
          prevOfficePhoneCode: state.officePhoneCode,
          prevFaxPhoneCode: state.faxPhoneCode
        });
        setOtherContactInfoEditFlag(true);
        setHkHomeNmLengthError(false);
        setNonHkHomeNmLengthError(false);
        setNonHkOfficeNmLenghtError(false);
        setNonHkFaxNmLengthError(false);
        setHkOfficeNmLenghtError(false);
        setHkFaxNmLengthError(false);
        props.onEdit(true);
      } else if (e.target.name === "cancel") {
        setState({
          ...state,
          homeNumber: state.prevHomeNumber,
          hCountryCodeDrDown: state.prevHCountryCodeDrDown,
          officeNumber: state.prevOfficeNumber,
          oCountryCodeDrDown: state.prevoCountryCodeDrDown,
          faxNumber: state.prevFaxNumber,
          fCountryCodeDrDown: state.prevfCountryCodeDrDown,
          hcodeEditFlag: false,
          hcodeErrorFlag: true,
          hNmEditFlag: false,
          hNmErrorFlag: true,
          ocodeEditFlag: false,
          ocodeErrorFlag: true,
          oNmEditFlag: false,
          oNmErrorFlag: true,
          fcodeEditFlag: false,
          fcodeErrorFlag: true,
          fNmEditFlag: false,
          fNmErrorFlag: true,
          homePhoneCode: state.prevHomePhoneCode,
          officePhoneCode: state.prevOfficePhoneCode,
          faxPhoneCode: state.prevFaxPhoneCode
        });
        setOtherContactInfoEditFlag(false);
        setNonHkHomeNmLengthError(false);
        setNonHkOfficeNmLenghtError(false);
        setNonHkFaxNmLengthError(false);
        setHkHomeNmLengthError(false);
        setHkOfficeNmLenghtError(false);
        setHkFaxNmLengthError(false);
        props.onEdit(false);
      }
      resetPropsValue();
    }
  };

  const enableEditFlag = () => {
    if (otherContactInfoEditFlag === false) {
      setOtherContactInfoEditFlag(true);
      props.dispatch(saveContactVerifyEditFlag(false));
    }
  };
  const resetPropsValue = () => {
    props.dispatch(saveContactVerifyEditFlag(false));
    props.dispatch(saveContactInfoUpdateFlag(false));
    props.dispatch(saveContactInfoDetails({}));
    props.dispatch(saveContactUpdateJson({}));
  };
  const onUpdate = (e) => {
    e.preventDefault();
    let homePhnCode;
    let offPhncode;
    let faxPhnCode;

    const home = props.countryDropDown
      ? props.countryDropDown.filter(
        (x) => x.pltcJrsdNmbr === parseInt((state.hCountryCodeDrDown !== undefined && state.hCountryCodeDrDown !== '') ? state.hCountryCodeDrDown : homeCNumber)
      )
      : "";

    let office =
      props && props.countryDropDown
        ? props.countryDropDown.filter(
          (x) => x.pltcJrsdNmbr === parseInt((state.oCountryCodeDrDown !== undefined && state.oCountryCodeDrDown !== '') ? state.oCountryCodeDrDown : officeCNumber)
        )
        : "";

    let fax =
      props && props.countryDropDown
        ? props.countryDropDown.filter(
          (x) => x.pltcJrsdNmbr === parseInt((state.fCountryCodeDrDown !== undefined && state.fCountryCodeDrDown !== '') ? state.fCountryCodeDrDown : faxCNumber)
        )
        : "";

    const updateData = {
      clientNmbr: props.clientInfo.clientNmbr,
      clntRltnShpNmbr: props.clntRltnshpNmbr,
      clntPhoneInfoList: [
      ]
    };

    if (home != undefined && home.length > 0 && (state.prevHomeNumber !== state.homeNumber || state.hCountryCodeDrDown !== state.prevHCountryCodeDrDown)) {
      homePhnCode = home[0].phoneCd;
      updateData.clntPhoneInfoList.push({
        phoneTypeCd: 102,
        phoneSeqNmbr: 100,
        phoneTypeNm: "HOME",
        phoneTxt: homePhnCode.replace("+", "").replace("-", "") + state.homeNumber,
        extensionTxt: null,
        phonePltcJrsdNmbr: home.length > 0 ? home[0].pltcJrsdNmbr : "",
        phoneLocationNm: home.length > 0 ? home[0].countryNm : "",
        deleteRecord: false,
      });
    }
    if (fax != undefined && fax.length > 0 && (state.prevFaxNumber !== state.faxNumber || state.fCountryCodeDrDown !== state.prevfCountryCodeDrDown)) {
      faxPhnCode = fax[0].phoneCd;
      updateData.clntPhoneInfoList.push({
        phoneTypeCd: 108,
        phoneSeqNmbr: 100,
        phoneTypeNm: "FAX",
        phoneTxt: faxPhnCode.replace("+", "").replace("-", "") + state.faxNumber,
        extensionTxt: null,
        phonePltcJrsdNmbr: fax.length > 0 ? fax[0].pltcJrsdNmbr : "",
        phoneLocationNm: fax.length > 0 ? fax[0].countryNm : "",
        deleteRecord: false,
      });
    }

    let phoneTypeName = "";
    let phoneTypeNumber = "";

    if ((props.scheme === schemeS800 && !(props?.isOrsoAccount)) || props.scheme === SMP) {
      phoneTypeName = "OFFICE";
      phoneTypeNumber = 110;
      /*  if (mpfOfficePhone !== "" && mpfOfficePhone !== undefined) {
         phoneTypeName = "MPF OFFICE"
         phoneTypeNumber = 110
       }
       else {
         phoneTypeName = "OFFICE"
         phoneTypeNumber = 109
       } */
    } else if (props.scheme === schemeAIIB || props?.isOrsoAccount) {
      if (orsoOfficePhone !== "" && orsoOfficePhone !== undefined) {
        phoneTypeName = "ORSO OFFICE";
        phoneTypeNumber = 104;
      }
      else {
        phoneTypeName = "OFFICE";
        phoneTypeNumber = 109;
      }
    }

    if (office != undefined && office.length > 0 && (state.prevOfficeNumber !== state.officeNumber || state.oCountryCodeDrDown !== state.prevoCountryCodeDrDown)) {
      offPhncode = office[0].phoneCd;
      updateData.clntPhoneInfoList.push({
        phoneTypeCd: phoneTypeNumber,
        phoneSeqNmbr: 100,
        phoneTypeNm: phoneTypeName,
        phoneTxt: offPhncode.replace("+", "").replace("-", "") + state.officeNumber,
        extensionTxt: null,
        phonePltcJrsdNmbr: office.length > 0 ? office[0].pltcJrsdNmbr : "",
        phoneLocationNm: office.length > 0 ? office[0].countryNm : "",
        deleteRecord: false,
      });
    }

    var editFiedlJsonValue = {};
    editFiedlJsonValue.homeNm = state.homeNumber;
    editFiedlJsonValue.homeCountryCode = homePhnCode;
    editFiedlJsonValue.homePltclJrNum = state.hCountryCodeDrDown;
    editFiedlJsonValue.officeNm = state.officeNumber;
    editFiedlJsonValue.officeCountryCode = offPhncode;
    editFiedlJsonValue.offPltclJrNum = state.oCountryCodeDrDown;
    editFiedlJsonValue.faxNm = state.faxNumber;
    editFiedlJsonValue.faxCountryCode = faxPhnCode;
    editFiedlJsonValue.faxPltclJrNum = state.fCountryCodeDrDown;

    if (state.hcodeEditFlag || state.hNmEditFlag) {
      editFiedlJsonValue.homeDetailsEditFlag = true;
    } else {
      editFiedlJsonValue.homeDetailsEditFlag = false;
    }
    if (state.ocodeEditFlag || state.oNmEditFlag) {
      editFiedlJsonValue.officeDetailsEditFlag = true;
    } else {
      editFiedlJsonValue.officeDetailsEditFlag = false;
    }
    if (state.fcodeEditFlag || state.fNmEditFlag) {
      editFiedlJsonValue.faxDetailsEditFlag = true;
    } else {
      editFiedlJsonValue.faxDetailsEditFlag = false;
    }

    props.dispatch(saveContactInfoDetails(editFiedlJsonValue));
    props.dispatch(saveContactInfoUpdateFlag(true));
    props.dispatch(saveContactUpdateJson(updateData));
    history.push("/verify");
  };
  const hCountryCodeDropDown = (e) => {
    enableEditFlag();
    if (e.target.value !== null && e.target.value !== "") {
      setState({
        ...state,
        hCountryCodeDrDown: e.target.value,
        hcodeEditFlag: true,
        hcodeErrorFlag: false,
      });

      if (e.target.value === '100' || e.target.value === '101') {
        if (state.homeNumber.length !== 8) {
          setHkHomeNmLengthError(true);
        } else {
          setHkHomeNmLengthError(false);
        }
        setNonHkHomeNmLengthError(false);
      } else {
        setHkHomeNmLengthError(false);
        if (state.homeNumber.length < 7) {
          setNonHkHomeNmLengthError(true);
        } else {
          setNonHkHomeNmLengthError(false);
        }
      }
    } else {
      setState({
        ...state,
        hCountryCodeDrDown: e.target.value,
        hcodeEditFlag: true,
        hcodeErrorFlag: true,
      });
      if (e.target.value === '100' || e.target.value === '101') {
        if (state.homeNumber.length !== 8) {
          setHkHomeNmLengthError(true);
        } else {
          setHkHomeNmLengthError(false);
        }
        setNonHkHomeNmLengthError(false);
      }
      else {
        if (state.homeNumber.length < 7) {
          setNonHkHomeNmLengthError(true);
        } else {
          setNonHkHomeNmLengthError(false);
        }
        setHkHomeNmLengthError(false);
      }
    }
  };
  const oCountryCodeDropDown = (e) => {
    enableEditFlag();
    if (e.target.value !== null && e.target.value !== "") {
      setState({
        ...state,
        oCountryCodeDrDown: e.target.value,
        ocodeEditFlag: true,
        ocodeErrorFlag: false,
      });
      if (e.target.value === '100' || e.target.value === '101') {
        if (state.officeNumber.length !== 8) {
          setHkOfficeNmLenghtError(true);
        } else {
          setHkOfficeNmLenghtError(false);
        }
        setNonHkOfficeNmLenghtError(false);
      } else {
        if (state.officeNumber.length < 7) {
          setNonHkOfficeNmLenghtError(true);
        } else {
          setNonHkOfficeNmLenghtError(false);
        }
        setHkOfficeNmLenghtError(false);
      }
    } else {
      setState({
        ...state,
        oCountryCodeDrDown: e.target.value,
        ocodeEditFlag: true,
        ocodeErrorFlag: true,
      });
      if (e.target.value === '100' || e.target.value === '101') {
        if (state.officeNumber.length !== 8) {
          setHkOfficeNmLenghtError(true);
        } else {
          setHkOfficeNmLenghtError(false);
        }
        setNonHkOfficeNmLenghtError(false);
      }
      else {
        if (state.officeNumber.length < 7) {
          setNonHkOfficeNmLenghtError(true);
        } else {
          setNonHkOfficeNmLenghtError(false);
        }
        setHkOfficeNmLenghtError(false);
      }
    }
  };
  const fCountryCodeDropDown = (e) => {
    enableEditFlag();
    if (e.target.value !== null && e.target.value !== "") {
      setState({
        ...state,
        fCountryCodeDrDown: e.target.value,
        fcodeEditFlag: true,
        fcodeErrorFlag: false,
      });
      if (e.target.value === '100' || e.target.value === '101') {
        if (state.faxNumber.length !== 8) {
          setHkFaxNmLengthError(true);
        } else {
          setHkFaxNmLengthError(false);
        }
        setNonHkFaxNmLengthError(false);
      } else {
        if (state.faxNumber.length < 7) {
          setNonHkFaxNmLengthError(true);
        } else {
          setNonHkFaxNmLengthError(false);
        }
        setHkFaxNmLengthError(false);
      }
    } else {
      setState({
        ...state,
        fCountryCodeDrDown: e.target.value,
        fcodeEditFlag: true,
        fcodeErrorFlag: true,
      });
      if (e.target.value === '100' || e.target.value === '101') {
        if (state.faxNumber.length !== 8) {
          setHkFaxNmLengthError(true);
        } else {
          setHkFaxNmLengthError(false);
        }
        setNonHkFaxNmLengthError(false);
      }
      else {
        if (state.faxNumber.length < 7) {
          setNonHkFaxNmLengthError(true);
        } else {
          setNonHkFaxNmLengthError(false);
        }
        setHkFaxNmLengthError(false);
      }
    }
  };
  const setHomeOnlyNumberErrorMsg = () => {
    setState({
      ...state,
      hOnlyNmAllowFlag: false,
    });
  };
  const setOfficeOnlyNumberErrorMsg = () => {
    setState({
      ...state,
      oOnlyNmAllowFlag: false,
    });
  };
  const setFaxOnlyNumberErrorMsg = () => {
    setState({
      ...state,
      fOnlyNmAllowFlag: false,
    });
  };

  const hideHomeOnlyNumberErrorMsg = (e) => {
    const key = e.key; // const {key} = event; ES6+
    if (key === "Backspace" || key === "Delete") {
      setState({
        ...state,
        hOnlyNmAllowFlag: false,
      });
    }
  };
  const hideOfficeOnlyNumberErrorMsg = (e) => {
    const key = e.key; // const {key} = event; ES6+
    if (key === "Backspace" || key === "Delete") {
      setState({
        ...state,
        oOnlyNmAllowFlag: false,
      });
    }
  };
  const hideFaxOnlyNumberErrorMsg = (e) => {
    const key = e.key; // const {key} = event; ES6+
    if (key === "Backspace" || key === "Delete") {
      setState({
        ...state,
        fOnlyNmAllowFlag: false,
      });
    }
  };
  const handleChange = (event) => {
    enableEditFlag();
    if (event.target.name === "homeNm") {
      if (event.target.value !== null && event.target.value !== "") {
        setState({
          ...state,
          homeNumber: event.target.value,
          hNmEditFlag: true,
          hNmErrorFlag: false,
        });
        if (state.hCountryCodeDrDown.toString() === '100' || state.hCountryCodeDrDown.toString() === '101') {

          if (event.target.value.length !== 8) {
            setHkHomeNmLengthError(true);
          } else {
            setHkHomeNmLengthError(false);
          }
        } else {
          if (event.target.value.length < 7) {
            setNonHkHomeNmLengthError(true);
          } else {
            setNonHkHomeNmLengthError(false);
          }
        }
      } else {
        setState({
          ...state,
          homeNumber: event.target.value,
          hNmEditFlag: true,
          hNmErrorFlag: true,
        });

        if (state.hCountryCodeDrDown.toString() === '100' || state.hCountryCodeDrDown.toString() === '101') {
          if (event.target.value.length !== 8) {
            setHkHomeNmLengthError(true);
          } else {
            setHkHomeNmLengthError(false);
          }
        } else {
          setNonHkHomeNmLengthError(false);
        }
      }
    } else if (event.target.name === "officeNm") {
      if (event.target.value !== null && event.target.value !== "") {
        setState({
          ...state,
          officeNumber: event.target.value,
          oNmEditFlag: true,
          oNmErrorFlag: false,
        });
        if (state.oCountryCodeDrDown.toString() === '100' || state.oCountryCodeDrDown.toString() === '101') {
          if (event.target.value.length !== 8) {
            setHkOfficeNmLenghtError(true);
          } else {
            setHkOfficeNmLenghtError(false);
          }
        } else {
          if (event.target.value.length < 7) {
            setNonHkOfficeNmLenghtError(true);
          } else {
            setNonHkOfficeNmLenghtError(false);
          }
        }
      } else {
        setState({
          ...state,
          officeNumber: event.target.value,
          oNmEditFlag: true,
          oNmErrorFlag: true,
        });
        if (state.oCountryCodeDrDown.toString() === '100' || state.oCountryCodeDrDown.toString() === '101') {
          if (event.target.value.length !== 8) {
            setHkOfficeNmLenghtError(true);
          } else {
            setHkOfficeNmLenghtError(false);
          }
        } else {
          setNonHkOfficeNmLenghtError(false);
        }
      }
    } else if (event.target.name === "faxNm") {
      if (event.target.value !== null && event.target.value !== "") {
        setState({
          ...state,
          faxNumber: event.target.value,
          fNmEditFlag: true,
          fNmErrorFlag: false,
        });
        if (state.fCountryCodeDrDown.toString() === '100' || state.fCountryCodeDrDown.toString() === '101') {
          if (event.target.value.length !== 8) {
            setHkFaxNmLengthError(true);
          } else {
            setHkFaxNmLengthError(false);
          }
        } else {
          if (event.target.value.length < 7) {
            setNonHkFaxNmLengthError(true);
          } else {
            setNonHkFaxNmLengthError(false);
          }
        }
      } else {
        setState({
          ...state,
          faxNumber: event.target.value,
          fNmEditFlag: true,
          fNmErrorFlag: true,
        });
        if (state.fCountryCodeDrDown.toString() === '100' || state.fCountryCodeDrDown.toString() === '101') {
          if (event.target.value.length !== 8) {
            setHkFaxNmLengthError(true);
          } else {
            setHkFaxNmLengthError(false);
          }
        } else {
          setNonHkFaxNmLengthError(false);
        }
      }
    } else if (event.target.name === "pin") {
      if (event.target.value !== null && event.target.value !== "") {
        setState({
          ...state,
          pin: event.target.value,
          pinErrorFlag: false,
        });
      } else {
        setState({
          ...state,
          pin: event.target.value,
          pinErrorFlag: true,
        });
      }
    }
  };
  const isValid = () => {
    if (!(state.hcodeErrorFlag && state.ocodeErrorFlag && state.fcodeErrorFlag && state.hNmErrorFlag &&
      state.oNmErrorFlag && state.fNmErrorFlag) && (!hkHomeNmLengthError && !hkOfficeNmLengthError && !hkFaxNmLengthError) && (!nonHkHomeNmLengthError && !nonHkOfficeNmLengthError && !nonHkFaxNmLengthError) &&
      (state.homeNumber.length <= 20 &&
        state.officeNumber.length <= 20 &&
        state.faxNumber.length <= 20)
    ) {
      if (((state.hCountryCodeDrDown === 100 || state.hCountryCodeDrDown === 101) || (state.hCountryCodeDrDown === '100' || state.hCountryCodeDrDown === '101')) && (state.homeNumber.startsWith('0') || state.homeNumber.startsWith('999'))) {
        return true;
      }
      if (((state.oCountryCodeDrDown === 100 || state.oCountryCodeDrDown === 101) || (state.oCountryCodeDrDown === '100' || state.oCountryCodeDrDown === '101')) && (state.officeNumber.startsWith('0') || state.officeNumber.startsWith('999'))) {
        return true;
      }
      if (((state.fCountryCodeDrDown === 100 || state.fCountryCodeDrDown === 101) || (state.fCountryCodeDrDown === '100' || state.fCountryCodeDrDown === '101')) && (state.faxNumber.startsWith('0') || state.faxNumber.startsWith('999'))) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };
  const isNumeric = (event) => {
    event = event ? event : window.event;
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      if (event.target.name === "homeNm") {
        setState({
          ...state,
          hOnlyNmAllowFlag: true,
        });
      } else if (event.target.name === "officeNm") {
        setState({
          ...state,
          oOnlyNmAllowFlag: true,
        });
      } else if (event.target.name === "faxNm") {
        setState({
          ...state,
          fOnlyNmAllowFlag: true,
        });
      }
    } else {
      if (event.target.name === "homeNm") {
        setState({
          ...state,
          hOnlyNmAllowFlag: false,
        });
      } else if (event.target.name === "officeNm") {
        setState({
          ...state,
          oOnlyNmAllowFlag: false,
        });
      } else if (event.target.name === "faxNm") {
        setState({
          ...state,
          fOnlyNmAllowFlag: false,
        });
      }
    }
  };
  const onlyNumbers = (e) => {
    if (e.type == "paste") {
      var clipboardData = e.clipboardData || window.clipboardData;
      var pastedData = clipboardData.getData("Text");
      let re = /^\d*$/;
      if (!re.test(pastedData)) {
        e.preventDefault();
      }
    }
  };
  const getFilteredCountryList = (countryDropDown) => {
    let countryList = countryDropDown;
    countryList = countryList.filter((item) => {
      if (item.pltcJrsdNmbr === 167 || item.pltcJrsdNmbr === 169 || item.pltcJrsdNmbr === 175)
        return false;
      return true;
    });
    countryList.sort((item, item1) => {
      if (item1.countryNm > item.countryNm)
        return -1;
      return 1;
    });
    return countryList;
  };

  return (
    <React.Fragment>
      <div className="row">
        <div
          className="pds-card info-body contact-section rsc-card"
          id="contact-section"
        >
          {state.otherContactInfoDisplayFlag ? (
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h3 className="info-title">{t("otherContact.title")}</h3>
                {otherContactInfoEditFlag ||
                  props.contactVerifyEditFlag === true ? (
                  <a
                    href="#"
                    className="edit-link btn-cancel"
                    name="cancel"
                    data-testid="resetEditInfo"
                    onClick={handleOtherContactsInfoEdit}
                  >
                    {t("common.cancel")}
                  </a>
                ) : (
                  <a
                    href="#"
                    className={props.activeEdit ? "edit-link inactive" : "edit-link"}
                    name="editButton"
                    data-testid="editContactInfo"
                    onClick={handleOtherContactsInfoEdit}
                  >
                    {t("common.edit")}
                  </a>
                )}
              </div>
              <form noValidate>
                {otherContactInfoEditFlag ||
                  props.contactVerifyEditFlag === true ? (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label className="left-align  ">
                          {t("otherContact.home")}
                        </label>
                        <select
                          name="hCountryCode"
                          placeholder="Country Code"
                          data-testid="homeCountryCode"
                          className="rsc-select otherdetailselect select-arrow"
                          value={state.hcodeEditFlag ? state.hCountryCodeDrDown : (state.hCountryCodeDrDown && (state.hCountryCodeDrDown === 100 || state.hCountryCodeDrDown === 101) ? state.hCountryCodeDrDown : '')}
                          onChange={(e) => hCountryCodeDropDown(e)}
                        >
                          <option value="" hidden disabled defaultValue   ></option>
                          {getFilteredCountryList(props.countryDropDown).map((opt, i) => (
                            <option key={i} value={opt.pltcJrsdNmbr}>
                              {opt.phoneCd}-{opt.countryNm}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <input
                          type="number"
                          name="homeNm"
                          placeholder="Phone Number"
                          value={state.homeNumber}
                          data-testid="homeNumber"
                          onChange={(e) => handleChange(e)}
                          onBlur={setHomeOnlyNumberErrorMsg}
                          className={state.hOnlyNmAllowFlag || (state.homeNumber.length > 20) ? "form-input form-text required form-control form-margin mobile-number inputerrorstyle contact-info-input-ipad" : "form-input form-text required form-control form-margin mobile-number contact-info-input-ipad"}
                          onPaste={onlyNumbers}
                          onKeyDown={hideHomeOnlyNumberErrorMsg}
                          onKeyPress={isNumeric}
                          noValidate
                        />
                        {(((state.hCountryCodeDrDown === '100' || state.hCountryCodeDrDown === '101') || (state.hCountryCodeDrDown === 100 || state.hCountryCodeDrDown === 101)) && state.homeNumber.startsWith('0')) && (
                          <div className="pds-error common-pds-error" >{t("otherContact.HkNumberStart0error")}</div>
                        )}
                        {(((state.hCountryCodeDrDown === '100' || state.hCountryCodeDrDown === '101') || (state.hCountryCodeDrDown === 100 || state.hCountryCodeDrDown === 101)) && state.homeNumber.startsWith('999')) && (
                          <div className="pds-error common-pds-error" >{t("otherContact.HkNumberStart999error")}</div>
                        )}
                        {hkHomeNmLengthError && (
                          <div className="pds-error common-pds-error" >{t("mobile.mobileError")}</div>
                        )}
                        {nonHkHomeNmLengthError && (
                          <div className="pds-error common-pds-error" >{t("mobile.nonHKmobileError")}</div>
                        )}
                        {state.hOnlyNmAllowFlag && (
                          <div className="pds-error common-pds-error" >{t("otherContact.phoneNmDigiterror")}</div>
                        )}
                        {state.homeNumber.length > 20 && (
                          <div className="pds-error common-pds-error" >{t("otherContact.phoneNmMaxLengthError")}</div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label className="left-align  ">
                          {t("otherContact.office")}
                        </label>
                        <select
                          name="oCountryCode"
                          data-testid="officeCountryCode"
                          placeholder="Country Code"
                          className="rsc-select otherdetailselect select-arrow"
                          value={state.ocodeEditFlag ? state.oCountryCodeDrDown : (state.oCountryCodeDrDown && (state.oCountryCodeDrDown === 100 || state.oCountryCodeDrDown === 101) ? state.oCountryCodeDrDown : '')}
                          onChange={(e) => oCountryCodeDropDown(e)}
                        >
                          <option value="" hidden disabled defaultValue   ></option>
                          {getFilteredCountryList(props.countryDropDown).map((opt, i) => (
                            <option key={i} value={opt.pltcJrsdNmbr}>
                              {opt.phoneCd}-{opt.countryNm}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <input
                          type="number"
                          name="officeNm"
                          placeholder="Phone Number"
                          onBlur={setOfficeOnlyNumberErrorMsg}
                          value={state.officeNumber}
                          data-testid="officeNumber"
                          onChange={(e) => handleChange(e)}
                          className={state.oOnlyNmAllowFlag || (state.officeNumber.length > 20) ? "form-input form-text required form-control form-margin mobile-number inputerrorstyle contact-info-input-ipad" : "form-input form-text required form-control form-margin mobile-number contact-info-input-ipad"}
                          onPaste={onlyNumbers}
                          onKeyDown={hideOfficeOnlyNumberErrorMsg}
                          onKeyPress={isNumeric}
                          noValidate
                        />
                        {(((state.oCountryCodeDrDown === '100' || state.oCountryCodeDrDown === '101') || (state.oCountryCodeDrDown === 100 || state.oCountryCodeDrDown === 101)) && state.officeNumber.startsWith('0')) && (
                          <div className="pds-error common-pds-error" >{t("otherContact.HkNumberStart0error")}</div>
                        )}
                        {(((state.oCountryCodeDrDown === '100' || state.oCountryCodeDrDown === '101') || (state.oCountryCodeDrDown === 100 || state.oCountryCodeDrDown === 101)) && state.officeNumber.startsWith('999')) && (
                          <div className="pds-error common-pds-error" >{t("otherContact.HkNumberStart999error")}</div>
                        )}
                        {hkOfficeNmLengthError && (
                          <div className="pds-error common-pds-error" >{t("mobile.mobileError")}</div>
                        )}
                        {nonHkOfficeNmLengthError && (
                          <div className="pds-error common-pds-error" >{t("mobile.nonHKmobileError")}</div>
                        )}
                        {state.oOnlyNmAllowFlag && (
                          <div className="pds-error common-pds-error" >{t("otherContact.phoneNmDigiterror")}</div>
                        )}
                        {state.officeNumber.length > 20 && (
                          <div className="pds-error common-pds-error" >{t("otherContact.phoneNmMaxLengthError")}</div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label className="left-align  ">
                          {t("otherContact.fax")}
                        </label>
                        <select
                          name="fCountryCode"
                          placeholder="Country Code"
                          data-testid="faxCountryCode"
                          className="rsc-select  otherdetailselect select-arrow"
                          value={state.fcodeEditFlag ? state.fCountryCodeDrDown : (state.fCountryCodeDrDown && (state.fCountryCodeDrDown === 100 || state.fCountryCodeDrDown === 101) ? state.fCountryCodeDrDown : '')}
                          onChange={(e) => fCountryCodeDropDown(e)}
                        >
                          <option value="" hidden disabled defaultValue   ></option>
                          {getFilteredCountryList(props.countryDropDown).map((opt, i) => (
                            <option key={i} value={opt.pltcJrsdNmbr}>
                              {opt.phoneCd}-{opt.countryNm}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <input
                          type="number"
                          name="faxNm"
                          placeholder="Phone Number"
                          onBlur={setFaxOnlyNumberErrorMsg}
                          value={state.faxNumber}
                          data-testid="faxNumber"
                          onChange={(e) => handleChange(e)}
                          className={state.fOnlyNmAllowFlag || (state.faxNumber.length > 20) ? "form-input form-text required form-control form-margin mobile-number inputerrorstyle contact-info-input-ipad" : "form-input form-text required form-control form-margin mobile-number contact-info-input-ipad"}
                          onPaste={onlyNumbers}
                          onKeyDown={hideFaxOnlyNumberErrorMsg}
                          onKeyPress={isNumeric}
                          noValidate
                        />
                        {(((state.fCountryCodeDrDown === '100' || state.fCountryCodeDrDown === '101') || (state.fCountryCodeDrDown === 100 || state.fCountryCodeDrDown === 101)) && state.faxNumber.startsWith('0')) && (
                          <div className="pds-error common-pds-error" >{t("otherContact.HkNumberStart0error")}</div>
                        )}
                        {(((state.fCountryCodeDrDown === '100' || state.fCountryCodeDrDown === '101') || (state.fCountryCodeDrDown === 100 || state.fCountryCodeDrDown === 101)) && state.faxNumber.startsWith('999')) && (
                          <div className="pds-error common-pds-error" >{t("otherContact.HkNumberStart999error")}</div>
                        )}
                        {hkFaxNmLengthError && (
                          <div className="pds-error common-pds-error" >{t("mobile.mobileError")}</div>
                        )}
                        {nonHkFaxNmLengthError && (
                          <div className="pds-error common-pds-error" >{t("mobile.nonHKmobileError")}</div>
                        )}
                        {state.fOnlyNmAllowFlag && (
                          <div className="pds-error common-pds-error" >{t("otherContact.phoneNmDigiterror")}</div>
                        )}
                        {state.faxNumber.length > 20 && (
                          <div className="pds-error common-pds-error" >{t("otherContact.phoneNmMaxLengthError")}</div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <h4 className="left-align  ">
                        {t("otherContact.home")}
                        <label className="inner-label">
                          {homeBadAddressFlag ? t('generalInfo.responseN/A') : (state.homePhoneCode === "+852" || props?.clientInfo?.homePltcJrsdNmbr == 100 || props?.clientInfo?.homePltcJrsdNmbr == 101) ?
                            ((props?.clientInfo?.homePltcJrsdNmbr == 100 || props?.clientInfo?.homePltcJrsdNmbr == 101) ? (state.homePhoneCode ? state.homePhoneCode : "+852") + " " + (state.homeNumber.match(/.{1,4}/g)?.join(' ') ?? '') : t('generalInfo.responseN/A'))
                            : (state.homePhoneCode !== undefined ? state.homeNumber : t('generalInfo.responseN/A'))}
                        </label>
                      </h4>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <h4 className="left-align  ">
                        {t("otherContact.office")}
                        <label className="inner-label">
                          {officeBadAddressFlag ? t('generalInfo.responseN/A') : (state.officePhoneCode === "+852" || ofcPhn[0]?.phonePltcJrsdNmbr == 100 || ofcPhn[0]?.phonePltcJrsdNmbr == 101) ?
                            ((ofcPhn[0]?.phonePltcJrsdNmbr == 100 || ofcPhn[0]?.phonePltcJrsdNmbr == 101) ?
                              (state.officePhoneCode ? state.officePhoneCode : "+852") + " " + (state.officeNumber.match(/.{1,4}/g)?.join(' ') ?? '') :
                              t('generalInfo.responseN/A'))
                            : (state.officePhoneCode !== undefined ? state.officeNumber : t('generalInfo.responseN/A'))}
                        </label>
                      </h4>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <h4 className="left-align  ">
                        {t("otherContact.fax")}
                        <label className="inner-label">
                          {faxBadAddressFlag ? t('generalInfo.responseN/A') : (state.faxPhoneCode === "+852" || props?.clientInfo?.faxPltcJrsdNmbr == 100 || props?.clientInfo?.faxPltcJrsdNmbr == 101) ?
                            ((props?.clientInfo?.faxPltcJrsdNmbr == 100 || props?.clientInfo?.faxPltcJrsdNmbr == 101) ? (state.faxPhoneCode ? state.faxPhoneCode : "+852") + " " + (state.faxNumber.match(/.{1,4}/g)?.join(' ') ?? '') : t('generalInfo.responseN/A'))
                            : (state.faxPhoneCode !== undefined ? state.faxNumber : t('generalInfo.responseN/A'))}
                        </label>
                      </h4>
                    </div>
                  </div>
                )}

                <div className="row">
                  {otherContactInfoEditFlag ||
                    props.contactVerifyEditFlag === true ? (
                    <div className="col-md-6 float-left">
                      <button
                        name="update"
                        type="submit"
                        data-testid="updateContactInfo"
                        disabled={
                          props.contactVerifyEditFlag ? false : isValid()
                        }
                        onClick={onUpdate}
                        className="pds-button pds-button-tertiary btn-blue"
                      >
                        {t("common.update")}
                      </button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </form>
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    clientInfo: state.landing.clientInfo,
    countryDropDown: state.commonDropDown.countryDropDown,
    contactInfoDetails: state.landing.contactInfoDetails,
    contactInfoUpdateFlag: state.landing.contactInfoUpdateFlag,
    contactInfoUpdateJson: state.landing.contactInfoUpdateJson,
    contactVerifyEditFlag: state.landing.contactVerifyEditFlag,
    scheme: state.empLogin.scheme,
    selectedQuickPolicyInfo: state.twoAccounts.selectedQuickPolicyInfo,
    isOrsoAccount: state.twoAccounts.isOrsoAccount,
    clntRltnshpNmbr: state.empLogin.clntRltnshpNmbr,
    productTypeNmbr: state.empLogin.productTypeNmbr,
  };
};
const ContactInfoComp = connect(mapStateToProps)(ContactInfo);

export default class ContactInfoComponent extends React.Component {
  constructor() {
    super();
  }
  render() {
    return <ContactInfoComp onEdit={this.props.onEdit} activeEdit={this.props.activeEdit}></ContactInfoComp>;
  }
}

ContactInfoComponent.propTypes = {
  onEdit: PropTypes.func,
  activeEdit: PropTypes.bool,
};
